import "./Header.css";
import { Link } from "react-router-dom";
import { useState } from "react";
import {
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Offcanvas,
  Button,
} from "reactstrap";

import { breakpoints, Logo, useWindowSize } from "@bphxd/ds-core-react";

import { Hamburger32, Remove32 } from "@bphxd/ds-core-react/lib/icons";
import SvgHamburger32 from "@bphxd/ds-core-react/lib/icons/interface/Hamburger32";
import { useGlobalState } from "../GlobalContext/GlobalContext";
import { envComponentProps } from "../../Constants/envConstants.types";

const Header = (props: envComponentProps) => {
  const { appState, setappState } = useGlobalState();
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);
  const [activeLink, setActiveLink] = useState("home");
  const breakpoint = "lg";
  const { width } = useWindowSize();

  const toggleMobileNav = () => {
    setIsMobileNavOpen(!isMobileNavOpen);
  };
  const setActiveNavLink = (selectedKey: any) => {
    setActiveLink(selectedKey);
  };

  const logoutinitiated = () => {
    setappState((prevState) => ({
      ...prevState,
      isLoggedOut: true,
    }));

    if (window.location.href !== window.location.origin + "/") {
      window.location.href = window.location.origin + "/";
    }
  };

  return (
    <>
      <Navbar expand className="navbar-border navheight">
        <div className="d-flex align-items-center">
          <NavbarBrand href="/">
            <Logo className="mx-n3" />
            <span className="ms-5">Market Intelligence Tool</span>
          </NavbarBrand>
          <Nav navbar className={`d-none d-${breakpoint}-flex flex-row`}>
            <NavItem>
              <NavLink
                to="/"
                tag={Link}
                className={activeLink === "home" ? "active" : ""}
                onClick={() => setActiveNavLink("home")}
              >
                <span>Global view</span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                to="/Decarb"
                tag={Link}
                className={activeLink === "decarb" ? "active" : ""}
                onClick={() => setActiveNavLink("decarb")}
              >
                <span>Decarbonization Pathway</span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                to="/Guide"
                tag={Link}
                className={activeLink === "guide" ? "active" : ""}
                onClick={() => setActiveNavLink("guide")}
              >
                <span>Guide</span>
              </NavLink>
            </NavItem>
          </Nav>
        </div>

        {/* right side */}
        <div
          className={`ms-auto d-none d-${breakpoint}-flex align-items-center`}
        >
          <div className="ms-auto d-none d-sm-block">
            <Button
              className="rounded-0"
              color="darker-quartenary"
              size=""
              aria-label="login/logout"
              onClick={logoutinitiated}
            >
              {appState.isAuthenticated ? "Logout" : "Log in"}
            </Button>
            {/* <Button className="rounded-0 x5-ms-2" color="primary" size="">
              Premium Access
            </Button> */}
          </div>
          <div className="ms-auto d-sm-none">
            <Button className="rounded-0" color="primary" size="">
              Log in
            </Button>
          </div>
          <button
            aria-label="Toggle navigation"
            className="btn-close-inline d-md-none ms-4"
            onClick={() => {
              console.log("");
            }}
            type="button"
          >
            <span aria-hidden="true">
              <SvgHamburger32 />
            </span>
          </button>
        </div>
        <button
          type="button"
          onClick={toggleMobileNav}
          className={`btn-close-inline d-${breakpoint}-none ms-4`}
          aria-label="Toggle navigation"
        >
          <span aria-hidden="true">
            {isMobileNavOpen ? <Remove32 /> : <Hamburger32 />}
          </span>
        </button>
      </Navbar>

      {width < breakpoints[breakpoint] && (
        <>
          <Offcanvas
            isOpen={isMobileNavOpen}
            toggle={toggleMobileNav}
            className="offcanvas-sidebar pt-11"
            backdropClassName="offcanvas-backdrop-sidebar"
            zIndex="auto"
          >
            <div className="nav-sidebar w-100">
              <Nav vertical className="mt-4">
                <NavItem>
                  <NavLink to="/" tag={Link} active>
                    <span>Home</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink to="/Decarb" tag={Link}>
                    <span>Decarbonization Pathway</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink to="Guide" tag={Link}>
                    <span>Guide</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink to="/Login" tag={Link}>
                    <span>Login</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink to="#" tag={Link}>
                    <span>Premium Access</span>
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
          </Offcanvas>
        </>
      )}
    </>
    // <div className="Background">
    //   <img
    //     alt="bp logo"
    //     src={logo}
    //     className="-Building-Blocks-Light-bp-Logo-Small-Color"
    //   />
    //   <span className="Product-Identifier">Market Intelligence Tool</span>

    //   <div>
    //     {/* <div className="-Decarb">Decarbonization pathway</div>
    //   <div className="-Guide">Guide</div>
    //   <div className="-Login">Login</div> */}
    //     <div className="-Home">
    //       <NavLink
    //         to="/"
    //         style={({ isActive }) => ({
    //           color: isActive
    //             ? "rgba(17, 17, 17, 0.92)"
    //             : "rgba(17, 17, 17, 0.64)",
    //         })}
    //       >
    //         Home
    //       </NavLink>
    //     </div>
    //     <div className="-Decarb">
    //       <NavLink
    //         to="/Decarb"
    //         style={({ isActive }) => ({
    //           color: isActive
    //             ? "rgba(17, 17, 17, 0.92)"
    //             : "rgba(17, 17, 17, 0.64)",
    //         })}
    //       >
    //         Decarbonization pathway
    //       </NavLink>
    //     </div>
    //     <div className="-Guide">
    //       <NavLink
    //         to="/Guide"
    //         style={({ isActive }) => ({
    //           color: isActive
    //             ? "rgba(17, 17, 17, 0.92)"
    //             : "rgba(17, 17, 17, 0.64)",
    //         })}
    //       >
    //         Guide
    //       </NavLink>
    //     </div>
    //     <div className="-Login">
    //       <NavLink
    //         to="/Login"
    //         style={({ isActive }) => ({
    //           color: isActive
    //             ? "rgba(17, 17, 17, 0.92)"
    //             : "rgba(17, 17, 17, 0.64)",
    //         })}
    //       >
    //         Login
    //       </NavLink>
    //     </div>
    //     <div className="-PremiumAccess">
    //       <span className="-premiumaccess-btn-text">Premium access</span>
    //     </div>
    //   </div>
    // </div>
  );
};

export default Header;
