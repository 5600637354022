import React, { useEffect, useState } from "react";
import { Modal, ModalHeader, ModalBody, Label, Input } from "reactstrap";
import { useGlobalState } from "../GlobalContext/GlobalContext";
import alertPng from "../../Assets/images/alert-32.png";
import "./ConfidentialityPopup.css";

const ConfidentialityPopup = (args: any) => {
  const [modal, setModal] = useState(false);
  const { appState, setappState } = useGlobalState();

  const toggle = () => {
    setModal(!modal);
    setappState((prevState) => ({
      ...prevState,
      ConfidentialPopup: !modal,
    }));
  };

  useEffect(() => {
    if (appState.ConfidentialPopup) {
      setModal(true);
    } else {
      setModal(false);
    }
  }, [appState.ConfidentialPopup]);

  return (
    <div>
      <Modal isOpen={modal} {...args} className="background-Popup boldCSS">
        <ModalHeader style={{ fontWeight: "bold" }}>
          <img src={alertPng}></img>Important – please read
        </ModalHeader>
        <ModalBody className="p-text-black-light">
          <ul>
            <li>
              This information is <span className="boldCSS">confidential</span>{" "}
              and for <span className="boldCSS">internal</span> use only.
            </li>
            <li>
              Data and insights should{" "}
              <span className="boldCSS">
                not be shared with external parties.
              </span>{" "}
            </li>
            <li>
              The primary purpose of the tool is{" "}
              <span className="boldCSS">customer identification</span>, all
              numbers are <span className="boldCSS">directional estimates</span>{" "}
              and should not be used as a source for any investment or offer
              decisions.{" "}
            </li>
            <li>
              The tool covers{" "}
              <span className="boldCSS">8 large industrial sectors</span>, but
              this is not exhaustive of industrial demand.{" "}
            </li>
            <li>
              The three largest sectors – steel, cement and chemicals – have
              demand outlooks to 2050, the other sectors only have a view for
              2024 at this time.{" "}
            </li>
            <li>
              Sector{" "}
              <span className="boldCSS">
                data sets have been sourced from 3rd parties
              </span>
              , these have been cleaned but there
              <span className="boldCSS"> may be errors and omissions </span>
              due to the size and breadth of the coverage.{" "}
            </li>
            <li>
              Where{" "}
              <span className="boldCSS">
                capacity is not available an average size has been assigned
              </span>
              , this can be seen in the source reference. The majority of these
              are in the <span className="boldCSS">data centre</span> and{" "}
              <span className="boldCSS">cement sectors</span>.{" "}
            </li>
            <li>
              The energy allocation methodology{" "}
              <span className="boldCSS">
                does not account for specific technologies
              </span>{" "}
              deployed at a site level.{" "}
            </li>
            <li>
              The tool uses{" "}
              <span className="boldCSS">
                static industrial capacity datasets
              </span>
              , but over time there will be growth in capacity in some regions
              but closures in others. As a result, the further forward you look
              in time the less accurate the consolidated outputs could be.{" "}
            </li>
            <li>
              <span className="boldCSS">Potential gross margin</span> value is{" "}
              <span className="boldCSS">
                not constrained by bp’s ability to supply
              </span>{" "}
              or a customer’s actual need (e.g. contracted supply).{" "}
            </li>
          </ul>
          <Label check className="p-text-black-light-lab boldCSS">
            <Input type="checkbox" checked={modal} onChange={toggle} /> I
            confirm I have read and understood the points above .
          </Label>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ConfidentialityPopup;
