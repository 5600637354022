import React, { useEffect, useLayoutEffect, useState } from "react";
import "./ChartTab.css";
import Plot from "react-plotly.js";
import { envComponentProps } from "../../Constants/envConstants.types";
import { Nav, NavItem, NavLink, Navbar } from "reactstrap";
import { Link } from "react-router-dom";
import info24Png from "../../Assets/images/info-24.png";
import { useGlobalState } from "../GlobalContext/GlobalContext";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import { colors } from "react-select/dist/declarations/src/theme";

import {
  ComposedChart,
  BarChart,
  Line,
  Area,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Label,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList,
} from "recharts";
import { Spinner } from "@bphxd/ds-core-react";

interface ExtendedProps extends envComponentProps {
  height?: number;
  width?: number;
  activeLink?: string;
}

const ChartTab: React.FC<ExtendedProps> = ({
  height: testChartheight,
  width: testChartwidth,
  activeLink: testActiveLink,
  ...props
}) => {
  const { appState, setappState } = useGlobalState();
  const breakpoint = "lg";
  const [activeLink, setActiveLink] = useState(testActiveLink || "Energy");
  const [activeUnit, setActiveUnit] = useState("TJ");
  const [dataMix, setdataMix] = useState<any>([]);
  const [datatoptencomp, setdatatoptencomp] = useState<any>([]);
  const [datatopfivecount, setdatatopfivecount] = useState<any>([]);
  const [datatopfiveSector, setdatatopfiveSector] = useState<any>([]);
  const [yAxisComp, setyAxisComp] = useState(100);
  const [yAxisCountry, setyAxisCountry] = useState(100);
  const [yAxisEnergymix, setyAxisEnergymix] = useState(100);
  const [yAxisSector, setyAxisSector] = useState(100);
  const [spinner, setSpinner] = useState(false);
  const [spinnerComp, setspinnerComp] = useState(false);
  const [spinnerCountry, setspinnerCountry] = useState(false);
  const [spinnerSector, setspinnerSector] = useState(false);
  const layoutChart = {
    width: 600,
    height: 350,
  };

  const setActiveNavLink = (selectedKey: any) => {
    setActiveLink(selectedKey);
    if (selectedKey === "Capacity") {
      setActiveUnit("");
    }
    if (selectedKey === "Energy") {
      setActiveUnit("TJ");
    }
    if (selectedKey === "Value") {
      setActiveUnit("$");
    }
    if (selectedKey === "Emissions") {
      setActiveUnit("Mt CO2e");
    }
  };

  useEffect(() => {
    if (appState.isAuthenticated) {
      // setSpinner(true);
      // setspinnerComp(true);
      // setspinnerCountry(true);
      // setspinnerSector(true);
      if (activeLink === "Capacity") {
        // Don't show spinner when
        testActiveLink ? setSpinner(false) : setSpinner(true);
        const EnergyFeatureLayer = new FeatureLayer({
          url: props.mit_scenario_table_spatial,
        });
        let whereClauseEnergy = "1 = 1";

        if (appState.energyOutlookVal !== "") {
          whereClauseEnergy = "pathway = '" + appState.energyOutlookVal + "'";
        }
        if (appState.regionVal !== "") {
          whereClauseEnergy =
            whereClauseEnergy +
            " AND country_name IN(" +
            appState.regionVal +
            ")";
        }
        if (appState.companyVal !== "" && whereClauseEnergy === "1 = 1") {
          whereClauseEnergy =
            "plant_front_name IN(" + appState.companyVal + ")";
        }
        if (appState.companyVal !== "" && whereClauseEnergy !== "1 = 1") {
          whereClauseEnergy =
            whereClauseEnergy +
            " AND plant_front_name IN(" +
            appState.companyVal +
            ")";
        }
        if (appState.sectorVal !== "" && whereClauseEnergy === "1 = 1") {
          whereClauseEnergy = "product IN(" + appState.sectorVal + ")";
        }
        if (appState.sectorVal !== "" && whereClauseEnergy !== "1 = 1") {
          whereClauseEnergy =
            whereClauseEnergy + " AND product IN(" + appState.sectorVal + ")";
        }
        if (appState.vectorVal !== "" && whereClauseEnergy === "1 = 1") {
          whereClauseEnergy = "vector IN(" + appState.vectorVal + ")";
        }
        if (appState.vectorVal !== "" && whereClauseEnergy !== "1 = 1") {
          whereClauseEnergy =
            whereClauseEnergy + " AND vector IN(" + appState.vectorVal + ")";
        }

        const queryEnergy = EnergyFeatureLayer.createQuery();
        queryEnergy.where = whereClauseEnergy;
        queryEnergy.outFields = ["*"];
        queryEnergy.returnDistinctValues = false;
        queryEnergy.returnGeometry = false;
        queryEnergy.orderByFields = ["Total_sum DESC"];
        queryEnergy.groupByFieldsForStatistics = ["year,vector"];
        const validationParamsEnergy: any = [
          {
            statisticType: "sum",
            onStatisticField: "total_energy_tj",
            outStatisticFieldName: "Total_sum",
          },
        ];
        queryEnergy.outStatistics = validationParamsEnergy;

        EnergyFeatureLayer.queryFeatures(queryEnergy).then(function (response) {
          if (response.features) {
            const Energymixsorted: any = [];
            const yearsList: any = [];
            response.features.forEach((item) => {
              Energymixsorted.push({
                year: item.attributes.year,
                vector: item.attributes.vector,
                totalVectorSum: item.attributes.Total_sum,
                percentage: 0,
                TotalYearSum: 0,
              });
              if (yearsList.indexOf(item.attributes.year) === -1) {
                yearsList.push(item.attributes.year);
              }
            });
            let EnergyMixbarchartheighest: any = 0;
            yearsList.forEach((element: any) => {
              let totalYearSum = 0;
              Energymixsorted.forEach((item: any) => {
                if (element === item.year) {
                  totalYearSum = totalYearSum + item.totalVectorSum;
                }
              });
              if (totalYearSum > EnergyMixbarchartheighest) {
                EnergyMixbarchartheighest = totalYearSum;
              }
              Energymixsorted.forEach((item: any) => {
                if (element === item.year) {
                  item.percentage = (
                    (item.totalVectorSum * 100) /
                    totalYearSum
                  ).toFixed(1);
                  item.TotalYearSum = Math.round(totalYearSum);
                }
              });
            });
            const EnergymixsortedList = Energymixsorted.sort(
              (a: any, b: any) => parseFloat(a.year) - parseFloat(b.year)
            );
            const energyMixResults: any = [];
            yearsList.forEach((element: any) => {
              energyMixResults.push({ year: element });
            });
            EnergymixsortedList.forEach((element: any) => {
              energyMixResults.forEach((item: any) => {
                if (item.year === element.year) {
                  item[element.vector.trim().replace(" ", "_")] =
                    element.totalVectorSum.toFixed(1);
                  item[
                    element.vector.trim().replace(" ", "_") + "_Percentage"
                  ] = element.percentage;
                  item["TotalSum"] = element.TotalYearSum;
                }
              });
            });
            setyAxisEnergymix(
              parseInt(EnergyMixbarchartheighest) >= 1
                ? parseInt(EnergyMixbarchartheighest) + 10
                : parseInt(EnergyMixbarchartheighest) + 0.5
            );
            const energyResult = energyMixResults.sort(
              (a: any, b: any) => a.year - b.year
            );

            setdataMix(energyResult);
            setSpinner(false);
          }
        });

        if (!testActiveLink) {
          setspinnerComp(true);
          const ToptenCompaniesFeatureLayer = new FeatureLayer({
            url: props.mit_fc_view_table,
          });
          let whereClause = "1 = 1";
          if (appState.energyOutlookVal !== "") {
            whereClause = "pathway = '" + appState.energyOutlookVal + "'";
          }
          if (
            appState.energyOutlookVal === "" &&
            appState.outputYearVal !== ""
          ) {
            whereClause = "year = " + appState.outputYearVal;
          }
          if (
            appState.outputYearVal !== "" &&
            appState.energyOutlookVal !== ""
          ) {
            whereClause = whereClause + " AND year = " + appState.outputYearVal;
          }
          if (appState.regionVal !== "") {
            whereClause =
              whereClause + " AND country_name IN(" + appState.regionVal + ")";
          }
          if (appState.companyVal !== "" && whereClause === "1 = 1") {
            whereClause = "plant_front_name IN(" + appState.companyVal + ")";
          }
          if (appState.companyVal !== "" && whereClause !== "1 = 1") {
            whereClause =
              whereClause +
              " AND plant_front_name IN(" +
              appState.companyVal +
              ")";
          }
          if (appState.sectorVal !== "" && whereClause === "1 = 1") {
            whereClause = "product IN(" + appState.sectorVal + ")";
          }
          if (appState.sectorVal !== "" && whereClause !== "1 = 1") {
            whereClause =
              whereClause + " AND product IN(" + appState.sectorVal + ")";
          }
          const query = ToptenCompaniesFeatureLayer.createQuery();
          query.where = whereClause;
          query.outFields = ["parent_company", "capacity"];
          query.returnDistinctValues = false;
          query.returnGeometry = false;
          query.orderByFields = ["Total_sum DESC"];
          query.groupByFieldsForStatistics = ["parent_company"];
          const validationParamsPlant: any = [
            {
              statisticType: "sum",
              onStatisticField: "capacity",
              outStatisticFieldName: "Total_sum",
            },
            {
              statisticType: "count",
              onStatisticField: "parent_company",
              outStatisticFieldName: "Total_Count",
            },
          ];
          query.outStatistics = validationParamsPlant;

          ToptenCompaniesFeatureLayer.queryFeatures(query).then(function (
            response
          ) {
            if (response.features) {
              const topFeaturesbyCapacity = response.features.slice(0, 10);
              let sumofTotalCountry = 0;
              response.features.forEach((item) => {
                sumofTotalCountry =
                  sumofTotalCountry + item.attributes.Total_sum;
              });
              const toptenbarchartData: any = [];

              topFeaturesbyCapacity.forEach((item) => {
                const itempercent = (
                  (item.attributes.Total_sum * 100) /
                  sumofTotalCountry
                ).toFixed(1);
                toptenbarchartData.push({
                  Plant: item.attributes.parent_company,
                  Capacity: item.attributes.Total_sum,
                  Percentage: itempercent,
                });
              });
              if (toptenbarchartData.length > 0) {
                setyAxisComp(parseInt(toptenbarchartData[0].Percentage) + 25);
              }
              setdatatoptencomp(toptenbarchartData);
              setspinnerComp(false);
            }
          });
          setspinnerCountry(true);
          const querytopfiveCount = ToptenCompaniesFeatureLayer.createQuery();
          querytopfiveCount.where = whereClause;
          querytopfiveCount.outFields = ["country_name", "capacity"];
          querytopfiveCount.returnDistinctValues = false;
          querytopfiveCount.returnGeometry = false;
          querytopfiveCount.orderByFields = ["Total_sum DESC"];
          querytopfiveCount.groupByFieldsForStatistics = ["country_name"];
          const validationParamsCountry: any = [
            {
              statisticType: "sum",
              onStatisticField: "capacity",
              outStatisticFieldName: "Total_sum",
            },
            {
              statisticType: "count",
              onStatisticField: "country_name",
              outStatisticFieldName: "Total_Count",
            },
          ];
          querytopfiveCount.outStatistics = validationParamsCountry;
          ToptenCompaniesFeatureLayer.queryFeatures(querytopfiveCount).then(
            function (response) {
              if (response.features) {
                const topFiveFeaturesbyCountry = response.features.slice(0, 5);
                let sumofTotalCountry = 0;
                response.features.forEach((item) => {
                  sumofTotalCountry =
                    sumofTotalCountry + item.attributes.Total_sum;
                });
                const topfivebarchartDatacountCountry: any = [];
                topFiveFeaturesbyCountry.forEach((item) => {
                  const itempercent = (
                    (item.attributes.Total_sum * 100) /
                    sumofTotalCountry
                  ).toFixed(1);
                  topfivebarchartDatacountCountry.push({
                    Country: item.attributes.country_name,
                    Capacity: item.attributes.Total_sum,
                    Percentage: itempercent,
                  });
                });
                if (topfivebarchartDatacountCountry.length > 0) {
                  setyAxisCountry(
                    parseInt(topfivebarchartDatacountCountry[0].Percentage) + 10
                  );
                }
                setdatatopfivecount(topfivebarchartDatacountCountry);
                setspinnerCountry(false);
              }
            }
          );
          setspinnerSector(true);
          const querytopfive = ToptenCompaniesFeatureLayer.createQuery();
          querytopfive.where = whereClause;
          querytopfive.outFields = ["sector", "capacity"];
          querytopfive.returnDistinctValues = false;
          querytopfive.returnGeometry = false;
          querytopfive.orderByFields = ["Total_sum DESC"];
          querytopfive.groupByFieldsForStatistics = ["sector"];
          const validationParams: any = [
            {
              statisticType: "sum",
              onStatisticField: "capacity",
              outStatisticFieldName: "Total_sum",
            },
            {
              statisticType: "count",
              onStatisticField: "sector",
              outStatisticFieldName: "Total_product",
            },
          ];
          querytopfive.outStatistics = validationParams;
          ToptenCompaniesFeatureLayer.queryFeatures(querytopfive).then(
            function (response) {
              if (response.features) {
                const topFiveFeaturesbySector = response.features.slice(0, 5);
                let sumofTotalSector = 0;
                response.features.forEach((item) => {
                  sumofTotalSector =
                    sumofTotalSector + item.attributes.Total_sum;
                });
                const topfivebarchartDatacountSector: any = [];
                topFiveFeaturesbySector.forEach((item) => {
                  const itempercent = (
                    (item.attributes.Total_sum * 100) /
                    sumofTotalSector
                  ).toFixed(1);
                  topfivebarchartDatacountSector.push({
                    Sector: item.attributes.sector,
                    Capacity: item.attributes.Total_sum,
                    Percentage: itempercent,
                  });
                });
                if (topfivebarchartDatacountSector.length > 0) {
                  setyAxisSector(
                    parseInt(topfivebarchartDatacountSector[0].Percentage) + 10
                  );
                }
                setdatatopfiveSector(topfivebarchartDatacountSector);
                setspinnerSector(false);
              }
            }
          );
        }
      }
      if (activeLink === "Energy") {
        // setSpinner(true);
        testActiveLink ? setSpinner(false) : setSpinner(true);
        const EnergyFeatureLayer = new FeatureLayer({
          url: props.mit_scenario_table_spatial,
        });
        let whereClauseEnergy = "1 = 1";

        if (appState.energyOutlookVal !== "") {
          whereClauseEnergy = "pathway = '" + appState.energyOutlookVal + "'";
        }
        if (appState.regionVal !== "") {
          whereClauseEnergy =
            whereClauseEnergy +
            "AND country_name IN(" +
            appState.regionVal +
            ")";
        }
        if (appState.companyVal !== "" && whereClauseEnergy === "1 = 1") {
          whereClauseEnergy =
            "plant_front_name IN(" + appState.companyVal + ")";
        }
        if (appState.companyVal !== "" && whereClauseEnergy !== "1 = 1") {
          whereClauseEnergy =
            whereClauseEnergy +
            " AND plant_front_name IN(" +
            appState.companyVal +
            ")";
        }
        if (appState.sectorVal !== "" && whereClauseEnergy === "1 = 1") {
          whereClauseEnergy = "product IN(" + appState.sectorVal + ")";
        }
        if (appState.sectorVal !== "" && whereClauseEnergy !== "1 = 1") {
          whereClauseEnergy =
            whereClauseEnergy + " AND product IN(" + appState.sectorVal + ")";
        }
        if (appState.vectorVal !== "" && whereClauseEnergy === "1 = 1") {
          whereClauseEnergy = "vector IN(" + appState.vectorVal + ")";
        }
        if (appState.vectorVal !== "" && whereClauseEnergy !== "1 = 1") {
          whereClauseEnergy =
            whereClauseEnergy + " AND vector IN(" + appState.vectorVal + ")";
        }
        const queryEnergy = EnergyFeatureLayer.createQuery();
        queryEnergy.where = whereClauseEnergy;
        queryEnergy.outFields = ["*"];
        queryEnergy.returnDistinctValues = false;
        queryEnergy.returnGeometry = false;
        queryEnergy.orderByFields = ["Total_sum DESC"];
        queryEnergy.groupByFieldsForStatistics = ["year,vector"];
        const validationParamsEnergy: any = [
          {
            statisticType: "sum",
            onStatisticField: "total_energy_tj",
            outStatisticFieldName: "Total_sum",
          },
        ];
        queryEnergy.outStatistics = validationParamsEnergy;

        EnergyFeatureLayer.queryFeatures(queryEnergy).then(function (response) {
          if (response.features) {
            const Energymixsorted: any = [];
            const yearsList: any = [];
            response.features.forEach((item) => {
              Energymixsorted.push({
                year: item.attributes.year,
                vector: item.attributes.vector,
                totalVectorSum: item.attributes.Total_sum,
                percentage: 0,
                TotalYearSum: 0,
              });
              if (yearsList.indexOf(item.attributes.year) === -1) {
                yearsList.push(item.attributes.year);
              }
            });
            let EnergyMixbarchartheighest: any = 0;
            yearsList.forEach((element: any) => {
              let totalYearSum = 0;
              Energymixsorted.forEach((item: any) => {
                if (element === item.year) {
                  totalYearSum = totalYearSum + item.totalVectorSum;
                }
              });
              if (totalYearSum > EnergyMixbarchartheighest) {
                EnergyMixbarchartheighest = totalYearSum;
              }
              Energymixsorted.forEach((item: any) => {
                if (element === item.year) {
                  item.percentage = (
                    (item.totalVectorSum * 100) /
                    totalYearSum
                  ).toFixed(1);
                  item.TotalYearSum = Math.round(totalYearSum);
                }
              });
            });
            const EnergymixsortedList = Energymixsorted.sort(
              (a: any, b: any) => parseFloat(a.year) - parseFloat(b.year)
            );
            const energyMixResults: any = [];
            yearsList.forEach((element: any) => {
              energyMixResults.push({ year: element });
            });
            EnergymixsortedList.forEach((element: any) => {
              energyMixResults.forEach((item: any) => {
                if (item.year === element.year) {
                  item[element.vector.trim().replace(" ", "_")] =
                    element.totalVectorSum.toFixed(1);
                  item[
                    element.vector.trim().replace(" ", "_") + "_Percentage"
                  ] = element.percentage;
                  item["TotalSum"] = element.TotalYearSum;
                }
              });
            });
            setyAxisEnergymix(
              parseInt(EnergyMixbarchartheighest) >= 1
                ? parseInt(EnergyMixbarchartheighest) + 10
                : parseInt(EnergyMixbarchartheighest) + 0.5
            );
            const energyResult = energyMixResults.sort(
              (a: any, b: any) => a.year - b.year
            );
            setdataMix(energyResult);
            setSpinner(false);
          }
        });
        // setspinnerComp(true);
        testActiveLink ? setspinnerComp(false) : setspinnerComp(true);
        const ToptenCompaniesFeatureLayer = new FeatureLayer({
          url: props.mit_scenario_table_spatial,
        });
        let whereClause = "1 = 1";
        if (appState.outputYearVal !== "") {
          whereClause = "year = " + appState.outputYearVal;
        }
        if (appState.energyOutlookVal !== "") {
          whereClause =
            whereClause + " AND pathway = '" + appState.energyOutlookVal + "'";
        }
        if (appState.regionVal !== "") {
          whereClause =
            whereClause + "AND country_name IN(" + appState.regionVal + ")";
        }
        if (appState.companyVal !== "" && whereClause === "1 = 1") {
          whereClause = "plant_front_name IN(" + appState.companyVal + ")";
        }
        if (appState.companyVal !== "" && whereClause !== "1 = 1") {
          whereClause =
            whereClause +
            " AND plant_front_name IN(" +
            appState.companyVal +
            ")";
        }
        if (appState.sectorVal !== "" && whereClause === "1 = 1") {
          whereClause = "product IN(" + appState.sectorVal + ")";
        }
        if (appState.sectorVal !== "" && whereClause !== "1 = 1") {
          whereClause =
            whereClause + " AND product IN(" + appState.sectorVal + ")";
        }
        if (appState.vectorVal !== "" && whereClause === "1 = 1") {
          whereClause = "vector IN(" + appState.vectorVal + ")";
        }
        if (appState.vectorVal !== "" && whereClause !== "1 = 1") {
          whereClause =
            whereClause + " AND vector IN(" + appState.vectorVal + ")";
        }
        const query = ToptenCompaniesFeatureLayer.createQuery();
        query.where = whereClause;
        query.outFields = ["parent_company", "total_energy_tj"];
        query.start = 0;
        query.num = 10;
        query.returnDistinctValues = false;
        query.returnGeometry = false;
        query.orderByFields = ["Total_sum DESC"];
        query.groupByFieldsForStatistics = ["parent_company"];
        const validationParamsPlant: any = [
          {
            statisticType: "sum",
            onStatisticField: "total_energy_tj",
            outStatisticFieldName: "Total_sum",
          },
        ];
        query.outStatistics = validationParamsPlant;

        ToptenCompaniesFeatureLayer.queryFeatures(query).then(function (
          response
        ) {
          if (response.features) {
            const topFeaturesbyCapacity = response.features.slice(0, 10);
            let sumofTotalCountry = 0;
            response.features.forEach((item) => {
              sumofTotalCountry = sumofTotalCountry + item.attributes.Total_sum;
            });
            const toptenbarchartData: any = [];

            topFeaturesbyCapacity.forEach((item) => {
              const itempercent = (
                (item.attributes.Total_sum * 100) /
                sumofTotalCountry
              ).toFixed(1);
              toptenbarchartData.push({
                Plant: item.attributes.parent_company,
                Capacity: item.attributes.Total_sum,
                Percentage: itempercent,
              });
            });
            if (toptenbarchartData.length) {
              setyAxisComp(parseInt(toptenbarchartData[0].Percentage) + 25);
            }
            setdatatoptencomp(toptenbarchartData);
            setspinnerComp(false);
          }
        });
        // setspinnerCountry(true);
        testActiveLink ? setspinnerCountry(false) : setspinnerCountry(true);
        const querytopfiveCount = ToptenCompaniesFeatureLayer.createQuery();
        querytopfiveCount.where = whereClause;
        querytopfiveCount.outFields = ["country_name", "total_energy_tj"];
        querytopfiveCount.returnDistinctValues = false;
        querytopfiveCount.returnGeometry = false;
        querytopfiveCount.orderByFields = ["Total_sum DESC"];
        querytopfiveCount.groupByFieldsForStatistics = ["country_name"];
        const validationParamsCountry: any = [
          {
            statisticType: "sum",
            onStatisticField: "total_energy_tj",
            outStatisticFieldName: "Total_sum",
          },
        ];
        querytopfiveCount.outStatistics = validationParamsCountry;
        ToptenCompaniesFeatureLayer.queryFeatures(querytopfiveCount).then(
          function (response) {
            if (response.features) {
              const topFiveFeaturesbyCountry = response.features.slice(0, 5);
              let sumofTotalCountry = 0;
              response.features.forEach((item) => {
                sumofTotalCountry =
                  sumofTotalCountry + item.attributes.Total_sum;
              });
              const topfivebarchartDatacountCountry: any = [];
              topFiveFeaturesbyCountry.forEach((item) => {
                const itempercent = (
                  (item.attributes.Total_sum * 100) /
                  sumofTotalCountry
                ).toFixed(1);
                topfivebarchartDatacountCountry.push({
                  Country: item.attributes.country_name,
                  Capacity: item.attributes.Total_sum,
                  Percentage: itempercent,
                  // Country: "Country",
                });
              });
              if (topfivebarchartDatacountCountry.length) {
                setyAxisCountry(
                  parseInt(topfivebarchartDatacountCountry[0].Percentage) + 10
                );
              }
              setdatatopfivecount(topfivebarchartDatacountCountry);
              setspinnerCountry(false);
            }
          }
        );
        // setspinnerSector(true);
        testActiveLink ? setspinnerSector(false) : setspinnerSector(true);
        const querytopfive = ToptenCompaniesFeatureLayer.createQuery();
        querytopfive.where = whereClause;
        querytopfive.outFields = ["sector", "total_energy_tj"];
        querytopfive.returnDistinctValues = false;
        querytopfive.returnGeometry = false;
        querytopfive.orderByFields = ["Total_sum DESC"];
        querytopfive.groupByFieldsForStatistics = ["sector"];
        const validationParams: any = [
          {
            statisticType: "sum",
            onStatisticField: "total_energy_tj",
            outStatisticFieldName: "Total_sum",
          },
          {
            statisticType: "count",
            onStatisticField: "sector",
            outStatisticFieldName: "Total_product",
          },
        ];
        querytopfive.outStatistics = validationParams;
        ToptenCompaniesFeatureLayer.queryFeatures(querytopfive).then(function (
          response
        ) {
          if (response.features) {
            const topFiveFeaturesbySector = response.features.slice(0, 5);
            let sumofTotalSector = 0;
            response.features.forEach((item) => {
              sumofTotalSector = sumofTotalSector + item.attributes.Total_sum;
            });
            const topfivebarchartDatacountSector: any = [];
            topFiveFeaturesbySector.forEach((item) => {
              const itempercent = (
                (item.attributes.Total_sum * 100) /
                sumofTotalSector
              ).toFixed(1);
              topfivebarchartDatacountSector.push({
                Sector: item.attributes.sector,
                Capacity: item.attributes.Total_sum,
                Percentage: itempercent,
              });
            });
            if (topfivebarchartDatacountSector.length > 0) {
              setyAxisSector(
                parseInt(topfivebarchartDatacountSector[0].Percentage) + 10
              );
            }
            setdatatopfiveSector(topfivebarchartDatacountSector);
            setspinnerSector(false);
          }
        });
      }
      if (activeLink === "Value") {
        // setSpinner(true);
        testActiveLink ? setSpinner(false) : setSpinner(true);
        const EnergyFeatureLayer = new FeatureLayer({
          url: props.mit_scenario_table_spatial,
        });
        let whereClauseEnergy = "1 = 1";

        if (appState.energyOutlookVal !== "") {
          whereClauseEnergy = "pathway = '" + appState.energyOutlookVal + "'";
        }
        if (appState.regionVal !== "") {
          whereClauseEnergy =
            whereClauseEnergy +
            "AND country_name IN(" +
            appState.regionVal +
            ")";
        }
        if (appState.companyVal !== "" && whereClauseEnergy === "1 = 1") {
          whereClauseEnergy =
            "plant_front_name IN(" + appState.companyVal + ")";
        }
        if (appState.companyVal !== "" && whereClauseEnergy !== "1 = 1") {
          whereClauseEnergy =
            whereClauseEnergy +
            " AND plant_front_name IN(" +
            appState.companyVal +
            ")";
        }
        if (appState.sectorVal !== "" && whereClauseEnergy === "1 = 1") {
          whereClauseEnergy = "product IN(" + appState.sectorVal + ")";
        }
        if (appState.sectorVal !== "" && whereClauseEnergy !== "1 = 1") {
          whereClauseEnergy =
            whereClauseEnergy + " AND product IN(" + appState.sectorVal + ")";
        }
        if (appState.vectorVal !== "" && whereClauseEnergy === "1 = 1") {
          whereClauseEnergy = "vector IN(" + appState.vectorVal + ")";
        }
        if (appState.vectorVal !== "" && whereClauseEnergy !== "1 = 1") {
          whereClauseEnergy =
            whereClauseEnergy + " AND vector IN(" + appState.vectorVal + ")";
        }
        const queryEnergy = EnergyFeatureLayer.createQuery();
        queryEnergy.where = whereClauseEnergy;
        queryEnergy.outFields = ["*"];
        queryEnergy.returnDistinctValues = false;
        queryEnergy.returnGeometry = false;
        queryEnergy.orderByFields = ["Total_sum DESC"];
        queryEnergy.groupByFieldsForStatistics = ["year,vector"];
        const validationParamsEnergy: any = [
          {
            statisticType: "sum",
            onStatisticField: "total_value_dollars_m",
            outStatisticFieldName: "Total_sum",
          },
        ];
        queryEnergy.outStatistics = validationParamsEnergy;

        EnergyFeatureLayer.queryFeatures(queryEnergy).then(function (response) {
          if (response.features) {
            const Energymixsorted: any = [];
            const yearsList: any = [];
            response.features.forEach((item) => {
              Energymixsorted.push({
                year: item.attributes.year,
                vector: item.attributes.vector,
                totalVectorSum: item.attributes.Total_sum,
                percentage: 0,
                TotalYearSum: 0,
              });
              if (yearsList.indexOf(item.attributes.year) === -1) {
                yearsList.push(item.attributes.year);
              }
            });
            let EnergyMixbarchartheighest: any = 0;
            yearsList.forEach((element: any) => {
              let totalYearSum = 0;
              Energymixsorted.forEach((item: any) => {
                if (element === item.year) {
                  totalYearSum = totalYearSum + item.totalVectorSum;
                }
              });
              if (totalYearSum > EnergyMixbarchartheighest) {
                EnergyMixbarchartheighest = totalYearSum;
              }
              Energymixsorted.forEach((item: any) => {
                if (element === item.year) {
                  item.percentage = (
                    (item.totalVectorSum * 100) /
                    totalYearSum
                  ).toFixed(1);
                  item.TotalYearSum = Math.round(totalYearSum);
                }
              });
            });
            const EnergymixsortedList = Energymixsorted.sort(
              (a: any, b: any) => parseFloat(a.year) - parseFloat(b.year)
            );
            const energyMixResults: any = [];
            yearsList.forEach((element: any) => {
              energyMixResults.push({ year: element });
            });
            EnergymixsortedList.forEach((element: any) => {
              energyMixResults.forEach((item: any) => {
                if (item.year === element.year) {
                  item[element.vector.trim().replace(" ", "_")] =
                    element.totalVectorSum.toFixed(1);
                  item[
                    element.vector.trim().replace(" ", "_") + "_Percentage"
                  ] = element.percentage;
                  item["TotalSum"] = element.TotalYearSum;
                }
              });
            });
            setyAxisEnergymix(
              parseInt(EnergyMixbarchartheighest) >= 1
                ? parseInt(EnergyMixbarchartheighest) + 10
                : parseInt(EnergyMixbarchartheighest) + 0.5
            );
            const energyResult = energyMixResults.sort(
              (a: any, b: any) => a.year - b.year
            );
            setdataMix(energyResult);
            setSpinner(false);
          }
        });
        // setspinnerComp(true);
        testActiveLink ? setspinnerComp(false) : setspinnerComp(true);
        const ToptenCompaniesFeatureLayer = new FeatureLayer({
          url: props.mit_scenario_table_spatial,
        });
        let whereClause = "1 = 1";
        if (appState.outputYearVal !== "") {
          whereClause = "year = " + appState.outputYearVal;
        }
        if (appState.energyOutlookVal !== "") {
          whereClause =
            whereClause + " AND pathway = '" + appState.energyOutlookVal + "'";
        }
        if (appState.regionVal !== "") {
          whereClause =
            whereClause + "AND country_name IN(" + appState.regionVal + ")";
        }
        if (appState.companyVal !== "" && whereClause === "1 = 1") {
          whereClause = "plant_front_name IN(" + appState.companyVal + ")";
        }
        if (appState.companyVal !== "" && whereClause !== "1 = 1") {
          whereClause =
            whereClause +
            " AND plant_front_name IN(" +
            appState.companyVal +
            ")";
        }
        if (appState.sectorVal !== "" && whereClause === "1 = 1") {
          whereClause = "product IN(" + appState.sectorVal + ")";
        }
        if (appState.sectorVal !== "" && whereClause !== "1 = 1") {
          whereClause =
            whereClause + " AND product IN(" + appState.sectorVal + ")";
        }
        if (appState.vectorVal !== "" && whereClause === "1 = 1") {
          whereClause = "vector IN(" + appState.vectorVal + ")";
        }
        if (appState.vectorVal !== "" && whereClause !== "1 = 1") {
          whereClause =
            whereClause + " AND vector IN(" + appState.vectorVal + ")";
        }
        const query = ToptenCompaniesFeatureLayer.createQuery();
        query.where = whereClause;
        query.outFields = ["total_value_dollars", "parent_company"];
        query.returnDistinctValues = false;
        query.returnGeometry = false;
        query.orderByFields = ["Total_sum DESC"];
        query.groupByFieldsForStatistics = ["parent_company"];
        const validationParamsPlant: any = [
          {
            statisticType: "sum",
            onStatisticField: "total_value_dollars",
            outStatisticFieldName: "Total_sum",
          },
          {
            statisticType: "count",
            onStatisticField: "parent_company",
            outStatisticFieldName: "Total_Count",
          },
        ];
        query.outStatistics = validationParamsPlant;

        ToptenCompaniesFeatureLayer.queryFeatures(query).then(function (
          response
        ) {
          if (response.features) {
            const topFeaturesbyCapacity = response.features.slice(0, 10);
            let sumofTotalCountry = 0;
            response.features.forEach((item) => {
              sumofTotalCountry = sumofTotalCountry + item.attributes.Total_sum;
            });
            const toptenbarchartData: any = [];

            topFeaturesbyCapacity.forEach((item) => {
              const itempercent = (
                (item.attributes.Total_sum * 100) /
                sumofTotalCountry
              ).toFixed(1);
              toptenbarchartData.push({
                Plant: item.attributes.parent_company,
                Capacity: item.attributes.Total_sum,
                Percentage: itempercent,
              });
            });
            if (toptenbarchartData.length > 0) {
              setyAxisComp(parseInt(toptenbarchartData[0].Percentage) + 25);
            }
            setdatatoptencomp(toptenbarchartData);
            setspinnerComp(false);
          }
        });
        // setspinnerCountry(true);
        testActiveLink ? setspinnerCountry(false) : setspinnerCountry(true);
        const querytopfiveCount = ToptenCompaniesFeatureLayer.createQuery();
        querytopfiveCount.where = whereClause;
        querytopfiveCount.outFields = ["total_value_dollars", "country_name"];
        querytopfiveCount.returnDistinctValues = false;
        querytopfiveCount.returnGeometry = false;
        querytopfiveCount.orderByFields = ["Total_sum DESC"];
        querytopfiveCount.groupByFieldsForStatistics = ["country_name"];
        const validationParamsCountry: any = [
          {
            statisticType: "sum",
            onStatisticField: "total_value_dollars",
            outStatisticFieldName: "Total_sum",
          },
          {
            statisticType: "count",
            onStatisticField: "country_name",
            outStatisticFieldName: "Total_Count",
          },
        ];
        querytopfiveCount.outStatistics = validationParamsCountry;
        ToptenCompaniesFeatureLayer.queryFeatures(querytopfiveCount).then(
          function (response) {
            if (response.features) {
              const topFiveFeaturesbyCountry = response.features.slice(0, 5);
              let sumofTotalCountry = 0;
              response.features.forEach((item) => {
                sumofTotalCountry =
                  sumofTotalCountry + item.attributes.Total_sum;
              });
              const topfivebarchartDatacountCountry: any = [];
              topFiveFeaturesbyCountry.forEach((item) => {
                const itempercent = (
                  (item.attributes.Total_sum * 100) /
                  sumofTotalCountry
                ).toFixed(1);
                topfivebarchartDatacountCountry.push({
                  Country: item.attributes.country_name,
                  Capacity: item.attributes.Total_sum,
                  Percentage: itempercent,
                });
              });
              if (topfivebarchartDatacountCountry.length > 0) {
                setyAxisCountry(
                  parseInt(topfivebarchartDatacountCountry[0].Percentage) + 10
                );
              }
              setdatatopfivecount(topfivebarchartDatacountCountry);
              setspinnerCountry(false);
            }
          }
        );
        // setspinnerSector(true);
        testActiveLink ? setspinnerSector(false) : setspinnerSector(true);
        const querytopfive = ToptenCompaniesFeatureLayer.createQuery();
        querytopfive.where = whereClause;
        querytopfive.outFields = ["total_value_dollars", "sector"];
        querytopfive.returnDistinctValues = false;
        querytopfive.returnGeometry = false;
        querytopfive.orderByFields = ["Total_sum DESC"];
        querytopfive.groupByFieldsForStatistics = ["sector"];
        const validationParams: any = [
          {
            statisticType: "sum",
            onStatisticField: "total_value_dollars",
            outStatisticFieldName: "Total_sum",
          },
          {
            statisticType: "count",
            onStatisticField: "sector",
            outStatisticFieldName: "Total_product",
          },
        ];
        querytopfive.outStatistics = validationParams;
        ToptenCompaniesFeatureLayer.queryFeatures(querytopfive).then(function (
          response
        ) {
          if (response.features) {
            const topFiveFeaturesbySector = response.features.slice(0, 5);
            let sumofTotalSector = 0;
            response.features.forEach((item) => {
              sumofTotalSector = sumofTotalSector + item.attributes.Total_sum;
            });
            const topfivebarchartDatacountSector: any = [];
            topFiveFeaturesbySector.forEach((item) => {
              const itempercent = (
                (item.attributes.Total_sum * 100) /
                sumofTotalSector
              ).toFixed(1);
              topfivebarchartDatacountSector.push({
                Sector: item.attributes.sector,
                Capacity: item.attributes.Total_sum,
                Percentage: itempercent,
              });
            });
            if (topfivebarchartDatacountSector.length > 0) {
              setyAxisSector(
                parseInt(topfivebarchartDatacountSector[0].Percentage) + 10
              );
            }
            setdatatopfiveSector(topfivebarchartDatacountSector);
            setspinnerSector(false);
          }
        });
      }
      if (activeLink === "Emissions") {
        setdatatoptencomp(null);
        setdatatopfivecount(null);
        setdatatopfiveSector(null);
        setdataMix(null);
        // setSpinner(true);
        testActiveLink ? setSpinner(false) : setSpinner(true);
        const EnergyFeatureLayer = new FeatureLayer({
          url: props.mit_scenario_table_spatial,
        });
        let whereClauseEnergy = "1 = 1";

        if (appState.energyOutlookVal !== "") {
          whereClauseEnergy = "pathway = '" + appState.energyOutlookVal + "'";
        }
        if (appState.regionVal !== "") {
          whereClauseEnergy =
            whereClauseEnergy +
            "AND country_name IN(" +
            appState.regionVal +
            ")";
        }
        if (appState.companyVal !== "" && whereClauseEnergy === "1 = 1") {
          whereClauseEnergy =
            "plant_front_name IN(" + appState.companyVal + ")";
        }
        if (appState.companyVal !== "" && whereClauseEnergy !== "1 = 1") {
          whereClauseEnergy =
            whereClauseEnergy +
            " AND plant_front_name IN(" +
            appState.companyVal +
            ")";
        }
        if (appState.sectorVal !== "" && whereClauseEnergy === "1 = 1") {
          whereClauseEnergy = "product IN(" + appState.sectorVal + ")";
        }
        if (appState.sectorVal !== "" && whereClauseEnergy !== "1 = 1") {
          whereClauseEnergy =
            whereClauseEnergy + " AND product IN(" + appState.sectorVal + ")";
        }
        if (appState.vectorVal !== "" && whereClauseEnergy === "1 = 1") {
          whereClauseEnergy = "vector IN(" + appState.vectorVal + ")";
        }
        if (appState.vectorVal !== "" && whereClauseEnergy !== "1 = 1") {
          whereClauseEnergy =
            whereClauseEnergy + " AND vector IN(" + appState.vectorVal + ")";
        }
        const queryEnergy = EnergyFeatureLayer.createQuery();
        queryEnergy.where =
          whereClauseEnergy +
          " AND vector In('Captured Emissions','Net Emissions')";
        queryEnergy.outFields = ["*"];
        queryEnergy.returnDistinctValues = false;
        queryEnergy.returnGeometry = false;
        queryEnergy.orderByFields = ["Total_sum DESC"];
        queryEnergy.groupByFieldsForStatistics = ["year,vector"];
        const validationParamsEnergy: any = [
          {
            statisticType: "sum",
            onStatisticField: "total_vector_energy",
            outStatisticFieldName: "Total_sum",
          },
        ];
        queryEnergy.outStatistics = validationParamsEnergy;

        EnergyFeatureLayer.queryFeatures(queryEnergy).then(function (response) {
          if (response.features) {
            const Energymixsorted: any = [];
            const yearsList: any = [];
            response.features.forEach((item) => {
              Energymixsorted.push({
                year: item.attributes.year,
                vector: item.attributes.vector,
                totalVectorSum: item.attributes.Total_sum,
                percentage: 0,
                TotalYearSum: 0,
              });
              if (yearsList.indexOf(item.attributes.year) === -1) {
                yearsList.push(item.attributes.year);
              }
            });
            let EnergyMixbarchartheighest: any = 0;
            yearsList.forEach((element: any) => {
              let totalYearSum = 0;
              Energymixsorted.forEach((item: any) => {
                if (element === item.year) {
                  totalYearSum = totalYearSum + item.totalVectorSum;
                }
              });
              if (totalYearSum > EnergyMixbarchartheighest) {
                EnergyMixbarchartheighest = totalYearSum;
              }
              Energymixsorted.forEach((item: any) => {
                if (element === item.year) {
                  item.percentage = (
                    (item.totalVectorSum * 100) /
                    totalYearSum
                  ).toFixed(1);
                  item.TotalYearSum = Math.round(totalYearSum);
                }
              });
            });
            const EnergymixsortedList = Energymixsorted.sort(
              (a: any, b: any) => parseFloat(a.year) - parseFloat(b.year)
            );
            const energyMixResults: any = [];
            yearsList.forEach((element: any) => {
              energyMixResults.push({ year: element });
            });
            EnergymixsortedList.forEach((element: any) => {
              energyMixResults.forEach((item: any) => {
                if (item.year === element.year) {
                  item[element.vector.trim().replace(" ", "_")] =
                    element.totalVectorSum.toFixed(1);
                  item[
                    element.vector.trim().replace(" ", "_") + "_Percentage"
                  ] = element.percentage;
                  item["TotalSum"] = element.TotalYearSum;
                }
              });
            });
            setyAxisEnergymix(
              parseInt(EnergyMixbarchartheighest) >= 1
                ? parseInt(EnergyMixbarchartheighest) + 10
                : parseInt(EnergyMixbarchartheighest) + 0.5
            );
            const energyResult = energyMixResults.sort(
              (a: any, b: any) => a.year - b.year
            );
            setdataMix(energyResult);
            setSpinner(false);
          }
        });
        // setspinnerComp(true);
        testActiveLink ? setspinnerComp(false) : setspinnerComp(true);
        const ToptenCompaniesFeatureLayer = new FeatureLayer({
          url: props.mit_scenario_table_spatial,
        });
        let whereClause = "1 = 1";
        if (appState.outputYearVal !== "") {
          whereClause = "year = " + appState.outputYearVal;
        }
        if (appState.energyOutlookVal !== "") {
          whereClause =
            whereClause + " AND pathway = '" + appState.energyOutlookVal + "'";
        }
        if (appState.regionVal !== "") {
          whereClause =
            whereClause + "AND country_name IN(" + appState.regionVal + ")";
        }
        if (appState.companyVal !== "" && whereClause === "1 = 1") {
          whereClause = "plant_front_name IN(" + appState.companyVal + ")";
        }
        if (appState.companyVal !== "" && whereClause !== "1 = 1") {
          whereClause =
            whereClause +
            " AND plant_front_name IN(" +
            appState.companyVal +
            ")";
        }
        if (appState.sectorVal !== "" && whereClause === "1 = 1") {
          whereClause = "product IN(" + appState.sectorVal + ")";
        }
        if (appState.sectorVal !== "" && whereClause !== "1 = 1") {
          whereClause =
            whereClause + " AND product IN(" + appState.sectorVal + ")";
        }
        if (appState.vectorVal !== "" && whereClause === "1 = 1") {
          whereClause = "vector IN(" + appState.vectorVal + ")";
        }
        if (appState.vectorVal !== "" && whereClause !== "1 = 1") {
          whereClause =
            whereClause + " AND vector IN(" + appState.vectorVal + ")";
        }
        const query = ToptenCompaniesFeatureLayer.createQuery();
        query.where = whereClause + " AND vector In('Net Emissions')";
        query.outFields = ["total_vector_energy", "parent_company"];
        query.returnDistinctValues = false;
        query.returnGeometry = false;
        query.orderByFields = ["Total_sum DESC"];
        query.groupByFieldsForStatistics = ["parent_company"];
        const validationParamsPlant: any = [
          {
            statisticType: "sum",
            onStatisticField: "total_vector_energy",
            outStatisticFieldName: "Total_sum",
          },
          {
            statisticType: "count",
            onStatisticField: "parent_company",
            outStatisticFieldName: "Total_Count",
          },
        ];
        query.outStatistics = validationParamsPlant;

        ToptenCompaniesFeatureLayer.queryFeatures(query).then(function (
          response
        ) {
          if (response.features) {
            const topFeaturesbyCapacity = response.features.slice(0, 10);
            let sumofTotalCountry = 0;
            response.features.forEach((item) => {
              sumofTotalCountry = sumofTotalCountry + item.attributes.Total_sum;
            });
            const toptenbarchartData: any = [];

            topFeaturesbyCapacity.forEach((item) => {
              const itempercent = (
                (item.attributes.Total_sum * 100) /
                sumofTotalCountry
              ).toFixed(1);
              toptenbarchartData.push({
                Plant: item.attributes.parent_company,
                Capacity: item.attributes.Total_sum,
                Percentage: itempercent,
              });
            });
            if (toptenbarchartData.length > 0) {
              setyAxisComp(parseInt(toptenbarchartData[0].Percentage) + 25);
            }
            setdatatoptencomp(toptenbarchartData);
            setspinnerComp(false);
          }
        });
        // setspinnerCountry(true);
        testActiveLink ? setspinnerCountry(false) : setspinnerCountry(true);

        const querytopfiveCount = ToptenCompaniesFeatureLayer.createQuery();
        querytopfiveCount.where =
          whereClause + " AND vector In('Net Emissions')";
        querytopfiveCount.outFields = ["total_vector_energy", "country_name"];
        querytopfiveCount.returnDistinctValues = false;
        querytopfiveCount.returnGeometry = false;
        querytopfiveCount.orderByFields = ["Total_sum DESC"];
        querytopfiveCount.groupByFieldsForStatistics = ["country_name"];
        const validationParamsCountry: any = [
          {
            statisticType: "sum",
            onStatisticField: "total_vector_energy",
            outStatisticFieldName: "Total_sum",
          },
          {
            statisticType: "count",
            onStatisticField: "country_name",
            outStatisticFieldName: "Total_Count",
          },
        ];
        querytopfiveCount.outStatistics = validationParamsCountry;
        ToptenCompaniesFeatureLayer.queryFeatures(querytopfiveCount).then(
          function (response) {
            if (response.features) {
              const topFiveFeaturesbyCountry = response.features.slice(0, 5);
              let sumofTotalCountry = 0;
              response.features.forEach((item) => {
                sumofTotalCountry =
                  sumofTotalCountry + item.attributes.Total_sum;
              });
              const topfivebarchartDatacountCountry: any = [];
              topFiveFeaturesbyCountry.forEach((item) => {
                const itempercent = (
                  (item.attributes.Total_sum * 100) /
                  sumofTotalCountry
                ).toFixed(1);
                topfivebarchartDatacountCountry.push({
                  Country: item.attributes.country_name,
                  Capacity: item.attributes.Total_sum,
                  Percentage: itempercent,
                });
              });
              if (topfivebarchartDatacountCountry.length > 0) {
                setyAxisCountry(
                  parseInt(topfivebarchartDatacountCountry[0].Percentage) + 10
                );
              }
              setdatatopfivecount(topfivebarchartDatacountCountry);
              setspinnerCountry(false);
            }
          }
        );
        // setspinnerSector(true);
        testActiveLink ? setspinnerSector(false) : setspinnerSector(true);

        const querytopfive = ToptenCompaniesFeatureLayer.createQuery();
        querytopfive.where =
          whereClause + " AND vector In('Captured Emissions','Net Emissions')";
        querytopfive.outFields = ["total_vector_energy", "sector"];
        querytopfive.returnDistinctValues = false;
        querytopfive.returnGeometry = false;
        querytopfive.orderByFields = ["Total_sum DESC"];
        querytopfive.groupByFieldsForStatistics = ["sector,vector"];
        const validationParams: any = [
          {
            statisticType: "sum",
            onStatisticField: "total_vector_energy",
            outStatisticFieldName: "Total_sum",
          },
          {
            statisticType: "count",
            onStatisticField: "sector",
            outStatisticFieldName: "Total_product",
          },
        ];
        querytopfive.outStatistics = validationParams;
        ToptenCompaniesFeatureLayer.queryFeatures(querytopfive).then(function (
          response
        ) {
          if (response.features) {
            response.features = response.features.slice(0, 5);
            const Energymixsorted: any = [];
            const yearsList: any = [];
            response.features.forEach((item) => {
              Energymixsorted.push({
                sector: item.attributes.sector,
                vector: item.attributes.vector,
                totalVectorSum: item.attributes.Total_sum,
                percentage: 0,
                TotalYearSum: 0,
              });
              if (yearsList.indexOf(item.attributes.sector) === -1) {
                yearsList.push(item.attributes.sector);
              }
            });
            let EnergyMixbarchartheighest: any = 0;
            yearsList.forEach((element: any) => {
              let totalYearSum = 0;
              Energymixsorted.forEach((item: any) => {
                if (element === item.sector) {
                  totalYearSum = totalYearSum + item.totalVectorSum;
                }
              });
              if (totalYearSum > EnergyMixbarchartheighest) {
                EnergyMixbarchartheighest = totalYearSum;
              }
              Energymixsorted.forEach((item: any) => {
                if (element === item.sector) {
                  item.percentage = (
                    (item.totalVectorSum * 100) /
                    totalYearSum
                  ).toFixed(1);
                  item.TotalYearSum = totalYearSum.toFixed(1);
                }
              });
            });
            const EnergymixsortedList = Energymixsorted.sort(
              (a: any, b: any) => parseFloat(a.sector) - parseFloat(b.sector)
            );
            const energyMixResults: any = [];
            yearsList.forEach((element: any) => {
              energyMixResults.push({ sector: element });
            });
            EnergymixsortedList.forEach((element: any) => {
              energyMixResults.forEach((item: any) => {
                if (item.sector === element.sector) {
                  item[element.vector.trim().replace(" ", "_")] =
                    element.totalVectorSum.toFixed(1);
                  item[
                    element.vector.trim().replace(" ", "_") + "_Percentage"
                  ] = element.percentage;
                  item["TotalSum"] = element.TotalYearSum;
                }
              });
            });
            setyAxisEnergymix(
              parseInt(EnergyMixbarchartheighest) >= 1
                ? parseInt(EnergyMixbarchartheighest) + 1
                : parseInt(EnergyMixbarchartheighest) + 0.5
            );

            setyAxisSector(parseInt(EnergyMixbarchartheighest) + 1);

            setdatatopfiveSector(energyMixResults);
            setspinnerSector(false);
          }
        });
      }
    }
  }, [appState, activeLink]);

  const CustomTooltip = (O: any) => {
    const { active, payload, label } = O;
    if (active && payload && payload.length) {
      return (
        <div
          className="custom-tooltip"
          style={{ backgroundColor: "white", border: "1px solid black" }}
        >
          <p className="label"> {`${label}`}</p>
          <p className="label">
            {" "}
            {`Percentage : ${payload[0].payload.Percentage} %`}
          </p>
          <p className="label">
            {activeLink !== "Energy"
              ? `${activeLink} : ${payload[0].payload.Capacity.toFixed(1)
                  .toLocaleString("en-US")
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ${activeUnit}`
              : `${activeLink} : ${payload[0].payload.Capacity.toFixed(1)
                  .toLocaleString("en-US")
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ${activeUnit}`}
          </p>
        </div>
      );
    }

    return null;
  };

  const CustomLabelPercentage = (props: any) => {
    const { x, y, width, height, value } = props;
    if (value) {
      const fireOffset = value.toString().length < 5;
      const offset = width < 50 ? 30 : 10;
      if (Number.isNaN(props.value)) {
        return <text></text>;
      }
      return (
        <text
          x={x + offset}
          y={y + height - 4}
          fill={fireOffset ? "#285A64" : "#fff"}
          textAnchor="start"
        >
          {`${value
            .toLocaleString("en-US")
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} %`}
        </text>
      );
    }
  };
  const CustomLabelPercentageVert = (props: any) => {
    const { x, y, width, height, value } = props;

    if (value) {
      const fireOffset = value.toString().length < 5;
      const offset = height < 30 ? -10 : -height + 20;
      return (
        <text
          x={x + width}
          y={y + height + offset}
          textAnchor="end"
          color="black"
        >
          {`${value
            .toLocaleString("en-US")
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} %`}
        </text>
      );
    }
  };
  const CustomLabelPercentageVertCap = (props: any) => {
    const { x, y, width, height, value } = props;
    if (value) {
      const fireOffset = value.toString().length < 5;
      const offset = height < 30 ? -25 : -height - 5;
      return (
        <text
          x={x + width + 10}
          y={y + height + offset}
          fill={fireOffset ? "#285A64" : "#fff"}
          textAnchor="end"
        >
          {activeLink !== "Energy"
            ? `${value
                .toFixed(1)
                .toLocaleString("en-US")
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ${activeUnit}`
            : `${value
                .toFixed(1)
                .toLocaleString("en-US")
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ${activeUnit}`}
        </text>
      );
    }
  };
  const renderCustomizedLabel = (props: any) => {
    const { x, y, width, height, value } = props;
    if (value) {
      const fireOffset = value.toString().length < 5;
      const offset = width < 200 ? 60 : 30;
      return (
        <text
          x={x + width}
          y={y + height - 5}
          fill={fireOffset ? "#285A64" : "#fff"}
          textAnchor="start"
        >
          {activeLink !== "Energy"
            ? `${value
                .toFixed(1)
                .toLocaleString("en-US")
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ${activeUnit}`
            : `${value
                .toFixed(1)
                .toLocaleString("en-US")
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ${activeUnit}`}
        </text>
      );
    }
  };

  return (
    <div className="-chartTab" data-testid="chartTab">
      <div className="ChartControls">
        <Navbar expand className="navbar-border">
          <div className="d-flex align-items-center">
            <Nav navbar className={`d-none d-${breakpoint}-flex flex-row`}>
              <NavItem>
                <NavLink
                  tag={Link}
                  className={activeLink === "Energy" ? "active" : ""}
                  onClick={() => setActiveNavLink("Energy")}
                >
                  <span>Site Energy Consumption (TJ)</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  tag={Link}
                  className={activeLink === "Emissions" ? "active" : ""}
                  onClick={() => setActiveNavLink("Emissions")}
                >
                  <span>Site Emissions (Mt CO2e)</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  tag={Link}
                  className={activeLink === "Capacity" ? "active" : ""}
                  onClick={() => setActiveNavLink("Capacity")}
                >
                  <span>Site Capacity (kt, MW or kbd)</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  tag={Link}
                  className={activeLink === "Value" ? "active" : ""}
                  onClick={() => setActiveNavLink("Value")}
                >
                  <span>Potential Value (GM $)</span>
                </NavLink>
              </NavItem>
            </Nav>
          </div>
        </Navbar>
      </div>
      <div className="ChartDiv">
        {activeLink === "Capacity" ? (
          <span
            style={{ color: "red", fontSize: "smaller", marginLeft: "20px" }}
          >
            *Please select one sector when using this view, as units differ.
            Units: Cement, Steel, Chemicals, Glass, Aluminium = kt // Data
            Centres, Gas Power Plants = MW // Refineries = kbd
          </span>
        ) : null}
        <div className="chartside">
          {activeLink === "Emissions" ? (
            <div className="Bounds">
              <span className="Energy-mix">Emissions mix</span>
              <div className="rule-F2F2F2"></div>
              {spinner === true ? (
                <Spinner
                  className="spinner-container"
                  color="brand"
                  data-testid="spinner"
                />
              ) : (
                ""
              )}
              <ResponsiveContainer
                width={!testChartwidth ? "90%" : testChartwidth}
                height={!testChartheight ? "90%" : testChartheight}
                style={{ marginLeft: 10 }}
              >
                <BarChart
                  width={450}
                  barGap="10%"
                  barCategoryGap="17%"
                  data={dataMix}
                  margin={{
                    top: 20,
                    right: 20,
                    left: 20,
                    bottom: 20,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="year" />
                  <YAxis
                    type="number"
                    label={{
                      value: "Emissions (Mt CO2e)",
                      angle: -90,
                      position: "left",
                    }}
                    domain={[0, yAxisEnergymix]}
                    allowDataOverflow={false}
                  />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="Net_Emissions" stackId="a" fill="#666666" />
                  <Bar
                    dataKey="Captured_Emissions"
                    stackId="a"
                    fill="#660099"
                  />
                </BarChart>
              </ResponsiveContainer>
              <span style={{ color: "red", fontSize: "smaller" }}>
                *2024 outputs include all sectors, beyond 2024 only the Steel,
                Cement and Chemicals sectors at this time.
              </span>
            </div>
          ) : (
            <div className="Bounds">
              <span className="Energy-mix">
                {`${activeLink === "Capacity" ? "Energy" : activeLink}`} mix
              </span>
              <div className="rule-F2F2F2"></div>
              {spinner === true ? (
                <Spinner className="spinner-container" color="brand" />
              ) : (
                ""
              )}
              <ResponsiveContainer
                width={!testChartwidth ? "90%" : testChartwidth}
                height={!testChartheight ? "90%" : testChartheight}
                style={{ marginLeft: 10 }}
                id="chart-container"
              >
                <BarChart
                  width={450}
                  barGap="10%"
                  barCategoryGap="17%"
                  data={dataMix}
                  margin={{
                    top: 20,
                    right: 20,
                    left: 30,
                    bottom: 20,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="year" />
                  <YAxis
                    type="number"
                    label={{
                      value: `${
                        activeLink === "Value"
                          ? "Annual value (GM M$)"
                          : "Energy(TJ)"
                      }`,
                      angle: -90,
                      position: "left",
                    }}
                    domain={[0, yAxisEnergymix]}
                    allowDataOverflow={false}
                  />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="Electricity" stackId="a" fill="#660099" />
                  <Bar dataKey="Oil" stackId="a" fill="#99CC00" />
                  <Bar dataKey="Oil_fs" stackId="a" fill="#669900" />
                  <Bar dataKey="Biofeedstock" stackId="a" fill="#00B800" />
                  <Bar dataKey="Coal" stackId="a" fill="#666666" />
                  <Bar dataKey="Natural_Gas" stackId="a" fill="#d24614" />
                  <Bar dataKey="Biomethane" stackId="a" fill="#FFE600" />
                  <Bar dataKey="Hydrogen" stackId="a" fill="#E49EDD" />
                  <Bar dataKey="Hydrogen_fs" stackId="a" fill="#F2CEEF" />
                  <Bar dataKey="Grey_Hydrogen_fs" stackId="a" fill="#999999" />
                  <Bar dataKey="Biomass" stackId="a" fill="#007F00" />
                  <Bar dataKey="Waste" stackId="a" fill="#00FF00" />
                  <Bar dataKey="Heat" stackId="a" fill="#FF9900" />
                  {activeLink === "Value" ? (
                    <Bar
                      dataKey="Captured_Emissions"
                      stackId="a"
                      fill="#660099"
                    />
                  ) : null}
                </BarChart>
              </ResponsiveContainer>
              <span style={{ color: "red", fontSize: "smaller" }}>
                *2024 outputs include all sectors, beyond 2024 only the Steel,
                Cement and Chemicals sectors at this time.
              </span>
            </div>
          )}
          <div className="Bounds">
            <span className="Energy-mix">
              Top 10 companies by{" "}
              {activeLink === "Value"
                ? " annual value (GM $)"
                : `${activeLink.toLowerCase()}`}
            </span>
            <div className="rule-F2F2F2"></div>
            {spinnerComp === true ? (
              <Spinner className="spinner-container-comp" color="brand" />
            ) : (
              ""
            )}
            <ResponsiveContainer
              width={!testChartwidth ? "90%" : testChartwidth}
              height={!testChartheight ? "90%" : testChartheight}
              style={{ marginLeft: 15 }}
            >
              <BarChart
                layout="vertical"
                width={450}
                height={350}
                barGap="10%"
                barCategoryGap="17%"
                data={datatoptencomp}
                margin={{
                  top: 20,
                  right: 20,
                  bottom: 20,
                  left: 20,
                }}
              >
                <XAxis type="number">
                  <Label
                    value={`% of total  ${activeLink}`}
                    offset={0}
                    position="bottom"
                  />
                  domain={[0, yAxisComp + 5]}
                </XAxis>
                <YAxis
                  dataKey="Plant"
                  type="category"
                  scale="auto"
                  label={{
                    value: "Plants/Companies",
                    angle: -90,
                    position: "left",
                  }}
                  width={150}
                  interval={0}
                  allowDataOverflow={false}
                />
                <Tooltip content={<CustomTooltip />} />
                <Bar dataKey="Percentage" barSize={20} fill="#0077be">
                  <LabelList
                    dataKey="Capacity"
                    content={renderCustomizedLabel}
                    position="insideRight"
                    style={{ fill: "white" }}
                  />
                  <LabelList
                    dataKey="Percentage"
                    content={CustomLabelPercentage}
                    position={"center"}
                    style={{ fill: "white" }}
                  />
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
        <div className="chartside">
          <div className="Bounds">
            <span className="Energy-mix">
              Top 5 countries by{" "}
              {activeLink === "Value"
                ? " annual value (GM $)"
                : `${activeLink.toLowerCase()}`}
            </span>
            <div className="rule-F2F2F2"></div>
            {spinnerCountry === true ? (
              <Spinner className="spinner-container-country" color="brand" />
            ) : (
              ""
            )}
            <ResponsiveContainer
              width={!testChartwidth ? "90%" : testChartwidth}
              height={!testChartheight ? "90%" : testChartheight}
              style={{ marginLeft: 10 }}
            >
              <BarChart
                width={450}
                barGap="10%"
                barCategoryGap="17%"
                data={datatopfivecount}
                margin={{
                  top: 20,
                  right: 20,
                  bottom: 20,
                  left: 20,
                }}
              >
                <YAxis
                  type="number"
                  label={{
                    value: `% of total ${activeLink} by country`,
                    angle: -90,
                    position: "left",
                  }}
                  domain={[0, yAxisCountry]}
                  allowDataOverflow={false}
                ></YAxis>
                <XAxis dataKey="Country" type="category" scale="auto">
                  {" "}
                  <Label value="Country" offset={0} position="bottom" />
                </XAxis>
                <Tooltip content={<CustomTooltip />} />
                <Bar dataKey="Percentage" barSize={40} fill="#0077be">
                  {" "}
                  <LabelList
                    dataKey="Capacity"
                    content={CustomLabelPercentageVertCap}
                    position="top"
                    angle={0}
                  />
                  <LabelList
                    dataKey="Percentage"
                    content={CustomLabelPercentageVert}
                    position={"insideTop"}
                    style={{ fill: "white" }}
                    angle={90}
                  />
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </div>
          {activeLink !== "Emissions" ? (
            <div className="Bounds">
              <span className="Energy-mix">
                Top 5 sectors by{" "}
                {activeLink === "Value"
                  ? " annual value (GM $)"
                  : `${activeLink.toLowerCase()}`}
              </span>
              <div className="rule-F2F2F2"></div>
              {spinnerSector === true ? (
                <Spinner className="spinner-container-sector" color="brand" />
              ) : (
                ""
              )}
              <ResponsiveContainer
                width={!testChartwidth ? "90%" : testChartwidth}
                height={!testChartheight ? "90%" : testChartheight}
                style={{ marginLeft: 10 }}
              >
                <BarChart
                  width={450}
                  barGap="10%"
                  barCategoryGap="17%"
                  data={datatopfiveSector}
                  margin={{
                    top: 20,
                    right: 20,
                    bottom: 20,
                    left: 20,
                  }}
                >
                  <YAxis
                    type="number"
                    label={{
                      value: `% of total ${activeLink} by Sector`,
                      angle: -90,
                      position: "left",
                    }}
                    domain={[0, yAxisSector]}
                    allowDataOverflow={false}
                  ></YAxis>
                  <XAxis dataKey="Sector" type="category" scale="auto">
                    <Label value="Sectors" offset={0} position="bottom" />
                  </XAxis>
                  <Tooltip content={<CustomTooltip />} />
                  <Bar dataKey="Percentage" barSize={40} fill="#0077be">
                    <LabelList
                      dataKey="Capacity"
                      content={CustomLabelPercentageVertCap}
                      position="top"
                      angle={0}
                    />
                    <LabelList
                      dataKey="Percentage"
                      content={CustomLabelPercentageVert}
                      position={"insideTop"}
                      style={{ fill: "white" }}
                      angle={90}
                    />
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </div>
          ) : (
            <div className="Bounds">
              <span className="Energy-mix">
                Top 5 Sectors by {`${activeLink.toLowerCase()}`}
              </span>
              <div className="rule-F2F2F2"></div>
              {spinnerSector === true ? (
                <Spinner className="spinner-container-sector" color="brand" />
              ) : (
                ""
              )}
              <ResponsiveContainer
                width={!testChartwidth ? "90%" : testChartwidth}
                height={!testChartheight ? "90%" : testChartheight}
                style={{ marginLeft: 10 }}
              >
                <BarChart
                  width={450}
                  //height={350}
                  barGap="10%"
                  barCategoryGap="17%"
                  data={datatopfiveSector}
                  margin={{
                    top: 20,
                    right: 20,
                    bottom: 20,
                    left: 20,
                  }}
                >
                  <YAxis
                    type="number"
                    label={{
                      value: `Total ${activeLink} (Mt CO2e)`,
                      angle: -90,
                      position: "left",
                    }}
                    domain={[0, yAxisSector]}
                    allowDataOverflow={false}
                  ></YAxis>
                  <XAxis dataKey="sector" type="category" scale="auto">
                    <Label
                      value="Sectors (Mt CO2e)"
                      offset={30}
                      position="bottom"
                    />
                  </XAxis>
                  <Tooltip />
                  <Legend />

                  <Bar dataKey="Net_Emissions" stackId="a" fill="#666666" />
                  <Bar
                    dataKey="Captured_Emissions"
                    stackId="a"
                    fill="#660099"
                  />
                </BarChart>
              </ResponsiveContainer>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ChartTab;
