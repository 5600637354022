import React, { useEffect, useState } from "react";
import "./App.css";
import {
  GlobalStateProvider,
  useGlobalState,
} from "./Components/GlobalContext/GlobalContext";
import { Route, Routes } from "react-router-dom";
import Home from "./Components/Home/Home";
import Decarb from "./Components/Decarb/Decarb";
import Guide from "./Components/Guide/Guide";
import Login from "./Components/Login/Login";
import Header from "./Components/Header/Header";

//ESRI oAuth
import config from "@arcgis/core/config";
import OAuthInfo from "@arcgis/core/identity/OAuthInfo";
import esriId from "@arcgis/core/identity/IdentityManager";

import { envComponentProps } from "../src/Constants/envConstants.types";
import { envConstants } from "../src/Constants/envConstants";

import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";

import { reactPlugin } from "./ApplicationInsightsService";

function App(props: envComponentProps) {
  // useEffect(() => {
  //   config.portalUrl = props.portalURL;

  //   const oAuth = new OAuthInfo({
  //     appId: props.appID,
  //     portalUrl: props.portalURL,
  //     flowType: "auto",
  //     popup: false,
  //   });

  //   esriId.registerOAuthInfos([oAuth]);

  //   const credListener = esriId.on("credential-create", (e) => {
  //     if (e.credential.server === props.portalURL && e.credential.token) {
  //       console.log(appState.isAuthenticated);
  //       setappState((prevState) => ({
  //         ...prevState,
  //         isAuthenticated: true,
  //         isLoggedOut: false,
  //       }));

  //       console.log(appState.isAuthenticated);
  //     }
  //   });

  //   return () => {
  //     credListener.remove();
  //   };
  // }, [props.portalURL, props.appID]);
  const { appState, setappState } = useGlobalState();
  const handleResize = (e: any) => {
    console.log("I've been resized!" + e);
    if (e.target.innerWidth > 1100 && e.target.innerWidth < 1300) {
      document.body.style.resize = "80%";
    }
    if (e.target.innerWidth > 1300) {
      document.body.style.resize = "70%";
    }
  };

  return (
    <>
      <AppInsightsContext.Provider value={reactPlugin}>
        <GlobalStateProvider>
          <div
            style={{
              backgroundColor: appState.isAuthenticated === true ? "" : "white",
            }}
            data-testid="app-div"
          >
            <Header {...envConstants} />
            <Routes>
              <Route path="/" element={<Home {...envConstants} />} />
              <Route path="/Decarb" element={<Decarb {...envConstants} />} />
              <Route path="/Guide" element={<Guide />} />
              <Route path="/Login" element={<Login />} />
            </Routes>
          </div>
        </GlobalStateProvider>
      </AppInsightsContext.Provider>
    </>
  );
}

export default App;
