import React, {
  ChangeEvent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import "./TablesTab.css";
import { envComponentProps } from "../../Constants/envConstants.types";
import { Nav, NavItem, NavLink, Navbar } from "reactstrap";
import { Link } from "react-router-dom";
import { useGlobalState } from "../GlobalContext/GlobalContext";
import { MantineReactTable } from "mantine-react-table";

import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import esriConfig from "@arcgis/core/config.js";
import { Box, Button } from "@mantine/core";
import { IconDownload } from "@tabler/icons-react";

/* istanbul ignore next */
import { mkConfig, generateCsv, download } from "export-to-csv"; //or use your library of choice here

interface ExtendedProps extends envComponentProps {
  dataTable?: any[]; // Add the new dataTable property
}

const TablesTab: React.FC<ExtendedProps> = ({
  dataTable: propDataTable,
  ...props
}) => {
  const [expandedCompanyIds, setExpandedCompanyIds] = useState<string[]>([]);
  const [expandedDepartmentIds, setExpandedDepartmentIds] = useState<string[]>(
    []
  );
  const [expandedTable, setexpendedTable] = useState({});
  const { appState, setappState } = useGlobalState();
  const breakpoint = "lg";
  const [activeLink, setActiveLink] = useState("Volume");
  const [resetTool, setresetTool] = useState(true);
  const [dataTable, setDataTable] = useState<any[]>(propDataTable || []);
  const [exportData, setExportData] = useState<any>([]);
  const [loadingTable, setloadingTable] = useState(true);
  let dataFiltered: any = [];
  let countQueryOwner = 0;
  let countCountryArr = 0;
  const setActiveNavLink = (selectedKey: any) => {
    setActiveLink(selectedKey);
  };

  const csvConfig = mkConfig({
    fieldSeparator: ",",
    decimalSeparator: ".",
    useKeysAsHeaders: true,
    filename: "MIT_" + activeLink + "_" + new Date().toString(),
  });

  const handleExportData = () => {
    const exportDatatemplate: any = [];
    const existingData = JSON.parse(JSON.stringify(exportData));
    existingData.forEach((item: any) => {
      item.subRows.forEach((item2: any) => {
        item2.subRows.forEach((item3: any) => {
          exportDatatemplate.push(item3);
        });
      });
    });
    for (let i = 0; i < exportDatatemplate.length; i++) {
      delete exportDatatemplate[i]["subRows"];
      delete exportDatatemplate[i]["hasChild"];
    }
    const newArrayOfObj = exportDatatemplate.map((item: any) => {
      if (activeLink === "Volume") {
        return {
          Country: item.country,
          City: item.city,
          Company: item.company,
          plant_front_name: item.PlantName,
          Plant_Count: item.PlantCount,
          Sector: item.sector,
          Product: item.product,
          Production_Type: item.production_type,
          Capacity_kt_mw_kbd: item.capacity,
          Total_Energy_TJ: item.TotalEnergy,
          Biomass_kt: item.Vector_Biomass,
          Biomethane_mmbtu: item.Vector_Biomethane,
          Captured_Emissions_Mt: item.Vector_Captured_Emissions,
          Coal_kt: item.Vector_Coal,
          Electricity_MWh: item.Vector_Electricity,
          Heat_Mwh: item.Vector_Heat,
          Hydrogen_kt: item.Vector_Hydrogen,
          Natural_Gas_mmbtu: item.Vector_Natural_Gas,
          Oil_bbl: item.Vector_Oil,
          Waste_kt: item.Vector_Waste,
          Biofeedstock_kt: item.Vector_Biofeedstock,
          Grey_Hydrogen_fs_kt: item.Vector_Grey_Hydrogen_fs,
          Hydrogen_fs_kt: item.Vector_Hydrogen_fs,
          Oil_fs_bbl: item.Vector_Oil_fs,
          NetEmission_MtCO2e: item.Vector_Net_Emissions,
          Source: item.source,
        };
      } else {
        return {
          Country: item.country,
          City: item.city,
          Company: item.company,
          plant_front_name: item.PlantName,
          Plant_Count: item.PlantCount,
          Sector: item.sector,
          Product: item.product,
          Production_Type: item.production_type,
          Capacity_kt_mw_kbd: item.capacity,
          Total_Energy_$: item.TotalEnergy,
          Biomass_$: item.Vector_Biomass,
          Biomethane_$: item.Vector_Biomethane,
          Captured_Emissions_$: item.Vector_Captured_Emissions,
          Coal_$: item.Vector_Coal,
          Electricity_$: item.Vector_Electricity,
          Heat_$: item.Vector_Heat,
          Hydrogen_$: item.Vector_Hydrogen,
          Natural_Gas_$: item.Vector_Natural_gas,
          Oil_$: item.Vector_Oil,
          Waste_$: item.Vector_Waste,
          Biofeedstock_$: item.Vector_Biofeedstock,
          Grey_Hydrogen_fs_$: item.Vector_Grey_Hydrogen_fs,
          Hydrogen_fs_$: item.Vector_Hydrogen_fs,
          Oil_fs_$: item.Vector_Oil_fs,
          NetEmission_MtCO2e: item.Vector_Net_Emissions,
          Source: item.source,
        };
      }
    });
    const csv = generateCsv(csvConfig)(newArrayOfObj);
    download(csvConfig)(csv);
  };

  const columnsfield = [
    { header: "Country", accessorKey: "country" },
    { header: "Company", accessorKey: "company" },
    { header: "City", accessorKey: "city" },
    { header: "Plant Name", accessorKey: "PlantName" },
    { header: "Plant (Product) Count", accessorKey: "PlantCount" },
    { header: "Sector", accessorKey: "sector" },
    { header: "Product", accessorKey: "product" },
    { header: "Production Type", accessorKey: "production_type" },
    { header: "Capacity (kt, MW or kbd)", accessorKey: "capacity" },
    { header: "Total Energy (TJ)", accessorKey: "TotalEnergy" },
    { header: "Biomass (kt)", accessorKey: "Vector_Biomass" },
    { header: "Biomethane (mmbtu)", accessorKey: "Vector_Biomethane" },
    {
      header: "Captured Emissions (Mt)",
      accessorKey: "Vector_Captured_Emissions",
    },
    { header: "Coal (kt)", accessorKey: "Vector_Coal" },
    { header: "Electricity (MWh)", accessorKey: "Vector_Electricity" },
    { header: "Heat (MWh)", accessorKey: "Vector_Heat" },
    { header: "Hydrogen (kt)", accessorKey: "Vector_Hydrogen" },
    {
      header: "Natural Gas (mmbtu)",
      accessorKey: "Vector_Natural_Gas",
    },
    { header: "Oil (bbl)", accessorKey: "Vector_Oil" },
    { header: "Waste (kt)", accessorKey: "Vector_Waste" },
    { header: "Biofeedstock (kt)", accessorKey: "Vector_Biofeedstock" },
    { header: "Grey Hydrogen_fs (kt)", accessorKey: "Vector_Grey_Hydrogen_fs" },
    { header: "Hydrogen_fs (kt)", accessorKey: "Vector_Hydrogen_fs" },
    { header: "Oil_fs (bbl)", accessorKey: "Vector_Oil_fs" },
    {
      header: "Net Emissons (Mt CO2e)",
      accessorKey: "Vector_Net_Emissions",
    },
    { header: "Source", accessorKey: "source" },
    { header: "hasChild", accessorKey: "hasChild" },
  ];
  const columnsfieldValue = [
    { header: "Country", accessorKey: "country" },
    { header: "City", accessorKey: "city" },
    { header: "Company", accessorKey: "company" },
    { header: "Plant Name", accessorKey: "PlantName" },
    { header: "Plant (Product) Count", accessorKey: "PlantCount" },
    { header: "Sector", accessorKey: "sector" },
    { header: "Product", accessorKey: "product" },
    { header: "Production Type", accessorKey: "production_type" },
    { header: "Capacity (kt, MW or kbd)", accessorKey: "capacity" },
    { header: "Total value (GM $)", accessorKey: "TotalEnergy" },
    { header: "Biomass ($)", accessorKey: "Vector_Biomass" },
    { header: "Biomethane ($)", accessorKey: "Vector_Biomethane" },
    {
      header: "Captured Emissions ($)",
      accessorKey: "Vector_Captured_Emissions",
    },
    { header: "Coal ($)", accessorKey: "Vector_Coal" },
    { header: "Electricity ($)", accessorKey: "Vector_Electricity" },
    { header: "Heat($)", accessorKey: "Vector_Heat" },
    { header: "Hydrogen ($)", accessorKey: "Vector_Hydrogen" },
    {
      header: "Natural Gas ($)",
      accessorKey: "Vector_Natural_Gas",
    },
    { header: "Oil ($)", accessorKey: "Vector_Oil" },
    { header: "Waste ($)", accessorKey: "Vector_Waste" },
    { header: "Biofeedstock ($)", accessorKey: "Vector_Biofeedstock" },
    { header: "Grey Hydrogen_fs ($)", accessorKey: "Vector_Grey_Hydrogen_fs" },
    { header: "Hydrogen_fs ($)", accessorKey: "Vector_Hydrogen_fs" },
    { header: "Oil_fs ($)", accessorKey: "Vector_Oil_fs" },
    {
      header: "Net Emissons (Mt CO2e)",
      accessorKey: "Vector_Net_Emissions",
    },
    { header: "Source", accessorKey: "source" },
    { header: "hasChild", accessorKey: "hasChild" },
  ];
  useEffect(() => {
    if (appState.isAuthenticated) {
      const KeysArr = [
        "Vector_Biomass",
        "Vector_Biomethane",
        "Vector_Captured_Emissions",
        "Vector_Coal",
        "Vector_Electricity",
        "Vector_Heat",
        "Vector_Hydrogen",
        "Vector_Natural_Gas",
        "Vector_Oil",
        "Vector_Net_Emissions",
        "Vector_Waste",
        "Vector_Biofeedstock",
        "Vector_Grey_Hydrogen_fs",
        "Vector_Hydrogen_fs",
        "Vector_Oil_fs",
      ];

      const plantdetailLayer = new FeatureLayer({
        url: props.mit_scenario_table_spatial,
      });
      esriConfig.request.interceptors?.push({
        before(params) {
          if (params.url.includes("query")) {
            params.requestOptions.query.f = "json";
          }
        },
      });
      let whereClause = "1 = 1";
      if (appState.outputYearVal !== "") {
        whereClause = "year = " + appState.outputYearVal;
      }
      if (appState.energyOutlookVal !== "") {
        whereClause =
          whereClause + " AND pathway = '" + appState.energyOutlookVal + "'";
      }
      if (appState.regionVal !== "") {
        whereClause =
          whereClause + "AND country_name IN(" + appState.regionVal + ")";
      }
      if (appState.companyVal !== "" && whereClause === "1 = 1") {
        whereClause = "plant_front_name IN(" + appState.companyVal + ")";
      }
      if (appState.companyVal !== "" && whereClause !== "1 = 1") {
        whereClause =
          whereClause + " AND plant_front_name IN(" + appState.companyVal + ")";
      }
      if (appState.sectorVal !== "" && whereClause === "1 = 1") {
        whereClause = "product IN(" + appState.sectorVal + ")";
      }
      if (appState.sectorVal !== "" && whereClause !== "1 = 1") {
        whereClause =
          whereClause + " AND product IN(" + appState.sectorVal + ")";
      }

      whereClause = whereClause + " AND vector IN('Electricity')";

      const queryplantDetails = plantdetailLayer.createQuery();
      queryplantDetails.where = whereClause;
      queryplantDetails.outFields = [
        "plant_front_name",
        "sector",
        "product",
        "production_type",
        "source",
        "city",
        "vector_value",
      ];
      queryplantDetails.returnDistinctValues = true;
      queryplantDetails.returnGeometry = false;
      const plantDetails: any = [];
      plantdetailLayer
        .queryFeatures(queryplantDetails)
        .then((responsePlant: any) => {
          if (responsePlant.features) {
            responsePlant.features.forEach((element: any) => {
              plantDetails.push(element.attributes);
            });
            if (activeLink === "Volume") {
              setloadingTable(true);
              const countryObjArr: any = [];
              const TableLayer = new FeatureLayer({
                url: props.mit_scenario_table_spatial,
              });
              let whereClause = "1 = 1";
              if (appState.outputYearVal !== "") {
                whereClause = "year = " + appState.outputYearVal;
              }
              if (appState.energyOutlookVal !== "") {
                whereClause =
                  whereClause +
                  " AND pathway = '" +
                  appState.energyOutlookVal +
                  "'";
              }
              if (appState.regionVal !== "") {
                whereClause =
                  whereClause +
                  "AND country_name IN(" +
                  appState.regionVal +
                  ")";
              }
              if (appState.companyVal !== "" && whereClause === "1 = 1") {
                whereClause =
                  "plant_front_name IN(" + appState.companyVal + ")";
              }
              if (appState.companyVal !== "" && whereClause !== "1 = 1") {
                whereClause =
                  whereClause +
                  " AND plant_front_name IN(" +
                  appState.companyVal +
                  ")";
              }
              if (appState.sectorVal !== "" && whereClause === "1 = 1") {
                whereClause = "product IN(" + appState.sectorVal + ")";
              }
              if (appState.sectorVal !== "" && whereClause !== "1 = 1") {
                whereClause =
                  whereClause + " AND product IN(" + appState.sectorVal + ")";
              }
              if (appState.vectorVal !== "" && whereClause === "1 = 1") {
                whereClause = "vector IN(" + appState.vectorVal + ")";
              }
              if (appState.vectorVal !== "" && whereClause !== "1 = 1") {
                whereClause =
                  whereClause + " AND vector IN(" + appState.vectorVal + ")";
              }
              const querytopfive = TableLayer.createQuery();
              querytopfive.where = whereClause;
              querytopfive.outFields = ["*"];
              // querytopfive.returnDistinctValues = true;
              querytopfive.returnGeometry = false;
              querytopfive.orderByFields = ["country_name ASC"];
              querytopfive.groupByFieldsForStatistics = ["country_name,vector"];
              const validationParams: any = [
                {
                  statisticType: "sum",
                  onStatisticField: "capacity",
                  outStatisticFieldName: "Total_sum_capacity",
                },
                {
                  statisticType: "sum",
                  onStatisticField: "total_vector_energy",
                  outStatisticFieldName: "Total_sum_vector",
                },
                {
                  statisticType: "sum",
                  onStatisticField: "total_energy_tj",
                  outStatisticFieldName: "Total_sum_Energy",
                },
                {
                  statisticType: "count",
                  onStatisticField: "plant_front_name",
                  outStatisticFieldName: "Total_PlantCount",
                },
              ];
              querytopfive.outStatistics = validationParams;
              TableLayer.queryFeatures(querytopfive).then(function (response) {
                if (response.features) {
                  const countryName: any = [];
                  const tableDataNested: any = [];
                  response.features.forEach((item: any) => {
                    let index = -1;
                    const obj = tableDataNested.find((o: any, i: any) => {
                      if (o.country === item.attributes.country_name) {
                        index = i;
                        return true;
                      }
                    });
                    if (obj !== null && obj !== undefined && obj !== false) {
                      if (
                        (tableDataNested[index]["country"] =
                          item.attributes.country_name)
                      ) {
                        if (
                          "Vector_" +
                            item.attributes.vector.replace(" ", "_") ===
                          "Vector_Electricity"
                        ) {
                          tableDataNested[index]["PlantCount"] =
                            item.attributes.Total_PlantCount;
                          tableDataNested[index]["capacity"] =
                            item.attributes.Total_sum_capacity;
                        } else {
                          if (
                            tableDataNested[index]["PlantCount"] <
                            item.attributes.Total_PlantCount
                          ) {
                            tableDataNested[index]["PlantCount"] =
                              item.attributes.Total_PlantCount;
                          }
                        }

                        tableDataNested[index]["TotalEnergy"] =
                          tableDataNested[index]["TotalEnergy"] +
                          item.attributes.Total_sum_Energy;

                        tableDataNested[index][
                          "Vector_" + item.attributes.vector.replace(" ", "_")
                        ] = item.attributes.Total_sum_vector; //item.attributes.Total_sum_vector.toFixed(1);
                      }
                    } else {
                      countryName.push(
                        item.attributes.country_name.replace(/'/g, "''")
                      );
                      const VectorName =
                        "Vector_" + item.attributes.vector.replace(" ", "_");
                      let plantVectorcount = 0;
                      let capacityPlant = 0;
                      if (VectorName === "Vector_Electricity") {
                        plantVectorcount = item.attributes.Total_PlantCount;
                        capacityPlant = item.attributes.Total_sum_capacity;
                      } else {
                        plantVectorcount = item.attributes.Total_PlantCount;
                        capacityPlant = item.attributes.Total_sum_capacity;
                      }
                      tableDataNested.push({
                        country: item.attributes.country_name,
                        company: "",
                        PlantName: "",
                        city: "",
                        sector: "",
                        product: "",
                        production_type: "",
                        source: "",
                        vector_value_NetEmissions: "",
                        PlantCount: plantVectorcount,
                        [VectorName]: item.attributes.Total_sum_vector,
                        //  item.attributes.Total_sum_vector.toFixed(1),
                        TotalEnergy: item.attributes.Total_sum_Energy,
                        subRows: [],
                        capacity: capacityPlant,
                        hasChild: false,
                      });
                    }
                  });
                  for (let i = 0; i < tableDataNested.length; i++) {
                    for (let item = 0; item < KeysArr.length; item++) {
                      if (!(KeysArr[item] in tableDataNested[i])) {
                        tableDataNested[i][KeysArr[item]] = 0.0;
                      }
                    }
                  }

                  dataFiltered = tableDataNested;
                  let countPlantNo = 0;
                  let stringOwner = "";
                  dataFiltered.forEach((element: any) => {
                    if (element.PlantCount + countPlantNo < 500) {
                      stringOwner =
                        stringOwner +
                        "'" +
                        element.country.replace(/'/g, "''") +
                        "',";
                      countPlantNo = countPlantNo + element.PlantCount;
                    } else {
                      countryObjArr.push(
                        stringOwner.substring(0, stringOwner.length - 1)
                      );
                      countPlantNo = 0;
                      stringOwner = "";
                      if (element.PlantCount > 500) {
                        countryObjArr.push(
                          "'" + element.country.replace(/'/g, "''") + "'"
                        );
                      } else {
                        stringOwner =
                          stringOwner +
                          "'" +
                          element.country.replace(/'/g, "''") +
                          "',";
                        countPlantNo = countPlantNo + element.PlantCount;
                      }
                    }
                    if (
                      !countryObjArr.includes(stringOwner) &&
                      dataFiltered.indexOf(element) === dataFiltered.length - 1
                    ) {
                      countryObjArr.push(
                        stringOwner.substring(0, stringOwner.length - 1)
                      );
                    }
                  });
                  countCountryArr = countryObjArr.length;
                  const filtCountryObjArr = countryObjArr.filter(
                    (item: any) => item !== ""
                  );
                  countCountryArr = filtCountryObjArr.length;

                  filtCountryObjArr.forEach((ele: any) => {
                    const countriesString = ele;

                    const TableLayer = new FeatureLayer({
                      url: props.mit_scenario_table_spatial,
                    });
                    let whereClause = "1 = 1";
                    if (appState.outputYearVal !== "") {
                      whereClause = "year = " + appState.outputYearVal;
                    }
                    if (appState.energyOutlookVal !== "") {
                      whereClause =
                        whereClause +
                        " AND pathway = '" +
                        appState.energyOutlookVal +
                        "'";
                    }
                    if (appState.regionVal !== "") {
                      whereClause =
                        whereClause +
                        "AND country_name IN(" +
                        appState.regionVal +
                        ")";
                    }
                    if (appState.companyVal !== "" && whereClause === "1 = 1") {
                      whereClause =
                        "plant_front_name IN(" + appState.companyVal + ")";
                    }
                    if (appState.companyVal !== "" && whereClause !== "1 = 1") {
                      whereClause =
                        whereClause +
                        " AND plant_front_name IN(" +
                        appState.companyVal +
                        ")";
                    }
                    if (appState.sectorVal !== "" && whereClause === "1 = 1") {
                      whereClause = "product IN(" + appState.sectorVal + ")";
                    }
                    if (appState.sectorVal !== "" && whereClause !== "1 = 1") {
                      whereClause =
                        whereClause +
                        " AND product IN(" +
                        appState.sectorVal +
                        ")";
                    }
                    if (appState.vectorVal !== "" && whereClause === "1 = 1") {
                      whereClause = "vector IN(" + appState.vectorVal + ")";
                    }
                    if (appState.vectorVal !== "" && whereClause !== "1 = 1") {
                      whereClause =
                        whereClause +
                        " AND vector IN(" +
                        appState.vectorVal +
                        ")";
                    }
                    if (countriesString !== "") {
                      whereClause =
                        whereClause +
                        " AND country_name IN(" +
                        countriesString +
                        ")";
                    }
                    const querytopfive = TableLayer.createQuery();
                    querytopfive.where = whereClause;
                    querytopfive.outFields = ["*"];
                    // querytopfive.returnDistinctValues = true;
                    querytopfive.returnGeometry = false;
                    querytopfive.orderByFields = ["country_name ASC"];
                    querytopfive.groupByFieldsForStatistics = [
                      "country_name,parent_company,vector",
                    ];
                    const validationParams: any = [
                      {
                        statisticType: "sum",
                        onStatisticField: "capacity",
                        outStatisticFieldName: "Total_sum_capacity",
                      },
                      {
                        statisticType: "sum",
                        onStatisticField: "total_vector_energy",
                        outStatisticFieldName: "Total_sum_vector",
                      },
                      {
                        statisticType: "sum",
                        onStatisticField: "total_energy_tj",
                        outStatisticFieldName: "Total_sum_Energy",
                      },
                      {
                        statisticType: "count",
                        onStatisticField: "plant_front_name",
                        outStatisticFieldName: "Total_PlantCount",
                      },
                    ];
                    querytopfive.outStatistics = validationParams;
                    TableLayer.queryFeatures(querytopfive).then(function (
                      response
                    ) {
                      if (response.features) {
                        countQueryOwner++;
                        const tableDataNested: any = [];
                        response.features.forEach((item: any) => {
                          let index = -1;
                          const obj = tableDataNested.find((o: any, i: any) => {
                            if (
                              o.company === item.attributes.parent_company &&
                              o.country === item.attributes.country_name
                            ) {
                              index = i;
                              return true;
                            }
                          });
                          if (
                            obj !== null &&
                            obj !== undefined &&
                            obj !== false
                          ) {
                            if (
                              (tableDataNested[index]["company"] =
                                item.attributes.parent_company)
                            ) {
                              if (
                                "Vector_" +
                                  item.attributes.vector.replace(" ", "_") ===
                                "Vector_Electricity"
                              ) {
                                tableDataNested[index]["PlantCount"] =
                                  item.attributes.Total_PlantCount;
                                tableDataNested[index]["capacity"] =
                                  item.attributes.Total_sum_capacity;
                              }
                              tableDataNested[index]["TotalEnergy"] =
                                tableDataNested[index]["TotalEnergy"] +
                                item.attributes.Total_sum_Energy;

                              tableDataNested[index][
                                "Vector_" +
                                  item.attributes.vector.replace(" ", "_")
                              ] = item.attributes.Total_sum_vector; //item.attributes.Total_sum_vector.toFixed(1);
                            }
                          } else {
                            const VectorName =
                              "Vector_" +
                              item.attributes.vector.replace(" ", "_");
                            let plantVectorcount = 0;
                            let capacityPlant = 0;
                            if (VectorName === "Vector_Electricity") {
                              plantVectorcount =
                                item.attributes.Total_PlantCount;
                              capacityPlant =
                                item.attributes.Total_sum_capacity;
                            } else {
                              plantVectorcount =
                                item.attributes.Total_PlantCount;
                              capacityPlant =
                                item.attributes.Total_sum_capacity;
                            }
                            tableDataNested.push({
                              country: item.attributes.country_name,
                              PlantName: "",
                              city: "",
                              sector: "",
                              product: "",
                              production_type: "",
                              source: "",
                              vector_value_NetEmissions: "",
                              PlantCount: plantVectorcount,
                              [VectorName]: item.attributes.Total_sum_vector,
                              //item.attributes.Total_sum_vector.toFixed(1),
                              TotalEnergy: item.attributes.Total_sum_Energy,
                              company: item.attributes.parent_company,
                              subRows: [],
                              capacity: capacityPlant,
                              hasChild: true,
                            });
                          }
                        });
                        for (let i = 0; i < tableDataNested.length; i++) {
                          for (let item = 0; item < KeysArr.length; item++) {
                            if (!(KeysArr[item] in tableDataNested[i])) {
                              tableDataNested[i][KeysArr[item]] = 0.0;
                            }
                          }
                        }

                        const previousData: any = dataFiltered;
                        tableDataNested.forEach((element: any) => {
                          let indexChild = -1;
                          const obj = previousData.find((o: any, i: any) => {
                            if (o.country === element.country) {
                              indexChild = i;
                              return true;
                            }
                          });
                          if (indexChild !== -1) {
                            previousData[indexChild].hasChild = true;
                            previousData[indexChild].subRows.push(element);
                          }
                        });
                        if (countCountryArr === countQueryOwner) {
                          countCountryArr = countCountryArr + countCountryArr;
                          filtCountryObjArr.forEach((ele: any) => {
                            const countriesString = ele;

                            const TableLayer = new FeatureLayer({
                              url: props.mit_scenario_table_spatial,
                            });
                            let whereClause = "1 = 1";
                            if (appState.outputYearVal !== "") {
                              whereClause = "year = " + appState.outputYearVal;
                            }
                            if (appState.energyOutlookVal !== "") {
                              whereClause =
                                whereClause +
                                " AND pathway = '" +
                                appState.energyOutlookVal +
                                "'";
                            }
                            if (appState.regionVal !== "") {
                              whereClause =
                                whereClause +
                                "AND country_name IN(" +
                                appState.regionVal +
                                ")";
                            }
                            if (
                              appState.companyVal !== "" &&
                              whereClause === "1 = 1"
                            ) {
                              whereClause =
                                "plant_front_name IN(" +
                                appState.companyVal +
                                ")";
                            }
                            if (
                              appState.companyVal !== "" &&
                              whereClause !== "1 = 1"
                            ) {
                              whereClause =
                                whereClause +
                                " AND plant_front_name IN(" +
                                appState.companyVal +
                                ")";
                            }
                            if (
                              appState.sectorVal !== "" &&
                              whereClause === "1 = 1"
                            ) {
                              whereClause =
                                "product IN(" + appState.sectorVal + ")";
                            }
                            if (
                              appState.sectorVal !== "" &&
                              whereClause !== "1 = 1"
                            ) {
                              whereClause =
                                whereClause +
                                " AND product IN(" +
                                appState.sectorVal +
                                ")";
                            }
                            if (
                              appState.vectorVal !== "" &&
                              whereClause === "1 = 1"
                            ) {
                              whereClause =
                                "vector IN(" + appState.vectorVal + ")";
                            }
                            if (
                              appState.vectorVal !== "" &&
                              whereClause !== "1 = 1"
                            ) {
                              whereClause =
                                whereClause +
                                " AND vector IN(" +
                                appState.vectorVal +
                                ")";
                            }
                            if (countriesString !== "") {
                              whereClause =
                                whereClause +
                                " AND country_name IN(" +
                                countriesString +
                                ")";
                            }
                            const querytopfive = TableLayer.createQuery();
                            querytopfive.where = whereClause;
                            querytopfive.outFields = ["*"];
                            // querytopfive.returnDistinctValues = true;
                            querytopfive.returnGeometry = false;
                            querytopfive.orderByFields = ["country_name ASC"];
                            querytopfive.groupByFieldsForStatistics = [
                              "country_name,parent_company,plant_front_name,vector",
                            ];
                            const validationParams: any = [
                              {
                                statisticType: "sum",
                                onStatisticField: "capacity",
                                outStatisticFieldName: "Total_sum_capacity",
                              },
                              {
                                statisticType: "sum",
                                onStatisticField: "total_vector_energy",
                                outStatisticFieldName: "Total_sum_vector",
                              },
                              {
                                statisticType: "sum",
                                onStatisticField: "total_energy_tj",
                                outStatisticFieldName: "Total_sum_Energy",
                              },
                              {
                                statisticType: "count",
                                onStatisticField: "plant_front_name",
                                outStatisticFieldName: "Total_PlantCount",
                              },
                            ];
                            querytopfive.outStatistics = validationParams;
                            TableLayer.queryFeatures(querytopfive).then(
                              function (response) {
                                if (response.features) {
                                  countQueryOwner++;
                                  const tableDataNested: any = [];
                                  response.features.forEach((item: any) => {
                                    let index = -1;
                                    const obj = tableDataNested.find(
                                      (o: any, i: any) => {
                                        if (
                                          o.PlantName ===
                                            item.attributes.plant_front_name &&
                                          o.company ===
                                            item.attributes.parent_company &&
                                          o.country ===
                                            item.attributes.country_name
                                        ) {
                                          index = i;
                                          return true;
                                        }
                                      }
                                    );
                                    if (
                                      obj !== null &&
                                      obj !== undefined &&
                                      obj !== false
                                    ) {
                                      if (
                                        (tableDataNested[index]["company"] =
                                          item.attributes.parent_company)
                                      ) {
                                        if (
                                          "Vector_" +
                                            item.attributes.vector.replace(
                                              " ",
                                              "_"
                                            ) ===
                                          "Vector_Electricity"
                                        ) {
                                          tableDataNested[index]["PlantCount"] =
                                            item.attributes.Total_PlantCount;
                                          tableDataNested[index]["capacity"] =
                                            item.attributes.Total_sum_capacity;
                                        }
                                        if (
                                          "Vector_" +
                                            item.attributes.vector.replace(
                                              " ",
                                              "_"
                                            ) ===
                                          "Vector_Net_Emissions"
                                        ) {
                                          tableDataNested[index][
                                            "Vector_Net_Emissions"
                                          ] = item.attributes.Total_sum_vector;
                                        }

                                        tableDataNested[index]["TotalEnergy"] =
                                          tableDataNested[index][
                                            "TotalEnergy"
                                          ] + item.attributes.Total_sum_Energy;

                                        tableDataNested[index][
                                          "Vector_" +
                                            item.attributes.vector.replace(
                                              " ",
                                              "_"
                                            )
                                        ] = item.attributes.Total_sum_vector;
                                        // item.attributes.Total_sum_vector.toFixed(0);
                                      }
                                    } else {
                                      const VectorName =
                                        "Vector_" +
                                        item.attributes.vector.replace(
                                          " ",
                                          "_"
                                        );

                                      let plantVectorcount = 0;
                                      let capacityPlant = 0;
                                      if (VectorName === "Vector_Electricity") {
                                        plantVectorcount =
                                          item.attributes.Total_PlantCount;
                                        capacityPlant =
                                          item.attributes.Total_sum_capacity;
                                      } else {
                                        plantVectorcount =
                                          item.attributes.Total_PlantCount;
                                        capacityPlant =
                                          item.attributes.Total_sum_capacity;
                                      }
                                      let emission_Net = "";
                                      if (
                                        VectorName === "Vector_Net_Emissions"
                                      ) {
                                        emission_Net =
                                          item.attributes.Total_sum_vector;
                                      }
                                      tableDataNested.push({
                                        country: item.attributes.country_name,
                                        PlantName:
                                          item.attributes.plant_front_name,
                                        city: "",
                                        sector: "",
                                        product: "",
                                        production_type: "",
                                        source: "",
                                        Vector_Net_Emissions: emission_Net,
                                        PlantCount: plantVectorcount,
                                        [VectorName]:
                                          item.attributes.Total_sum_vector,
                                        // item.attributes.Total_sum_vector.toFixed(0),
                                        TotalEnergy:
                                          item.attributes.Total_sum_Energy,
                                        company: item.attributes.parent_company,
                                        subRows: [],
                                        capacity: capacityPlant,
                                        hasChild: true,
                                      });
                                    }
                                  });
                                  for (
                                    let i = 0;
                                    i < tableDataNested.length;
                                    i++
                                  ) {
                                    for (
                                      let item = 0;
                                      item < KeysArr.length;
                                      item++
                                    ) {
                                      if (
                                        !(KeysArr[item] in tableDataNested[i])
                                      ) {
                                        tableDataNested[i][KeysArr[item]] = 0.0;
                                      }
                                    }
                                  }

                                  const previousData: any = dataFiltered;
                                  const tablestructure: any = [];
                                  tableDataNested.forEach((element: any) => {
                                    let indexp = -1;
                                    const objPlantDetail = plantDetails.find(
                                      (o: any, i: any) => {
                                        if (
                                          o.plant_front_name ===
                                          element.PlantName
                                        ) {
                                          indexp = i;
                                          return true;
                                        }
                                      }
                                    );
                                    const indices = plantDetails
                                      .map((item: any, index: any) => {
                                        return item.plant_front_name ===
                                          element.PlantName
                                          ? index
                                          : -1;
                                      })
                                      .filter((index: any) => index !== -1);
                                    let productGroup = "";
                                    for (let c = 0; c < indices.length; c++) {
                                      c === 0
                                        ? (productGroup =
                                            plantDetails[indices[c]].product)
                                        : (productGroup =
                                            productGroup +
                                            " , " +
                                            plantDetails[indices[c]].product);
                                    }
                                    if (indexp !== -1) {
                                      element["city"] =
                                        plantDetails[indexp].city;
                                      element["sector"] =
                                        plantDetails[indexp].sector;
                                      element["production_type"] =
                                        plantDetails[indexp].production_type;
                                      element["product"] = productGroup;
                                      element["source"] =
                                        plantDetails[indexp].source;
                                    }
                                    let indexChild = -1;
                                    const obj = previousData.find(
                                      (o: any, i: any) => {
                                        if (o.country === element.country) {
                                          indexChild = i;
                                          return true;
                                        }
                                      }
                                    );
                                    if (indexChild !== -1) {
                                      if (
                                        previousData[indexChild].subRows
                                          .length > 0
                                      ) {
                                        previousData[
                                          indexChild
                                        ].subRows.forEach((iter: any) => {
                                          if (
                                            iter.company === element.company &&
                                            iter.subRows.includes(element) ===
                                              false
                                          ) {
                                            iter.subRows.push(element);
                                          }
                                        });
                                      }
                                    }
                                  });
                                  if (countCountryArr === countQueryOwner) {
                                    setDataTable([]);
                                    setresetTool(true);
                                    const dataexport = JSON.parse(JSON.stringify(previousData));
                                    setExportData(dataexport);
                                    previousData.forEach((element: any) => {
                                      const objectKeys = Object.keys(element);
                                      objectKeys.forEach((obj: any) => {
                                        if (obj !== "subRows") {
                                          if (parseFloat(element[obj])) {
                                            element[obj] = parseFloat(
                                              parseFloat(element[obj]).toFixed(
                                                1
                                              )
                                            );
                                          }
                                        } else {
                                          element[obj].forEach(
                                            (elementChild: any) => {
                                              const objectKeysChild =
                                                Object.keys(elementChild);
                                              objectKeysChild.forEach(
                                                (objChild: any) => {
                                                  if (objChild !== "subRows") {
                                                    if (
                                                      typeof elementChild[
                                                        objChild
                                                      ] !== "string" &&
                                                      parseFloat(
                                                        elementChild[objChild]
                                                      )
                                                    ) {
                                                      elementChild[objChild] =
                                                        parseFloat(
                                                          parseFloat(
                                                            elementChild[
                                                              objChild
                                                            ]
                                                          ).toFixed(1)
                                                        );
                                                    }
                                                  } else {
                                                    elementChild[
                                                      objChild
                                                    ].forEach(
                                                      (
                                                        elementChildLast: any
                                                      ) => {
                                                        const objectKeysChildLast =
                                                          Object.keys(
                                                            elementChildLast
                                                          );
                                                        objectKeysChildLast.forEach(
                                                          (
                                                            objChildLast: any
                                                          ) => {
                                                            if (
                                                              objChildLast !==
                                                              "subRows"
                                                            ) {
                                                              if (
                                                                typeof elementChildLast[
                                                                  objChildLast
                                                                ] !==
                                                                  "string" &&
                                                                parseFloat(
                                                                  elementChildLast[
                                                                    objChildLast
                                                                  ]
                                                                )
                                                              ) {
                                                                elementChildLast[
                                                                  objChildLast
                                                                ] = parseFloat(
                                                                  parseFloat(
                                                                    elementChildLast[
                                                                      objChildLast
                                                                    ]
                                                                  ).toFixed(1)
                                                                );
                                                              }
                                                            }
                                                          }
                                                        );
                                                      }
                                                    );
                                                  }
                                                }
                                              );
                                            }
                                          );
                                        }
                                      });
                                    });

                                    setDataTable(previousData);
                                    countCountryArr = countQueryOwner = 0;
                                    setloadingTable(false);
                                  }
                                }
                              }
                            );
                          });
                        }
                      }
                    });
                  });
                }
              });
            } else {
              setloadingTable(true);
              const countryObjArr: any = [];
              const TableLayer = new FeatureLayer({
                url: props.mit_scenario_table_spatial,
              });
              let whereClause = "1 = 1";
              if (appState.outputYearVal !== "") {
                whereClause = "year = " + appState.outputYearVal;
              }
              if (appState.energyOutlookVal !== "") {
                whereClause =
                  whereClause +
                  " AND pathway = '" +
                  appState.energyOutlookVal +
                  "'";
              }
              if (appState.regionVal !== "") {
                whereClause =
                  whereClause +
                  "AND country_name IN(" +
                  appState.regionVal +
                  ")";
              }
              if (appState.companyVal !== "" && whereClause === "1 = 1") {
                whereClause =
                  "plant_front_name IN(" + appState.companyVal + ")";
              }
              if (appState.companyVal !== "" && whereClause !== "1 = 1") {
                whereClause =
                  whereClause +
                  " AND plant_front_name IN(" +
                  appState.companyVal +
                  ")";
              }
              if (appState.sectorVal !== "" && whereClause === "1 = 1") {
                whereClause = "product IN(" + appState.sectorVal + ")";
              }
              if (appState.sectorVal !== "" && whereClause !== "1 = 1") {
                whereClause =
                  whereClause + " AND product IN(" + appState.sectorVal + ")";
              }
              if (appState.vectorVal !== "" && whereClause === "1 = 1") {
                whereClause = "vector IN(" + appState.vectorVal + ")";
              }
              if (appState.vectorVal !== "" && whereClause !== "1 = 1") {
                whereClause =
                  whereClause + " AND vector IN(" + appState.vectorVal + ")";
              }
              const querytopfive = TableLayer.createQuery();
              querytopfive.where = whereClause;
              querytopfive.outFields = ["*"];
              // querytopfive.returnDistinctValues = true;
              querytopfive.returnGeometry = false;
              querytopfive.orderByFields = ["country_name ASC"];
              querytopfive.groupByFieldsForStatistics = ["country_name,vector"];
              const validationParams: any = [
                {
                  statisticType: "sum",
                  onStatisticField: "capacity",
                  outStatisticFieldName: "Total_sum_capacity",
                },
                {
                  statisticType: "sum",
                  onStatisticField: "total_value_dollars",
                  outStatisticFieldName: "Total_sum_vector",
                },
                {
                  statisticType: "sum",
                  onStatisticField: "total_value_dollars",
                  outStatisticFieldName: "Total_sum_Energy",
                },
                {
                  statisticType: "count",
                  onStatisticField: "plant_front_name",
                  outStatisticFieldName: "Total_PlantCount",
                },
              ];
              querytopfive.outStatistics = validationParams;
              TableLayer.queryFeatures(querytopfive).then(function (response) {
                if (response.features) {
                  const countryName: any = [];
                  const tableDataNested: any = [];
                  response.features.forEach((item: any) => {
                    let index = -1;
                    const obj = tableDataNested.find((o: any, i: any) => {
                      if (o.country === item.attributes.country_name) {
                        index = i;
                        return true;
                      }
                    });
                    if (obj !== null && obj !== undefined && obj !== false) {
                      if (
                        (tableDataNested[index]["country"] =
                          item.attributes.country_name)
                      ) {
                        if (
                          "Vector_" +
                            item.attributes.vector.replace(" ", "_") ===
                          "Vector_Electricity"
                        ) {
                          tableDataNested[index]["PlantCount"] =
                            item.attributes.Total_PlantCount;
                          tableDataNested[index]["capacity"] =
                            item.attributes.Total_sum_capacity;
                        } else {
                          if (
                            tableDataNested[index]["PlantCount"] <
                            item.attributes.Total_PlantCount
                          ) {
                            tableDataNested[index]["PlantCount"] =
                              item.attributes.Total_PlantCount;
                          }
                        }
                        tableDataNested[index]["TotalEnergy"] =
                          tableDataNested[index]["TotalEnergy"] +
                          item.attributes.Total_sum_Energy;

                        tableDataNested[index][
                          "Vector_" + item.attributes.vector.replace(" ", "_")
                        ] = item.attributes.Total_sum_vector; //item.attributes.Total_sum_vector.toFixed(1);
                      }
                    } else {
                      countryName.push(item.attributes.country_name);
                      const VectorName =
                        "Vector_" + item.attributes.vector.replace(" ", "_");

                      let plantVectorcount = 0;
                      let capacityPlant = 0;
                      if (VectorName === "Vector_Electricity") {
                        plantVectorcount = item.attributes.Total_PlantCount;
                        capacityPlant = item.attributes.Total_sum_capacity;
                      } else {
                        plantVectorcount = item.attributes.Total_PlantCount;
                        capacityPlant = item.attributes.Total_sum_capacity;
                      }
                      tableDataNested.push({
                        country: item.attributes.country_name,
                        company: "",
                        PlantName: "",
                        city: "",
                        sector: "",
                        product: "",
                        production_type: "",
                        source: "",
                        vector_value_NetEmissions: "",
                        PlantCount: plantVectorcount,
                        [VectorName]: item.attributes.Total_sum_vector,
                        //item.attributes.Total_sum_vector.toFixed(1),
                        TotalEnergy: item.attributes.Total_sum_Energy,
                        subRows: [],
                        capacity: capacityPlant,
                        hasChild: false,
                      });
                    }
                  });
                  for (let i = 0; i < tableDataNested.length; i++) {
                    for (let item = 0; item < KeysArr.length; item++) {
                      if (KeysArr[item] in tableDataNested[i]) {
                        if (
                          KeysArr[item] === "Vector_Coal" ||
                          KeysArr[item] === "Vector_Heat" ||
                          KeysArr[item] === "Vector_Waste"
                        ) {
                          tableDataNested[i][KeysArr[item]] = 0.0;
                        }
                      } else {
                        tableDataNested[i][KeysArr[item]] = 0.0;
                      }
                    }
                  }

                  dataFiltered = tableDataNested;
                  let countPlantNo = 0;
                  let stringOwner = "";
                  dataFiltered.forEach((element: any) => {
                    if (element.PlantCount + countPlantNo < 1000) {
                      stringOwner =
                        stringOwner +
                        "'" +
                        element.country.replace(/'/g, "''") +
                        "',";
                      countPlantNo = countPlantNo + element.PlantCount;
                    } else {
                      countryObjArr.push(
                        stringOwner.substring(0, stringOwner.length - 1)
                      );
                      countPlantNo = 0;
                      stringOwner = "";
                      if (element.PlantCount > 1000) {
                        countryObjArr.push(
                          "'" + element.country.replace(/'/g, "''") + "'"
                        );
                      } else {
                        stringOwner =
                          stringOwner +
                          "'" +
                          element.country.replace(/'/g, "''") +
                          "',";
                        countPlantNo = countPlantNo + element.PlantCount;
                      }
                    }
                    if (
                      !countryObjArr.includes(stringOwner) &&
                      dataFiltered.indexOf(element) === dataFiltered.length - 1
                    ) {
                      countryObjArr.push(
                        stringOwner.substring(0, stringOwner.length - 1)
                      );
                    }
                  });
                  countCountryArr = countryObjArr.length;

                  countryObjArr.forEach((ele: any) => {
                    const countriesString = ele;

                    const TableLayer = new FeatureLayer({
                      url: props.mit_scenario_table_spatial,
                    });
                    let whereClause = "1 = 1";
                    if (appState.outputYearVal !== "") {
                      whereClause = "year = " + appState.outputYearVal;
                    }
                    if (appState.energyOutlookVal !== "") {
                      whereClause =
                        whereClause +
                        " AND pathway = '" +
                        appState.energyOutlookVal +
                        "'";
                    }
                    if (appState.regionVal !== "") {
                      whereClause =
                        whereClause +
                        "AND country_name IN(" +
                        appState.regionVal +
                        ")";
                    }
                    if (appState.companyVal !== "" && whereClause === "1 = 1") {
                      whereClause =
                        "plant_front_name IN(" + appState.companyVal + ")";
                    }
                    if (appState.companyVal !== "" && whereClause !== "1 = 1") {
                      whereClause =
                        whereClause +
                        " AND plant_front_name IN(" +
                        appState.companyVal +
                        ")";
                    }
                    if (appState.sectorVal !== "" && whereClause === "1 = 1") {
                      whereClause = "product IN(" + appState.sectorVal + ")";
                    }
                    if (appState.sectorVal !== "" && whereClause !== "1 = 1") {
                      whereClause =
                        whereClause +
                        " AND product IN(" +
                        appState.sectorVal +
                        ")";
                    }
                    if (appState.vectorVal !== "" && whereClause === "1 = 1") {
                      whereClause = "vector IN(" + appState.vectorVal + ")";
                    }
                    if (appState.vectorVal !== "" && whereClause !== "1 = 1") {
                      whereClause =
                        whereClause +
                        " AND vector IN(" +
                        appState.vectorVal +
                        ")";
                    }
                    if (countriesString !== "") {
                      whereClause =
                        whereClause +
                        " AND country_name IN(" +
                        countriesString +
                        ")";
                    }
                    const querytopfive = TableLayer.createQuery();
                    querytopfive.where = whereClause;
                    querytopfive.outFields = ["*"];
                    // querytopfive.returnDistinctValues = true;
                    querytopfive.returnGeometry = false;
                    querytopfive.orderByFields = ["country_name ASC"];
                    querytopfive.groupByFieldsForStatistics = [
                      "country_name,parent_company,vector",
                    ];
                    const validationParams: any = [
                      {
                        statisticType: "sum",
                        onStatisticField: "capacity",
                        outStatisticFieldName: "Total_sum_capacity",
                      },
                      {
                        statisticType: "sum",
                        onStatisticField: "total_value_dollars",
                        outStatisticFieldName: "Total_sum_vector",
                      },
                      {
                        statisticType: "sum",
                        onStatisticField: "total_value_dollars",
                        outStatisticFieldName: "Total_sum_Energy",
                      },
                      {
                        statisticType: "count",
                        onStatisticField: "plant_front_name",
                        outStatisticFieldName: "Total_PlantCount",
                      },
                    ];
                    querytopfive.outStatistics = validationParams;
                    TableLayer.queryFeatures(querytopfive).then(function (
                      response
                    ) {
                      if (response.features) {
                        countQueryOwner++;
                        const tableDataNested: any = [];
                        response.features.forEach((item: any) => {
                          let index = -1;
                          const obj = tableDataNested.find((o: any, i: any) => {
                            if (
                              o.company === item.attributes.parent_company &&
                              o.country === item.attributes.country_name
                            ) {
                              index = i;
                              return true;
                            }
                          });
                          if (
                            obj !== null &&
                            obj !== undefined &&
                            obj !== false
                          ) {
                            if (
                              (tableDataNested[index]["company"] =
                                item.attributes.parent_company)
                            ) {
                              tableDataNested[index]["PlantCount"] =
                                item.attributes.Total_PlantCount;
                              tableDataNested[index]["TotalEnergy"] =
                                tableDataNested[index]["TotalEnergy"] +
                                item.attributes.Total_sum_Energy;

                              tableDataNested[index][
                                "Vector_" +
                                  item.attributes.vector.replace(" ", "_")
                              ] = item.attributes.Total_sum_vector; // item.attributes.Total_sum_vector.toFixed(1);
                            }
                          } else {
                            const VectorName =
                              "Vector_" +
                              item.attributes.vector.replace(" ", "_");
                            tableDataNested.push({
                              country: item.attributes.country_name,
                              PlantCount: item.attributes.Total_PlantCount,
                              city: "",
                              sector: "",
                              product: "",
                              production_type: "",
                              source: "",
                              vector_value_NetEmissions: "",
                              [VectorName]: item.attributes.Total_sum_vector,
                              // item.attributes.Total_sum_vector.toFixed(1),
                              TotalEnergy: item.attributes.Total_sum_Energy,
                              company: item.attributes.parent_company,
                              capacity: item.attributes.Total_sum_capacity,
                              subRows: [],
                              hasChild: true,
                            });
                          }
                        });
                        for (let i = 0; i < tableDataNested.length; i++) {
                          for (let item = 0; item < KeysArr.length; item++) {
                            if (KeysArr[item] in tableDataNested[i]) {
                              if (
                                KeysArr[item] === "Vector_Coal" ||
                                KeysArr[item] === "Vector_Heat" ||
                                KeysArr[item] === "Vector_Waste"
                              ) {
                                tableDataNested[i][KeysArr[item]] = 0.0;
                              }
                            } else {
                              tableDataNested[i][KeysArr[item]] = 0.0;
                            }
                          }
                        }

                        const previousData: any = dataFiltered;
                        tableDataNested.forEach((element: any) => {
                          let indexChild = -1;
                          const obj = previousData.find((o: any, i: any) => {
                            if (o.country === element.country) {
                              indexChild = i;
                              return true;
                            }
                          });
                          if (indexChild !== -1) {
                            previousData[indexChild].hasChild = true;
                            previousData[indexChild].subRows.push(element);
                          }
                        });
                        if (countCountryArr === countQueryOwner) {
                          countCountryArr = countCountryArr + countCountryArr;
                          countryObjArr.forEach((ele: any) => {
                            const countriesString = ele;

                            const TableLayer = new FeatureLayer({
                              url: props.mit_scenario_table_spatial,
                            });
                            let whereClause = "1 = 1";
                            if (appState.outputYearVal !== "") {
                              whereClause = "year = " + appState.outputYearVal;
                            }
                            if (appState.energyOutlookVal !== "") {
                              whereClause =
                                whereClause +
                                " AND pathway = '" +
                                appState.energyOutlookVal +
                                "'";
                            }
                            if (appState.regionVal !== "") {
                              whereClause =
                                whereClause +
                                "AND country_name IN(" +
                                appState.regionVal +
                                ")";
                            }
                            if (
                              appState.companyVal !== "" &&
                              whereClause === "1 = 1"
                            ) {
                              whereClause =
                                "plant_front_name IN(" +
                                appState.companyVal +
                                ")";
                            }
                            if (
                              appState.companyVal !== "" &&
                              whereClause !== "1 = 1"
                            ) {
                              whereClause =
                                whereClause +
                                " AND plant_front_name IN(" +
                                appState.companyVal +
                                ")";
                            }
                            if (
                              appState.sectorVal !== "" &&
                              whereClause === "1 = 1"
                            ) {
                              whereClause =
                                "product IN(" + appState.sectorVal + ")";
                            }
                            if (
                              appState.sectorVal !== "" &&
                              whereClause !== "1 = 1"
                            ) {
                              whereClause =
                                whereClause +
                                " AND product IN(" +
                                appState.sectorVal +
                                ")";
                            }
                            if (
                              appState.vectorVal !== "" &&
                              whereClause === "1 = 1"
                            ) {
                              whereClause =
                                "vector IN(" + appState.vectorVal + ")";
                            }
                            if (
                              appState.vectorVal !== "" &&
                              whereClause !== "1 = 1"
                            ) {
                              whereClause =
                                whereClause +
                                " AND vector IN(" +
                                appState.vectorVal +
                                ")";
                            }
                            if (countriesString !== "") {
                              whereClause =
                                whereClause +
                                " AND country_name IN(" +
                                countriesString +
                                ")";
                            }
                            const querytopfive = TableLayer.createQuery();
                            querytopfive.where = whereClause;
                            querytopfive.outFields = ["*"];
                            // querytopfive.returnDistinctValues = true;
                            querytopfive.returnGeometry = false;
                            querytopfive.orderByFields = ["country_name ASC"];
                            querytopfive.groupByFieldsForStatistics = [
                              "country_name,parent_company,plant_front_name,vector",
                            ];
                            const validationParams: any = [
                              {
                                statisticType: "sum",
                                onStatisticField: "capacity",
                                outStatisticFieldName: "Total_sum_capacity",
                              },
                              {
                                statisticType: "sum",
                                onStatisticField: "total_value_dollars",
                                outStatisticFieldName: "Total_sum_vector",
                              },
                              {
                                statisticType: "sum",
                                onStatisticField: "total_value_dollars",
                                outStatisticFieldName: "Total_sum_Energy",
                              },
                              {
                                statisticType: "count",
                                onStatisticField: "plant_front_name",
                                outStatisticFieldName: "Total_PlantCount",
                              },
                            ];
                            querytopfive.outStatistics = validationParams;
                            TableLayer.queryFeatures(querytopfive).then(
                              function (response) {
                                if (response.features) {
                                  countQueryOwner++;
                                  const tableDataNested: any = [];
                                  response.features.forEach((item: any) => {
                                    let index = -1;
                                    const obj = tableDataNested.find(
                                      (o: any, i: any) => {
                                        if (
                                          o.PlantName ===
                                            item.attributes.plant_front_name &&
                                          o.company ===
                                            item.attributes.parent_company &&
                                          o.country ===
                                            item.attributes.country_name
                                        ) {
                                          index = i;
                                          return true;
                                        }
                                      }
                                    );
                                    if (
                                      obj !== null &&
                                      obj !== undefined &&
                                      obj !== false
                                    ) {
                                      if (
                                        (tableDataNested[index]["company"] =
                                          item.attributes.parent_company)
                                      ) {
                                        tableDataNested[index]["PlantCount"] =
                                          item.attributes.Total_PlantCount;
                                        tableDataNested[index]["TotalEnergy"] =
                                          tableDataNested[index][
                                            "TotalEnergy"
                                          ] + item.attributes.Total_sum_Energy;

                                        tableDataNested[index][
                                          "Vector_" +
                                            item.attributes.vector.replace(
                                              " ",
                                              "_"
                                            )
                                        ] = item.attributes.Total_sum_vector;
                                        // item.attributes.Total_sum_vector.toFixed(0);
                                      }
                                    } else {
                                      const VectorName =
                                        "Vector_" +
                                        item.attributes.vector.replace(
                                          " ",
                                          "_"
                                        );
                                      tableDataNested.push({
                                        country: item.attributes.country_name,
                                        PlantCount:
                                          item.attributes.Total_PlantCount,
                                        city: "",
                                        sector: "",
                                        product: "",
                                        production_type: "",
                                        source: "",
                                        vector_value_NetEmissions: "",
                                        [VectorName]:
                                          item.attributes.Total_sum_vector,
                                        // item.attributes.Total_sum_vector.toFixed(0),
                                        TotalEnergy:
                                          item.attributes.Total_sum_Energy,
                                        company: item.attributes.parent_company,
                                        PlantName:
                                          item.attributes.plant_front_name,
                                        capacity:
                                          item.attributes.Total_sum_capacity,
                                        subRows: [],
                                        hasChild: true,
                                      });
                                    }
                                  });
                                  for (
                                    let i = 0;
                                    i < tableDataNested.length;
                                    i++
                                  ) {
                                    for (
                                      let item = 0;
                                      item < KeysArr.length;
                                      item++
                                    ) {
                                      if (KeysArr[item] in tableDataNested[i]) {
                                        if (
                                          KeysArr[item] === "Vector_Coal" ||
                                          KeysArr[item] === "Vector_Heat" ||
                                          KeysArr[item] === "Vector_Waste"
                                        ) {
                                          tableDataNested[i][
                                            KeysArr[item]
                                          ] = 0.0;
                                        }
                                      } else {
                                        tableDataNested[i][KeysArr[item]] = 0.0;
                                      }
                                    }
                                  }

                                  const previousData: any = dataFiltered;
                                  tableDataNested.forEach((element: any) => {
                                    let indexp = -1;
                                    const objPlantDetail = plantDetails.find(
                                      (o: any, i: any) => {
                                        if (
                                          o.plant_front_name ===
                                          element.PlantName
                                        ) {
                                          indexp = i;
                                          return true;
                                        }
                                      }
                                    );
                                    if (indexp !== -1) {
                                      element["city"] =
                                        plantDetails[indexp].city;
                                      element["sector"] =
                                        plantDetails[indexp].sector;
                                      element["production_type"] =
                                        plantDetails[indexp].production_type;
                                      element["product"] =
                                        plantDetails[indexp].product;
                                      element["source"] =
                                        plantDetails[indexp].source;
                                    }
                                    let indexChild = -1;
                                    const obj = previousData.find(
                                      (o: any, i: any) => {
                                        if (o.country === element.country) {
                                          indexChild = i;
                                          return true;
                                        }
                                      }
                                    );
                                    if (indexChild !== -1) {
                                      if (
                                        previousData[indexChild].subRows
                                          .length > 0
                                      ) {
                                        previousData[
                                          indexChild
                                        ].subRows.forEach((iter: any) => {
                                          if (
                                            iter.company === element.company &&
                                            iter.subRows.includes(element) ===
                                              false
                                          ) {
                                            iter.subRows.push(element);
                                          }
                                        });
                                      }
                                    }
                                  });
                                  if (countCountryArr === countQueryOwner) {
                                    setDataTable([]);
                                    setresetTool(true);
                                    const dataexport = JSON.parse(JSON.stringify(previousData));
                                    setExportData(dataexport);
                                    previousData.forEach((element: any) => {
                                      const objectKeys = Object.keys(element);
                                      objectKeys.forEach((obj: any) => {
                                        if (obj !== "subRows") {
                                          if (parseFloat(element[obj])) {
                                            element[obj] = parseFloat(
                                              parseFloat(element[obj]).toFixed(
                                                1
                                              )
                                            );
                                          }
                                        } else {
                                          element[obj].forEach(
                                            (elementChild: any) => {
                                              const objectKeysChild =
                                                Object.keys(elementChild);
                                              objectKeysChild.forEach(
                                                (objChild: any) => {
                                                  if (objChild !== "subRows") {
                                                    if (
                                                      typeof elementChild[
                                                        objChild
                                                      ] !== "string" &&
                                                      parseFloat(
                                                        elementChild[objChild]
                                                      )
                                                    ) {
                                                      elementChild[objChild] =
                                                        parseFloat(
                                                          parseFloat(
                                                            elementChild[
                                                              objChild
                                                            ]
                                                          ).toFixed(1)
                                                        );
                                                    }
                                                  } else {
                                                    elementChild[
                                                      objChild
                                                    ].forEach(
                                                      (
                                                        elementChildLast: any
                                                      ) => {
                                                        const objectKeysChildLast =
                                                          Object.keys(
                                                            elementChildLast
                                                          );
                                                        objectKeysChildLast.forEach(
                                                          (
                                                            objChildLast: any
                                                          ) => {
                                                            if (
                                                              objChildLast !==
                                                              "subRows"
                                                            ) {
                                                              if (
                                                                typeof elementChildLast[
                                                                  objChildLast
                                                                ] !==
                                                                  "string" &&
                                                                parseFloat(
                                                                  elementChildLast[
                                                                    objChildLast
                                                                  ]
                                                                )
                                                              ) {
                                                                elementChildLast[
                                                                  objChildLast
                                                                ] = parseFloat(
                                                                  parseFloat(
                                                                    elementChildLast[
                                                                      objChildLast
                                                                    ]
                                                                  ).toFixed(1)
                                                                );
                                                              }
                                                            }
                                                          }
                                                        );
                                                      }
                                                    );
                                                  }
                                                }
                                              );
                                            }
                                          );
                                        }
                                      });
                                    });

                                    for (
                                      let i = 0;
                                      i < tableDataNested.length;
                                      i++
                                    ) {
                                      for (
                                        let item = 0;
                                        item < KeysArr.length;
                                        item++
                                      ) {
                                        if (
                                          KeysArr[item] in tableDataNested[i]
                                        ) {
                                          if (
                                            KeysArr[item] === "Vector_Coal" ||
                                            KeysArr[item] === "Vector_Heat" ||
                                            KeysArr[item] === "Vector_Waste"
                                          ) {
                                            tableDataNested[i][
                                              KeysArr[item]
                                            ] = 0;
                                          }
                                        } else {
                                          tableDataNested[i][
                                            KeysArr[item]
                                          ] = 0.0;
                                        }
                                      }
                                    }

                                    setDataTable(previousData);
                                    countCountryArr = countQueryOwner = 0;
                                    setloadingTable(false);
                                  }
                                }
                              }
                            );
                          });
                        }
                      }
                    });
                  });
                }
              });
            }
          }
        });
    }
  }, [
    appState.outputYearVal,
    appState.energyOutlookVal,
    appState.regionVal,
    appState.companyVal,
    appState.sectorVal,
    appState.vectorVal,
    appState.isAuthenticated,
    activeLink,
  ]);

  return (
    <div className="-tablesTab">
      <div className="tablesControls">
        <Navbar expand className="navbar-border" data-testid="table-navbar">
          <div className="d-flex align-items-center">
            <Nav navbar className={`d-none d-${breakpoint}-flex flex-row`}>
              <NavItem>
                <NavLink
                  tag={Link}
                  className={activeLink === "Volume" ? "active" : ""}
                  onClick={() => setActiveNavLink("Volume")}
                >
                  <span>Volume</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  tag={Link}
                  className={activeLink === "Value" ? "active" : ""}
                  onClick={() => setActiveNavLink("Value")}
                >
                  <span>Value</span>
                </NavLink>
              </NavItem>
            </Nav>
          </div>
        </Navbar>
      </div>
      <div className="tablesDiv">
        {dataTable && (
          <MantineReactTable
            columns={activeLink === "Volume" ? columnsfield : columnsfieldValue}
            data={dataTable}
            enableExpanding={true}
            enableExpandAll={false} //default //only expand the root rows by default
            enableDensityToggle={false}
            enableStickyHeader={true}
            initialState={{
              density: "xs",
              showGlobalFilter: false,
              columnVisibility: {
                hasChild: false,
                autoResetAll: true,
              },
              columnPinning: {
                left: ["expand", "country", "company", "city", "PlantName"],
              },
            }}
            state={{ isLoading: loadingTable }}
            autoResetAll={resetTool}
            renderTopToolbarCustomActions={({ table }) => (
              <Box
                sx={{
                  display: "flex",
                  gap: "16px",
                  padding: "8px",
                  flexWrap: "wrap",
                }}
              >
                <Button
                  //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                  onClick={handleExportData}
                  leftIcon={<IconDownload />}
                  variant="filled"
                  className="exportBtnContainerTable"
                  disabled={exportData.length > 0 ? false : true}
                  data-testid="export-data-btn"
                >
                  Export All Data
                </Button>
              </Box>
            )}
          />
        )}
      </div>
    </div>
  );
};

export default TablesTab;
