import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import "@bphxd/ds-core-react/lib/scss/bp-core.scss";
import "react-tabulator/css/bootstrap/tabulator_bootstrap.min.css";
import OAuthInfo from "@arcgis/core/identity/OAuthInfo";
import esriId from "@arcgis/core/identity/IdentityManager";
import { envConstants } from "./Constants/envConstants";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const oAuth = new OAuthInfo({
  appId: envConstants.appID,
  portalUrl: envConstants.portalURL,
  flowType: "auto",
  popup: false,
});

esriId.registerOAuthInfos([oAuth]);

esriId
  .checkSignInStatus(envConstants.portalURL + "/sharing/rest")
  .then(() => {
    root.render(
      <BrowserRouter>
        <App {...envConstants} />
      </BrowserRouter>
    );
  })
  .catch(() => {
    esriId.getCredential(envConstants.portalURL);
  });

// root.render(
//   // <React.StrictMode>
//   <BrowserRouter>
//     <App {...envConstants} />
//   </BrowserRouter>
//   /* </React.StrictMode> */
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
