export const initialState = {
  isAuthenticated: false,
  isLoggedOut: false,
  outputYearVal: "",
  energyOutlookVal: "",
  exploreByVal: "",
  sectorVal: "",
  companyVal: "",
  regionVal: "",
  vectorVal: "",
  basemapValue: "Light Gray Canvas",
  basemapId: "18e420d6c86-basemap-14",
  homeQuery: "",
  ConfidentialPopup: false,
  totalPlantCount: "0",
  totalEnergyConsumption: "0",
  decarbRegion: "",
  decarbSector: "",
  decarbPlantName: "",
  decarbCompany: "",
  decarbQuery: "",
  polygonFilter:false,
  totalemissions:"0",
  activeTabs:"Tab1"
};
