import "./MapTab.css";
import { useRef, useEffect, useState } from "react";
import { useGlobalState } from "../GlobalContext/GlobalContext";
//ESRI oAuth
import config from "@arcgis/core/config";
import OAuthInfo from "@arcgis/core/identity/OAuthInfo";
import esriId from "@arcgis/core/identity/IdentityManager";

//ESRI ArcGIS
import MapView from "@arcgis/core/views/MapView";
import WebMap from "@arcgis/core/WebMap";
import Search from "@arcgis/core/widgets/Search";
import BasemapGallery from "@arcgis/core/widgets/BasemapGallery";
import LayerList from "@arcgis/core/widgets/LayerList";
import Legend from "@arcgis/core/widgets/Legend.js";
import Measurement from "@arcgis/core/widgets/Measurement.js";
import Expand from "@arcgis/core/widgets/Expand";

import Sketch from "@arcgis/core/widgets/Sketch";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import Field from "@arcgis/core/layers/support/Field";
import { envComponentProps } from "../../Constants/envConstants.types";

import {
  Nav,
  NavItem,
  NavLink,
  Navbar,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
} from "reactstrap";
import { Button, Close, Spinner } from "@bphxd/ds-core-react";
import { Link } from "react-router-dom";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import * as reactiveUtils from "@arcgis/core/core/reactiveUtils";

const MapTab = (props: envComponentProps) => {
  const breakpoint = "lg";
  const { appState, setappState } = useGlobalState();
  const mapContainer = useRef<any>();
  const basemapContainer = useRef<any>();

  const [mapView, setMapView] = useState<MapView | null>(null);
  const [searchWidget, setsearchWidget] = useState<Search | null>(null);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

  const [open, setOpen] = useState(false);
  const [OpenMapLayers, setOpenMapLayers] = useState(false);
  const [searchToolLoaded, setsearchToolLoaded] = useState(false);
  const [baseMapLayer, setbaseMapLayer] = useState(["Light Gray Canvas", ""]);
  const [renderarSizeEnergy, setrendererSizeEnergy] = useState<any>();
  const [renderarSizeValue, setrendererSizeValue] = useState<any>();
  const [popupTemplateEnergy, setpopupTemplateEnergy] = useState<any>();
  const [popupTemplateValue, setpopupTemplateValue] = useState<any>();
  const resultTotalEnergyOptions: { label: any; value: any }[] = [];
  const resultTotalValueOptions: { label: any; value: any }[] = [];

  const [activeLink, setActiveLink] = useState("Energy");

  const [spinner, setSpinner] = useState(false);

  const toggleMapTools = () => setOpen(!open);

  const toggleMapLayers = () => setOpenMapLayers(!OpenMapLayers);

  //Use Effect to register OAuth
  useEffect(() => {
    config.portalUrl = props.portalURL;

    // const oAuth = new OAuthInfo({
    //   appId: props.appID,
    //   portalUrl: props.portalURL,
    //   flowType: "auto",
    //   popup: false,
    // });

    // esriId.registerOAuthInfos([oAuth]);

    const credListener = esriId.on("credential-create", (e) => {
      if (e.credential.server === props.portalURL && e.credential.token) {
        setIsAuthenticated(true);
        setappState((prevState) => ({
          ...prevState,
          isAuthenticated: true,
          isLoggedOut: false,
        }));
      }
    });

    esriId.checkSignInStatus(props.portalURL).then((e: any) => {
      if (e.server === props.portalURL && e.token) {
        setIsAuthenticated(true);
        setappState((prevState) => ({
          ...prevState,
          isAuthenticated: true,
          isLoggedOut: false,
        }));
      }
    });

    return () => {
      credListener.remove();
    };
  }, [props.portalURL, props.appID]);

  // use Effect to logOUT
  useEffect(() => {
    if (appState.isAuthenticated === true && appState.isLoggedOut === true) {
      esriId.destroyCredentials();
      setappState((prevState) => ({
        ...prevState,
        isAuthenticated: false,
        isLoggedOut: false,
      }));
      window.location.href = props.redirectURI;
    }
  }, [appState.isAuthenticated, appState.isLoggedOut]);

  //Use Effect to Load Map
  useEffect(() => {
    const webMap = new WebMap({
      portalItem: {
        id: props.webMapID,
      },
    });

    const mapView = new MapView({
      container: mapContainer?.current,
      map: webMap,
      popup: {
        defaultPopupTemplateEnabled: true,
        dockEnabled: true,
        dockOptions: { position: "top-right", breakpoint: false },
      },
    });

    if (appState.isAuthenticated) {
      mapView.when().then(() => {
        setMapView(mapView);
        mapView.zoom = 2;

        if (!searchToolLoaded) {
          mapView?.when().then(() => {
            setsearchToolLoaded(true);
          });
        }
        // LayerList
        const layerList = new LayerList({
          label: "Layers",
          container: document.createElement("div"),
          view: mapView,
          listItemCreatedFunction: (event) => {
            const item = event.item;
            if (item.layer.type != "group") {
              // don't show legend twice
              item.panel = {
                content: "legend",
                open: false,
              };
            }
          },
        });
        const layerListExpand = new Expand({
          expandTooltip: "Layers",
          expandIcon: "layers",
          view: mapView,
          content: layerList,
          group: "top-left",
        });
        // LayerList
        const basemapGalleryList = new BasemapGallery({
          label: "Layers",
          container: document.createElement("div"),
          view: mapView,
        });
        const basemapExpand = new Expand({
          expandTooltip: "Basemap Gallery",
          expandIcon: "basemap",
          view: mapView,
          content: basemapGalleryList,
          group: "top-left",
        });
        const graphicsLayerSketch = new GraphicsLayer({
          title: "Polygon Draw Layer",
          listMode: "hide",
        });
        mapView.map.add(graphicsLayerSketch);
        const sketch = new Sketch({
          layer: graphicsLayerSketch,
          label: "Layers",
          container: document.createElement("div"),
          view: mapView,
          creationMode: "update",
          availableCreateTools: ["polygon"],
          visibleElements: {
            selectionTools: {
              "lasso-selection": false,
            },
            settingsMenu: false,
          },
        });

        // Listen to sketch widget's create event.
        sketch.on("create", function (event) {
          // check if the create event's state has changed to complete indicating
          // the graphic create operation is completed.
          if (event.state === "complete") {
            // remove the graphic from the layer. Sketch adds
            // the completed graphic to the layer by default.
            setSpinner(true);
            graphicsLayerSketch.remove(event.graphic);
            const flFilter = new FeatureLayer({
              url: props.mit_fc_view_table,
            });
            const query = flFilter.createQuery();
            query.geometry = event.graphic.geometry;
            query.spatialRelationship = "intersects";
            query.outFields = ["*"];
            query.returnGeometry = false;

            flFilter.queryFeatures(query).then((response: any) => {
              if (response.features) {
                const RResults = response.features.map(function (feature: any) {
                  return feature;
                });
                if (appState.regionVal != "") {
                  mapView.goTo(RResults).finally(() => {
                    setSpinner(false);
                  });
                } else {
                  setSpinner(false);
                }
                const arraySpatialFilter: any = [];
                response.features.forEach((element: any) => {
                  if (
                    arraySpatialFilter.indexOf(
                      element.attributes.country_name
                    ) === -1
                  ) {
                    arraySpatialFilter.push(
                      element.attributes.plant_front_name
                    );
                  }
                });
                if (arraySpatialFilter.length > 0) {
                  let eventValues = "";
                  for (let i = 0; i < arraySpatialFilter.length; i++) {
                    if (eventValues === "") {
                      eventValues = "'" + arraySpatialFilter[i] + "'";
                    } else {
                      eventValues =
                        eventValues + ",'" + arraySpatialFilter[i] + "'";
                    }
                  }
                  setappState((prevState) => ({
                    ...prevState,
                    companyVal: eventValues,
                    polygonFilter: true,
                  }));
                }
              }
            });
            // use the graphic.geometry to query features that intersect it
            // selectFeatures(event.graphic.geometry);
          }
        });
        const sketchExpand = new Expand({
          expandTooltip: "Polygon Draw",
          expandIcon: "polygon-vertices",
          view: mapView,
          content: sketch,
          group: "top-left",
        });
        // LayerList
        const legendList = new Legend({
          label: "Legend",
          container: document.createElement("div"),
          view: mapView,
        });
        const legendExpand = new Expand({
          expandTooltip: "Legend",
          expandIcon: "legend",
          view: mapView,
          content: legendList,
          // group: "top-left",
          expanded: true,
        });
        // LayerList
        // const measurementTool = new Measurement({
        //   view: mapView,
        // });
        // const measurementExpand = new Expand({
        //   view: mapView,
        //   content: measurementTool,
        //   expandTooltip: "Measurement Tool",
        //   expandIcon: "measure",
        // });
        mapView.ui.add(
          [
            layerListExpand,
            basemapExpand,
            sketchExpand,
            // legendExpand,
            //  measurementExpand,
          ],
          "top-left"
        );
        mapView.ui.add(legendExpand, "bottom-right");
      });
    }

    return () => {
      if (appState.isAuthenticated) {
        mapView?.destroy();
        setMapView(null);
        searchWidget?.destroy();
      }
    };
  }, [props.webMapID, isAuthenticated]);
  useEffect(() => {
    if (OpenMapLayers) {
      mapView?.when().then(() => {
        const basemapGallery = new BasemapGallery({
          view: mapView,
          container: basemapContainer.current,
        });
        const handle = basemapGallery.watch(
          "activeBasemap",
          (newValue, oldValue, property, object) => {
            setbaseMapLayer([newValue?.title, newValue?.id]);
            if (newValue !== null && newValue !== undefined) {
              setMapView(mapView);
              setappState((prevState) => ({
                ...prevState,
                basemapValue: newValue?.title,
                basemapId: newValue?.id,
              }));
            }
          }
        );
      });
    }
  }, [OpenMapLayers, mapView]);

  const setActiveNavLink = (selectedKey: any) => {
    if (activeLink === "Emissions" && selectedKey !== "emissions") {
      setappState((prevState) => ({
        ...prevState,
        vectorVal: "",
      }));
    }
    setActiveLink(selectedKey);
    setappState((prevState) => ({
      ...prevState,
      activeLinkMap: selectedKey,
    }));
  };

  useEffect(() => {
    if (appState.isAuthenticated) {
      mapView?.when(() => {
        setSpinner(true);
        let whereclause = "";
        if (appState.regionVal !== "") {
          whereclause = "country_name IN(" + appState.regionVal + ")";
        }
        if (appState.companyVal !== "" && whereclause === "") {
          whereclause = "plant_front_name IN(" + appState.companyVal + ")";
        }
        if (whereclause !== "" && appState.companyVal !== "") {
          whereclause =
            whereclause +
            " AND plant_front_name IN(" +
            appState.companyVal +
            ")";
        }
        if (appState.sectorVal !== "" && whereclause === "") {
          whereclause = "product IN(" + appState.sectorVal + ")";
        }
        if (whereclause !== "" && appState.sectorVal !== "") {
          whereclause =
            whereclause + " AND product IN(" + appState.sectorVal + ")";
        }
        if (whereclause !== "" && appState.outputYearVal !== "") {
          whereclause = whereclause + " AND year = " + appState.outputYearVal;
        }
        if (appState.outputYearVal !== "" && whereclause === "") {
          whereclause = "year = " + appState.outputYearVal;
        }
        if (whereclause !== "" && appState.energyOutlookVal !== "") {
          whereclause =
            whereclause + " AND pathway = '" + appState.energyOutlookVal + "'";
        }
        if (appState.energyOutlookVal !== "" && whereclause === "") {
          whereclause = "pathway = '" + appState.energyOutlookVal + "'";
        }

        let whereClauseOthers = whereclause;

        if (
          whereClauseOthers === "" &&
          appState.energyOutlookVal !== "" &&
          appState.outputYearVal !== ""
        ) {
          whereClauseOthers =
            "year = " +
            appState.outputYearVal +
            " AND pathway = IN ('" +
            appState.energyOutlookVal +
            "')";
        }

        if (whereClauseOthers === "" && appState.vectorVal !== "") {
          whereClauseOthers = "vector IN (" + appState.vectorVal + ")";
        }
        if (whereClauseOthers !== "" && appState.vectorVal !== "") {
          whereClauseOthers =
            whereClauseOthers + " AND vector IN (" + appState.vectorVal + ")";
        }

        mapView?.map?.layers?.forEach((layer: any) => {
          if (whereclause !== "") {
            if (layer.title === "MIT - Emissions Default") {
              if (layer.allSublayers.items.length > 0) {
                layer.allSublayers.items[0].definitionExpression = "";
              }
              if (
                layer?.fullExtent !== null &&
                layer?.fullExtent !== undefined
              ) {
                const whereClauses = whereclause.split(" AND ");
                const vectorLessArray = whereClauses.filter(
                  (item: any) => !item.includes("vector")
                );
                const vectorLessWhereClause = vectorLessArray.join(" AND ");
                layer.allSublayers.items[0].definitionExpression =
                  vectorLessWhereClause + " AND vector = 'Net Emissions'";
                if (
                  activeLink === "Emissions" &&
                  appState.vectorVal === "'Net Emissions'"
                ) {
                  layer.visible = true;
                } else {
                  layer.visible = false;
                }
                layer.refresh();
                // mapView?.goTo(layer?.fullExtent);
              }
            }
            if (layer.title === "MIT - Emissions") {
              if (layer.allSublayers.items.length > 0) {
                layer.allSublayers.items[0].definitionExpression = "";
              }
              if (
                layer?.fullExtent !== null &&
                layer?.fullExtent !== undefined
              ) {
                const whereClauses = whereclause.split(" AND ");
                const vectorLessArray = whereClauses.filter(
                  (item: any) => !item.includes("vector")
                );
                const vectorLessWhereClause = vectorLessArray.join(" AND ");
                layer.allSublayers.items[0].definitionExpression =
                  vectorLessWhereClause + " AND vector = 'Captured Emissions'";
                if (
                  activeLink === "Emissions" &&
                  appState.vectorVal === "'Captured Emissions'"
                ) {
                  layer.visible = true;
                } else {
                  layer.visible = false;
                }
                layer.refresh();
                // mapView?.goTo(layer?.fullExtent);
              }
            }
            if (layer.title === "MIT - Capacity") {
              if (activeLink === "Capacity") {
                layer.visible = true;
              }
              if (layer.allSublayers.items.length > 0) {
                layer.allSublayers.items[0].definitionExpression = whereclause;
              }
            }
          } else {
            if (layer.title === "MIT - Capacity") {
              if (layer.allSublayers.items.length > 0) {
                layer.allSublayers.items[0].definitionExpression = "";
              }
              if (
                layer?.fullExtent !== null &&
                layer?.fullExtent !== undefined
              ) {
                // mapView?.goTo(layer?.fullExtent);
              }
            }
          }
        });
        if (whereClauseOthers !== "") {
          mapView?.map?.layers?.forEach((layer: any) => {
            if (layer.title === "MIT - Energy") {
              if (
                renderarSizeEnergy === undefined ||
                renderarSizeEnergy === null
              ) {
                setrendererSizeEnergy(
                  layer?.allSublayers?.items[0]?.renderer.clone()
                );
                setpopupTemplateEnergy(
                  layer?.allSublayers?.items[0]?.popupTemplate
                );
              }
              layer.load();
            }
            if (layer.title === "MIT - Value") {
              if (
                renderarSizeValue === undefined ||
                renderarSizeValue === null
              ) {
                setrendererSizeValue(
                  layer?.allSublayers?.items[0]?.renderer.clone()
                );
                setpopupTemplateValue(
                  layer?.allSublayers?.items[0]?.popupTemplate
                );
              }
              layer.load();
            }
          });
        }

        const fl = new FeatureLayer({
          url: props.mit_fc_view_table,
        });
        const query = fl.createQuery();
        query.where = whereclause;
        query.outFields = ["*"];
        query.returnDistinctValues = false;
        query.returnGeometry = true;
        fl.queryFeatures(query)
          .then((response) => {
            if (response.features) {
              const RResults = response.features.map(function (feature) {
                return feature;
              });
              if (appState.regionVal != "") {
                mapView.goTo(RResults).finally(() => {
                  setSpinner(false);
                });
              } else {
                setSpinner(false);
              }
            }
          })
          .catch(() => {
            setSpinner(false);
          });
      });
    }
  }, [
    appState.isAuthenticated,
    appState.regionVal,
    appState.companyVal,
    appState.sectorVal,
    appState.outputYearVal,
    appState.energyOutlookVal,
    appState.vectorVal,
  ]);

  useEffect(() => {
    if (activeLink === "Energy") {
      mapView?.when(() => {
        mapView?.map?.layers?.forEach((layer: any) => {
          if (layer.title === "MIT - Energy Default") {
            if (appState.vectorVal === "") {
              let where = "";
              if (appState.energyOutlookVal !== "") {
                where = filtersSelcted();
              } else {
                where = "1=1";
              }
              layer.sublayers._items[0].definitionExpression = where;
              layer.visible = true;
              layer.refresh();
            }
          }
          if (layer.title === "MIT - Energy") {
            setSpinner(true);
            const fl = new FeatureLayer({
              url: props.mit_scenario_table_spatial,
            });
            const query = fl.createQuery();
            if (appState.homeQuery === "") {
              if (appState.energyOutlookVal !== "") {
                query.where =
                  "year = " +
                  appState.outputYearVal +
                  " AND pathway = '" +
                  appState.energyOutlookVal +
                  "'";
              } else {
                query.where = "1=1";
              }
            } else {
              const wherecl: any = appState.homeQuery;
              query.where = wherecl;
            }
            fl.definitionExpression = query.where;
            layer.sublayers.items[0].definitionExpression = query.where;
            layer.load();
            query.outFields = ["*"];
            query.returnDistinctValues = false;
            query.returnGeometry = true;
            fl.queryFeatures(query)
              .then((response: any) => {
                if (response.features) {
                  const RResults = response.features.map(function (
                    feature: any
                  ) {
                    return feature;
                  });
                  if (appState.regionVal != "") {
                    mapView.goTo(RResults).finally(() => {
                      setSpinner(false);
                    });
                  } else {
                    setSpinner(false);
                  }
                } else {
                  setSpinner(false);
                }
              })
              .catch(() => {
                setSpinner(false);
              });
          }
          if (layer.title === "MIT - Value") {
            layer.visible = false;
          }
          if (layer.title === "MIT - Capacity") {
            layer.visible = false;
          }
          if (layer.title === "MIT-Energy-Filtered") {
            if (appState.vectorVal === "") {
              layer.visible = false;
            } else {
              layer.visible = true;
            }
          }
          if (layer.title === "MIT-Value-Filtered") {
            layer.visible = false;
          }
          if (layer.title === "MIT - Emissions Default") {
            layer.visible = false;
          }
          if (layer.title === "MIT - Emissions") {
            layer.visible = false;
          }
          if (layer.title === "MIT - Value Default") {
            layer.visible = false;
          }
        });
      });
    }
    if (activeLink === "Capacity") {
      mapView?.when(() => {
        mapView?.map?.layers?.forEach((layer: any) => {
          if (layer.title === "MIT - Capacity") {
            layer.visible = true;
            setSpinner(true);
            const fl = new FeatureLayer({
              url: props.mit_fc_view_table,
            });
            const query = fl.createQuery();
            if (appState.homeQuery === "") {
              query.where = "1=1";
            } else {
              const wherecl: any = appState.homeQuery;
              query.where = wherecl;
            }
            query.outFields = ["*"];
            query.returnDistinctValues = false;
            query.returnGeometry = true;
            query.orderByFields = ["capacity DESC"];
            fl.queryFeatures(query)
              .then((response: any) => {
                if (response.features) {
                  const RResults = response.features.map(function (
                    feature: any
                  ) {
                    return feature;
                  });
                  if (appState.regionVal != "") {
                    mapView?.goTo(RResults).finally(() => {
                      setSpinner(false);
                    });
                  } else {
                    setSpinner(false);
                  }
                } else {
                  setSpinner(false);
                }
              })
              .catch(() => {
                setSpinner(false);
              });
          }
          if (layer.title === "MIT - Energy") {
            layer.visible = false;
          }
          if (layer.title === "MIT - Value") {
            layer.visible = false;
          }
          if (layer.title === "MIT-Energy-Filtered") {
            layer.visible = false;
          }
          if (layer.title === "MIT - Energy Default") {
            layer.visible = false;
          }
          if (layer.title === "MIT-Value-Filtered") {
            layer.visible = false;
          }
          if (layer.title === "MIT - Value Default") {
            layer.visible = false;
          }
          if (layer.title === "MIT - Emissions Default") {
            layer.visible = false;
          }
          if (layer.title === "MIT - Emissions") {
            layer.visible = false;
          }
        });
      });
    }
    if (activeLink === "Value") {
      mapView?.when(() => {
        mapView?.map?.layers?.forEach((layer: any) => {
          if (layer.title === "MIT - Value Default") {
            if (appState.vectorVal === "") {
              let where = "";
              if (appState.energyOutlookVal !== "") {
                where = filtersSelcted();
              } else {
                where = "1=1";
              }
              layer.sublayers._items[0].definitionExpression = where;
              layer.visible = true;
              layer.refresh();
            }
          }
          if (layer.title === "MIT - Value") {
            setSpinner(true);
            const fl = new FeatureLayer({
              url: props.mit_scenario_table_spatial,
            });
            const query = fl.createQuery();
            if (appState.homeQuery === "") {
              query.where = "1=1";
            } else {
              const wherecl: any = appState.homeQuery;
              query.where = wherecl;
            }
            query.outFields = ["*"];
            query.returnDistinctValues = false;
            query.returnGeometry = true;
            fl.queryFeatures(query)
              .then((response: any) => {
                if (response.features) {
                  const RResults = response.features.map(function (
                    feature: any
                  ) {
                    return feature;
                  });
                  if (appState.regionVal != "") {
                    mapView.goTo(RResults).finally(() => {
                      setSpinner(false);
                    });
                  } else {
                    setSpinner(false);
                  }
                } else {
                  setSpinner(false);
                }
              })
              .catch(() => {
                setSpinner(false);
              });
          }
          if (layer.title === "MIT - Energy") {
            layer.visible = false;
          }
          if (layer.title === "MIT - Capacity") {
            layer.visible = false;
          }
          if (layer.title === "MIT-Energy-Filtered") {
            layer.visible = false;
          }
          if (layer.title === "MIT - Energy Default") {
            layer.visible = false;
          }
          if (layer.title === "MIT-Value-Filtered") {
            if (appState.vectorVal === "") {
              layer.visible = false;
            } else {
              layer.visible = true;
            }
          }
          if (layer.title === "MIT - Emissions Default") {
            layer.visible = false;
          }
          if (layer.title === "MIT - Emissions") {
            layer.visible = false;
          }
        });
      });
    }
    if (activeLink === "Emissions") {
      mapView?.when(() => {
        mapView?.map?.layers?.forEach((layer: any) => {
          if (
            layer.title === "MIT - Emissions Default" &&
            appState.vectorVal === "'Net Emissions'"
          ) {
            let where = "";
            setSpinner(true);
            if (appState.energyOutlookVal !== "") {
              where = filtersSelcted();
              const whereClauses = where.split(" AND ");
              const vectorLessArray = whereClauses.filter(
                (item: any) => !item.includes("vector")
              );
              const vectorLessWhereClause = vectorLessArray.join(" AND ");
              where = vectorLessWhereClause + " AND vector = 'Net Emissions'";
            } else {
              where = "vector = 'Net Emissions'";
            }
            layer.sublayers._items[0].definitionExpression = where;
            layer.visible = true;
            layer.refresh();
            const fl = new FeatureLayer({
              url: props.mit_scenario_table_spatial,
            });
            const query = fl.createQuery();
            if (appState.homeQuery === "") {
              query.where = "1=1";
            } else {
              const wherecl: any = appState.homeQuery;
              query.where = wherecl;
            }
            query.outFields = ["*"];
            query.returnDistinctValues = false;
            query.returnGeometry = true;
            fl.queryFeatures(query)
              .then((response: any) => {
                if (response.features) {
                  const RResults = response.features.map(function (
                    feature: any
                  ) {
                    return feature;
                  });
                  if (appState.regionVal != "") {
                    mapView.goTo(RResults).finally(() => {
                      setSpinner(false);
                    });
                  } else {
                    setSpinner(false);
                  }
                } else {
                  setSpinner(false);
                }
              })
              .catch(() => {
                setSpinner(false);
              });
          }
          if (
            layer.title === "MIT - Emissions" &&
            appState.vectorVal === "'Captured Emissions'"
          ) {
            let where = "";
            setSpinner(true);
            if (appState.energyOutlookVal !== "") {
              where = filtersSelcted();
              const whereClauses = where.split(" AND ");
              const vectorLessArray = whereClauses.filter(
                (item: any) => !item.includes("vector")
              );
              const vectorLessWhereClause = vectorLessArray.join(" AND ");
              where =
                vectorLessWhereClause + " AND vector = 'Captured Emissions'";
            } else {
              where = "1 = 1";
            }
            layer.sublayers._items[0].definitionExpression = where;
            layer.visible = true;
            layer.refresh();
            const fl = new FeatureLayer({
              url: props.mit_scenario_table_spatial,
            });
            const query = fl.createQuery();
            if (appState.homeQuery === "") {
              query.where = "1=1";
            } else {
              const wherecl: any = appState.homeQuery;
              query.where = wherecl;
            }
            query.outFields = ["*"];
            query.returnDistinctValues = false;
            query.returnGeometry = true;
            fl.queryFeatures(query)
              .then((response: any) => {
                if (response.features) {
                  const RResults = response.features.map(function (
                    feature: any
                  ) {
                    return feature;
                  });
                  if (appState.regionVal != "") {
                    mapView.goTo(RResults).finally(() => {
                      setSpinner(false);
                    });
                  } else {
                    setSpinner(false);
                  }
                } else {
                  setSpinner(false);
                }
              })
              .catch(() => {
                setSpinner(false);
              });
          }
          if (layer.title === "MIT - Energy") {
            layer.visible = false;
          }
          if (layer.title === "MIT - Capacity") {
            layer.visible = false;
          }
          if (layer.title === "MIT-Energy-Filtered") {
            layer.visible = false;
          }
          if (layer.title === "MIT - Energy Default") {
            layer.visible = false;
          }
          if (layer.title === "MIT-Value-Filtered") {
            layer.visible = false;
          }
          if (layer.title === "MIT - Value") {
            layer.visible = false;
          }
          if (
            layer.title === "MIT - Emissions Default" &&
            appState.vectorVal === "'Net Emissions'"
          ) {
            layer.visible = true;
          }
          if (
            layer.title === "MIT - Emissions" &&
            appState.vectorVal === "'Captured Emissions'"
          ) {
            layer.visible = true;
          }
          if (layer.title === "MIT - Value Default") {
            layer.visible = false;
          }
        });
      });
    }
  }, [activeLink]);

  useEffect(() => {
    if (appState.activeTabs === "Tab1" && activeLink === "Emissions") {
      mapView?.when(() => {
        mapView?.map?.layers?.forEach((layer: any) => {
          if (appState.vectorVal === "'Net Emissions'") {
            if (layer.title === "MIT - Emissions Default") {
              layer.visible = true;
            }
            if (layer.title === "MIT - Emissions") {
              layer.visible = false;
            }
          }
          if (appState.vectorVal === "'Captured Emissions'") {
            if (layer.title === "MIT - Emissions Default") {
              layer.visible = false;
            }
            if (layer.title === "MIT - Emissions") {
              layer.visible = true;
            }
          }
        });
      });
    }
  }, [appState.activeTabs]);

  useEffect(() => {
    if (
      appState.vectorVal !== "" &&
      activeLink === "Energy" &&
      appState.vectorVal !== "'Net Emissions'"
    ) {
      if (renderarSizeEnergy) {
        mapView?.when().then(() => {
          mapView?.map?.layers?.forEach((layer: any) => {
            if (layer.title === "MIT-Energy-Filtered") {
              mapView.map.remove(layer);
            }
          });
          const flEnergy = new FeatureLayer({
            url: props.mit_scenario_table_spatial,
          });
          const queryEnergy = flEnergy.createQuery();
          const whereclEnergy: any = filtersSelcted();
          queryEnergy.where = whereclEnergy;
          if (queryEnergy.where !== "" && queryEnergy.where !== "1 = 1") {
            queryEnergy.where = queryEnergy.where + " AND total_energy_tj > 0";
          } else {
            queryEnergy.where = "total_energy_tj > 0";
          }
          flEnergy.definitionExpression = queryEnergy.where;
          queryEnergy.outFields = ["*"];
          queryEnergy.returnDistinctValues = false;
          queryEnergy.returnGeometry = false;
          queryEnergy.orderByFields = ["Total_sum DESC"];
          queryEnergy.groupByFieldsForStatistics = ["plant_front_name"];
          const validationParams: any = [
            {
              statisticType: "sum",
              onStatisticField: "total_energy_tj",
              outStatisticFieldName: "Total_sum",
            },
            {
              statisticType: "count",
              onStatisticField: "plant_front_name",
              outStatisticFieldName: "Total_plant",
            },
          ];

          queryEnergy.outStatistics = validationParams;
          setSpinner(true);

          flEnergy
            .queryFeatures(queryEnergy)
            .then(function (response) {
              if (response.features) {
                response.features.forEach((item: any) => {
                  resultTotalEnergyOptions.push({
                    label: item.attributes.plant_front_name,
                    value: item.attributes.Total_sum,
                  });
                });
                const fl = new FeatureLayer({
                  url: props.mit_scenario_table_spatial,
                });
                const query = fl.createQuery();
                const wherecl: any = filtersSelcted();
                query.where = wherecl;
                if (query.where !== "" && query.where !== "1 = 1") {
                  query.where = query.where + " AND total_energy_tj > 0";
                } else {
                  query.where = "total_energy_tj > 0";
                }
                fl.definitionExpression = query.where;
                query.outFields = ["*"];
                query.returnDistinctValues = false;
                query.returnGeometry = true;
                query.orderByFields = ["total_energy_tj DESC"];
                fl.queryFeatures(query)
                  .then((response: any) => {
                    if (response.features) {
                      const RResults = response.features.map(function (
                        feature: any
                      ) {
                        return feature;
                      });
                      if (appState.regionVal != "") {
                        mapView.goTo(RResults).finally(() => {
                          setSpinner(false);
                        });
                      } else {
                        setSpinner(false);
                      }

                      response.features.forEach((element: any) => {
                        const item: any = resultTotalEnergyOptions.find(
                          (x: any) =>
                            x.label === element.attributes.plant_front_name
                        );
                        const index = resultTotalEnergyOptions.indexOf(item);
                        if (index !== -1) {
                          element.attributes["TotalEnergy"] =
                            resultTotalEnergyOptions[index].value;
                        }
                      });
                      const rendererE = renderarSizeEnergy;
                      // rendererE.field = "TotalEnergy";
                      // rendererE.visualVariables[0].field = "TotalEnergy";
                      // rendererE.visualVariables[0].maxDataValue =
                      //   resultTotalEnergyOptions[0]?.value;
                      // response.fields.push(
                      //   new Field({
                      //     name: "TotalEnergy",
                      //     alias: "TotalEnergy",
                      //     type: "double",
                      //   })
                      // );
                      const featureEnergy = new FeatureLayer({
                        source: response.features,
                        fields: response.fields,
                        objectIdField: "object_v1",
                        geometryType: response.geometryType,
                        spatialReference: response.spatialReference,
                        renderer: rendererE,
                        popupTemplate: popupTemplateEnergy,
                      });
                      featureEnergy.title = "MIT-Energy-Filtered";
                      if (activeLink === "Energy") {
                        if (appState.vectorVal !== "") {
                          featureEnergy.visible = true;
                          mapView?.map?.layers?.forEach((layer: any) => {
                            if (layer.title === "MIT - Energy Default") {
                              layer.visible = false;
                            }
                          });
                        } else {
                          featureEnergy.visible = false;
                          mapView?.map?.layers?.forEach((layer: any) => {
                            if (layer.title === "MIT - Energy Default") {
                              if (appState.vectorVal === "") {
                                let where = "";
                                if (appState.energyOutlookVal !== "") {
                                  where = filtersSelcted();
                                } else {
                                  where = "1=1";
                                }
                                layer.sublayers._items[0].definitionExpression =
                                  where;
                                layer.visible = true;
                                layer.refresh();
                              }
                            }
                          });
                        }
                      } else {
                        featureEnergy.visible = false;
                        mapView?.map?.layers?.forEach((layer: any) => {
                          if (layer.title === "MIT - Energy Default") {
                            layer.visible = false;
                          }
                        });
                      }
                      mapView?.map?.layers?.forEach((layer: any) => {
                        if (layer.title === "MIT-Energy-Filtered") {
                          mapView.map.remove(layer);
                        }
                      });
                      mapView?.map?.add(featureEnergy);
                    } else {
                      setSpinner(false);
                    }
                  })
                  .catch(() => {
                    setSpinner(false);
                  });
              } else {
                setSpinner(false);
              }
            })
            .catch(() => {
              setSpinner(false);
            });
        });
      }
    }
    if (appState.vectorVal !== "" && activeLink === "Value") {
      if (renderarSizeValue) {
        mapView?.when().then(() => {
          mapView?.map?.layers?.forEach((layer: any) => {
            if (layer.title === "MIT-Value-Filtered") {
              mapView.map.remove(layer);
            }
          });
          const flEnergy = new FeatureLayer({
            url: props.mit_scenario_table_spatial,
          });
          const queryEnergy = flEnergy.createQuery();
          const whereclEnergy: any = filtersSelcted();
          queryEnergy.where = whereclEnergy;
          if (queryEnergy.where !== "" && queryEnergy.where !== "1 = 1") {
            queryEnergy.where =
              queryEnergy.where + " AND total_value_dollars > 0";
          } else {
            queryEnergy.where = "total_value_dollars > 0";
          }
          flEnergy.definitionExpression = queryEnergy.where;
          queryEnergy.outFields = ["*"];
          queryEnergy.returnDistinctValues = false;
          queryEnergy.returnGeometry = false;
          queryEnergy.orderByFields = ["Total_sum DESC"];
          queryEnergy.groupByFieldsForStatistics = ["plant_front_name"];
          const validationParams: any = [
            {
              statisticType: "sum",
              onStatisticField: "total_value_dollars",
              outStatisticFieldName: "Total_sum",
            },
            {
              statisticType: "count",
              onStatisticField: "plant_front_name",
              outStatisticFieldName: "Total_plant",
            },
          ];

          queryEnergy.outStatistics = validationParams;

          setSpinner(true);

          flEnergy
            .queryFeatures(queryEnergy)
            .then(function (response) {
              if (response.features) {
                response.features.forEach((item: any) => {
                  resultTotalValueOptions.push({
                    label: item.attributes.plant_front_name,
                    value: item.attributes.Total_sum,
                  });
                });
                const fl = new FeatureLayer({
                  url: props.mit_scenario_table_spatial,
                });
                const query = fl.createQuery();

                const wherecl: any = filtersSelcted();
                query.where = wherecl;
                if (query.where !== "" && query.where !== "1 = 1") {
                  query.where = query.where + " AND total_value_dollars > 0";
                } else {
                  query.where = "total_value_dollars > 0";
                }
                fl.definitionExpression = query.where;
                query.outFields = ["*"];
                query.returnDistinctValues = false;
                query.returnGeometry = true;
                query.orderByFields = ["total_value_dollars DESC"];
                fl.queryFeatures(query)
                  .then((response: any) => {
                    if (response.features) {
                      const RResults = response.features.map(function (
                        feature: any
                      ) {
                        return feature;
                      });
                      if (appState.regionVal != "") {
                        mapView.goTo(RResults).finally(() => {
                          setSpinner(false);
                        });
                      } else {
                        setSpinner(false);
                      }

                      response.features.forEach((element: any) => {
                        const item: any = resultTotalValueOptions.find(
                          (x: any) =>
                            x.label === element.attributes.plant_front_name
                        );
                        const index = resultTotalValueOptions.indexOf(item);
                        if (index !== -1) {
                          element.attributes["TotalValue"] =
                            resultTotalValueOptions[index].value;
                        }
                      });
                      const rendererE = renderarSizeValue;
                      const featureValue = new FeatureLayer({
                        source: response.features,
                        fields: response.fields,
                        objectIdField: "object_v1",
                        geometryType: response.geometryType,
                        spatialReference: response.spatialReference,
                        renderer: rendererE,
                        popupTemplate: popupTemplateValue,
                      });
                      featureValue.title = "MIT-Value-Filtered";
                      if (activeLink === "Value") {
                        if (appState.vectorVal !== "") {
                          featureValue.visible = true;
                          mapView?.map?.layers?.forEach((layer: any) => {
                            if (layer.title === "MIT - Value Default") {
                              layer.visible = false;
                            }
                          });
                        } else {
                          featureValue.visible = false;
                          mapView?.map?.layers?.forEach((layer: any) => {
                            if (layer.title === "MIT - Value Default") {
                              if (appState.vectorVal === "") {
                                let where = "";
                                if (appState.energyOutlookVal !== "") {
                                  where = filtersSelcted();
                                } else {
                                  where = "1=1";
                                }
                                layer.sublayers._items[0].definitionExpression =
                                  where;
                                layer.visible = true;
                                layer.refresh();
                              }
                            }
                          });
                        }
                      } else {
                        mapView?.map?.layers?.forEach((layer: any) => {
                          if (layer.title === "MIT - Value Default") {
                            layer.visible = false;
                          }
                        });
                      }
                      mapView?.map?.layers?.forEach((layer: any) => {
                        if (layer.title === "MIT-Value-Filtered") {
                          mapView?.map?.remove(layer);
                        }
                      });
                      if (activeLink !== "Value") {
                        featureValue.visible = false;
                      }
                      mapView?.map?.add(featureValue);
                    } else {
                      setSpinner(false);
                    }
                  })
                  .catch((error) => {
                    setSpinner(false);
                  });
              } else {
                setSpinner(false);
              }
            })
            .catch(() => {
              setSpinner(false);
            });
        });
      }
    }
  }, [
    renderarSizeEnergy,
    renderarSizeValue,
    appState.vectorVal,
    appState.outputYearVal,
    appState.energyOutlookVal,
    activeLink,
  ]);
  const filtersSelcted = () => {
    let whereClause = "";

    if (appState.outputYearVal !== "") {
      whereClause = whereClause + "year = " + appState.outputYearVal;
    }

    if (appState.regionVal !== "") {
      if (whereClause === "") {
        whereClause =
          whereClause + "country_name IN(" + appState.regionVal + ")";
      } else {
        whereClause =
          whereClause + " AND country_name IN(" + appState.regionVal + ")";
      }
    }

    if (appState.companyVal !== "") {
      if (whereClause === "") {
        whereClause =
          whereClause + "plant_front_name IN(" + appState.companyVal + ")";
      } else {
        whereClause =
          whereClause + " AND plant_front_name IN(" + appState.companyVal + ")";
      }
    }

    if (appState.vectorVal !== "") {
      if (whereClause === "") {
        whereClause = whereClause + "vector IN(" + appState.vectorVal + ")";
      } else {
        whereClause =
          whereClause + " AND vector IN(" + appState.vectorVal + ")";
      }
    }

    if (appState.sectorVal !== "") {
      if (whereClause === "") {
        whereClause = whereClause + "product IN(" + appState.sectorVal + ")";
      } else {
        whereClause =
          whereClause + " AND product IN(" + appState.sectorVal + ")";
      }
    }

    if (appState.energyOutlookVal !== "") {
      if (whereClause === "") {
        whereClause =
          whereClause + "pathway IN('" + appState.energyOutlookVal + "')";
      } else {
        whereClause =
          whereClause + " AND pathway IN('" + appState.energyOutlookVal + "')";
      }
    }
    return whereClause;
  };
  useEffect(() => {
    if (appState.homeQuery === "") {
      mapView?.map?.layers?.forEach((layer: any) => {
        if (layer.title === "MIT - Capacity") {
          if (layer?.allSublayers?.items?.length > 0) {
            layer.allSublayers.items[0].definitionExpression = "";
          }
        }
        if (layer.title === "MIT - Energy") {
          if (layer?.allSublayers?.items?.length > 0) {
            layer.allSublayers.items[0].definitionExpression = "";
          }
        }
        if (layer.title === "MIT - Value") {
          if (layer?.allSublayers?.items?.length > 0) {
            layer.allSublayers.items[0].definitionExpression = "";
          }
        }

        if (layer.title === "MIT-Energy-Filtered") {
          if (layer?.allSublayers?.items?.length > 0) {
            layer.allSublayers.items[0].definitionExpression = "";
          }
        }
        if (layer.title === "MIT-Value-Filtered") {
          if (layer?.allSublayers?.items?.length > 0) {
            layer.allSublayers.items[0].definitionExpression = "";
          }
        }
        if (layer.title === "MIT - Emissions Default") {
          if (layer?.allSublayers?.items?.length > 0) {
            if (appState.homeQuery) {
              const whereClauses = appState.homeQuery.split(" AND ");
              const vectorLessArray = whereClauses.filter(
                (item: any) => !item.includes("vector")
              );
              const vectorLessWhereClause = vectorLessArray.join(" AND ");
              layer.allSublayers.items[0].definitionExpression =
                vectorLessWhereClause + " AND vector = 'Net Emissions'";
            } else {
              layer.allSublayers.items[0].definitionExpression =
                appState.homeQuery + " AND vector = 'Net Emissions'";
            }
            if (
              activeLink === "Emissions" &&
              appState.vectorVal === "'Net Emissions'"
            ) {
              layer.visible = true;
            } else {
              layer.visible = false;
            }
            layer.refresh();
          }
        }
        if (layer.title === "MIT - Emissions") {
          if (layer?.allSublayers?.items?.length > 0) {
            if (appState.homeQuery) {
              const whereClauses = appState.homeQuery.split(" AND ");
              const vectorLessArray = whereClauses.filter(
                (item: any) => !item.includes("vector")
              );
              const vectorLessWhereClause = vectorLessArray.join(" AND ");
              layer.allSublayers.items[0].definitionExpression =
                vectorLessWhereClause + " AND vector = 'Captured Emissions'";
            } else {
              layer.allSublayers.items[0].definitionExpression =
                appState.homeQuery + " AND vector = 'Captured Emissions'";
            }
            if (
              activeLink === "Emissions" &&
              appState.vectorVal === "'Captured Emissions'"
            ) {
              layer.visible = true;
            } else {
              layer.visible = false;
            }
            layer.refresh();
          }
        }
      });
    }
  }, [appState.homeQuery]);
  useEffect(() => {
    let whereclEnergy: any = filtersSelcted();
    if (whereclEnergy === "") {
      whereclEnergy = "1=1";
    }
    mapView?.map?.layers?.forEach((layer: any) => {
      if (layer.title === "MIT - Capacity") {
        if (layer?.allSublayers?.items?.length > 0) {
          layer.allSublayers.items[0].definitionExpression = whereclEnergy;
          layer.refresh();
        }
      }
      if (layer.title === "MIT - Energy") {
        if (layer?.allSublayers?.items?.length > 0) {
          layer.allSublayers.items[0].definitionExpression = whereclEnergy;
          layer.refresh();
        }
      }
      if (layer.title === "MIT - Value") {
        if (layer?.allSublayers?.items?.length > 0) {
          layer.allSublayers.items[0].definitionExpression = whereclEnergy;
          layer.refresh();
        }
      }
      if (layer.title === "MIT-Energy-Filtered") {
        layer.definitionExpression = whereclEnergy;
        layer.refresh();
        if (activeLink === "Energy") {
          if (appState.vectorVal === "") {
            layer.visible = false;
          } else {
            layer.visible = true;
          }
        }
      }
      if (layer.title === "MIT-Value-Filtered") {
        layer.definitionExpression = whereclEnergy;
        layer.refresh();
        if (activeLink === "Value") {
          if (appState.vectorVal === "") {
            layer.visible = false;
          } else {
            layer.visible = true;
          }
        }
      }
      if (layer.title === "MIT - Emissions Default") {
        if (layer?.allSublayers?.items?.length > 0) {
          const whereClauses = whereclEnergy.split(" AND ");
          const vectorLessArray = whereClauses.filter(
            (item: any) => !item.includes("vector")
          );
          const vectorLessWhereClause = vectorLessArray.join(" AND ");
          layer.allSublayers.items[0].definitionExpression =
            vectorLessWhereClause + " AND vector = 'Net Emissions'";
          if (
            activeLink === "Emissions" &&
            appState.vectorVal === "'Net Emissions'"
          ) {
            layer.visible = true;
          } else {
            layer.visible = false;
          }
          layer.refresh();
        }
      }

      if (layer.title === "MIT - Emissions") {
        if (layer?.allSublayers?.items?.length > 0) {
          const whereClauses = whereclEnergy.split(" AND ");
          const vectorLessArray = whereClauses.filter(
            (item: any) => !item.includes("vector")
          );
          const vectorLessWhereClause = vectorLessArray.join(" AND ");
          layer.allSublayers.items[0].definitionExpression =
            vectorLessWhereClause + " AND vector = 'Captured Emissions'";
          if (
            activeLink === "Emissions" &&
            appState.vectorVal === "'Captured Emissions'"
          ) {
            layer.visible = true;
          } else {
            layer.visible = false;
          }
          layer.refresh();
        }
      }
      if (layer.title === "MIT - Energy Default") {
        if (layer?.allSublayers?.items?.length > 0) {
          layer.allSublayers.items[0].definitionExpression = whereclEnergy;
          layer.refresh();
          if (activeLink === "Energy") {
            if (appState.vectorVal === "") {
              layer.visible = true;
            } else {
              layer.visible = false;
            }
          }
        }
      }
      if (layer.title === "MIT - Value Default") {
        if (layer?.allSublayers?.items?.length > 0) {
          layer.allSublayers.items[0].definitionExpression = whereclEnergy;
          layer.refresh();
          if (activeLink === "Value") {
            if (appState.vectorVal === "") {
              layer.visible = true;
            } else {
              layer.visible = false;
            }
          }
        }
      }
    });
  }, [
    appState.energyOutlookVal,
    appState.exploreByVal,
    appState.outputYearVal,
    appState.regionVal,
    appState.sectorVal,
    appState.vectorVal,
    appState.companyVal,
  ]);
  return (
    <div className="-mapTab">
      <div className="mapControls">
        <Navbar expand className="navbar-border">
          <div className="d-flex align-items-center">
            <Nav navbar className={`d-none d-${breakpoint}-flex flex-row`}>
              <NavItem>
                <NavLink
                  tag={Link}
                  className={activeLink === "Energy" ? "active" : ""}
                  onClick={() => setActiveNavLink("Energy")}
                >
                  <span>Site Energy Consumption (TJ)</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  tag={Link}
                  className={activeLink === "Emissions" ? "active" : ""}
                  onClick={() => setActiveNavLink("Emissions")}
                >
                  <span>Site Emissions (Mt CO2e)</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  tag={Link}
                  className={activeLink === "Capacity" ? "active" : ""}
                  onClick={() => setActiveNavLink("Capacity")}
                >
                  <span>Site Capacity (kt, MW or kbd)</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  tag={Link}
                  className={activeLink === "Value" ? "active" : ""}
                  onClick={() => setActiveNavLink("Value")}
                >
                  <span>Potential Value (GM $)</span>
                </NavLink>
              </NavItem>
            </Nav>
          </div>

          {activeLink === "Emissions" ? (
            <span style={{ color: "red" }}>
              *Site emissions cannot be seen by individual vector
            </span>
          ) : (
            ""
          )}
        </Navbar>
      </div>
      <div className="map-spinner-container">
        {spinner ? <Spinner className="spinner-container" color="brand" /> : ""}
        <div
          className="mapDiv"
          ref={mapContainer}
          id="map-container"
          data-testid="map-container"
          style={{
            opacity: spinner ? 0.5 : 1,
            pointerEvents: spinner ? "none" : "all",
          }}
        >
          <Offcanvas
            backdrop={false}
            className="offcanvas-panel-sm"
            direction="end"
            isOpen={open}
            toggle={toggleMapTools}
            scrollable={false}
          >
            <OffcanvasHeader
              className=""
              close={<Close onClick={toggleMapTools} />}
            >
              Map Tools
            </OffcanvasHeader>
            <OffcanvasBody>
              <p>Tools to be added..</p>
              <p>{appState.isAuthenticated === true ? "True" : "False"}</p>
              <p>{appState.outputYearVal}</p>
              <p>{appState.energyOutlookVal}</p>
              <p>{appState.exploreByVal}</p>
              <p>{appState.sectorVal}</p>
              <p>{appState.companyVal}</p>
              <p>{appState.regionVal}</p>
            </OffcanvasBody>
          </Offcanvas>
          <Offcanvas
            backdrop={false}
            className="offcanvas-panel-sm"
            direction="end"
            isOpen={OpenMapLayers}
            toggle={toggleMapLayers}
            scrollable={false}
          >
            <OffcanvasHeader
              className=""
              close={<Close onClick={toggleMapLayers} />}
            >
              Map Layers
            </OffcanvasHeader>
            <OffcanvasBody>
              <p>Basemap Gallery..</p>
              <div id="basemapGalleryDiv" ref={basemapContainer}></div>
            </OffcanvasBody>
          </Offcanvas>
        </div>
      </div>
    </div>
  );
};

export default MapTab;
