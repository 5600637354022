import { envComponentProps } from "../Constants/envConstants.types";
declare let process: {
    env: {
        REACT_APP_PORTAL_URL: string;
        REACT_APP_APP_ID: string;
        REACT_APP_WEBMAP_ID: string;
        REACT_APP_REDIRECT_URI: string;
        REACT_APP_MIT_SCENARIOS_TABLE:string;
        REACT_APP_MIT_SCENARIOS_SPATIAL_TABLE:string;
        REACT_APP_MIT_SCENARIOS_DECARB_TABLE:string;
        REACT_APP_MIT_FC_VIEW_TABLE:string;
        REACT_APP_MIT_SECTOR_LAYER_STEEL:string;
        REACT_APP_MIT_SECTOR_LAYER_CEMENT:string;

    };
};
export const envConstants: envComponentProps = {
    portalURL: process.env.REACT_APP_PORTAL_URL,
    appID: process.env.REACT_APP_APP_ID,
    webMapID: process.env.REACT_APP_WEBMAP_ID,
    redirectURI: process.env.REACT_APP_REDIRECT_URI,
    mit_scenario_table:process.env.REACT_APP_MIT_SCENARIOS_TABLE,
    mit_scenario_table_spatial:process.env.REACT_APP_MIT_SCENARIOS_SPATIAL_TABLE,
    mit_sceanrio_decarb_table:process.env.REACT_APP_MIT_SCENARIOS_DECARB_TABLE,
    mit_fc_view_table:process.env.REACT_APP_MIT_FC_VIEW_TABLE,
    mitFeatureLayerSteel:process.env.REACT_APP_MIT_SECTOR_LAYER_STEEL,
    mitFeatureLayerCement:process.env.REACT_APP_MIT_SECTOR_LAYER_CEMENT

};
