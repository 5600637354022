import React, { useEffect, useState, FC } from "react";
import "./Home.css";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import Select from "react-select";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import MapTab from "../TabsHome/MapTab";
import ChartTab from "../TabsHome/ChartTab";
import savePng from "../../Assets/images/save.png";
import exportPng from "../../Assets/images/export.png";
import reloadPng from "../../Assets/images/reload-16.png";
import { Link } from "react-router-dom";
import widgetsSearch from "@arcgis/core/widgets/Search";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer.js";
import Query from "@arcgis/core/rest/support/Query.js";
import { initialState } from "../GlobalContext/GlobalContextInitialState";
import { useGlobalState } from "../GlobalContext/GlobalContext";
import { envConstants } from "../../Constants/envConstants";
import { envComponentProps } from "../../Constants/envConstants.types";
import TablesTab from "../TabsHome/TablesTab";
import { Slider, ReactSelectOverrides, Card } from "@bphxd/ds-core-react";
import ConfidentialityPopup from "../ConfidentialityPopup/ConfidentialityPopup";
import { em } from "@mantine/core";

const Home = (props: envComponentProps) => {
  const { appState, setappState } = useGlobalState();
  const [activeTab, setactiveTab] = useState("Tab1");
  const [sectorValue, setsectorValue] = useState("Select Sector ...");
  const [outYearValue, setoutYearValue] = useState("");
  const [outEmissionValue, setoutEmissionValue] = useState("");
  const [outRegionValue, setoutRegionValue] = useState("");
  const [outPlantValue, setoutPlantValue] = useState("");
  const [outVectorValue, setoutVectorValue] = useState("");
  const [outSectorValue, setoutSectorValue] = useState("");
  const [outEOValue, setoutEOValue] = useState("");
  const [optionsSector, setoptionsSector] = useState<any>([]);
  const [optionsEnergyOutput, setoptionsEnergyOutput] = useState<any>([]);
  const [valueSector, setvalueSector] = useState<any>([]);

  const [sectorSelected, setsectorSelected] = useState(true);
  const [yearoption, setyearoption] = useState<any>([]);
  const [vectoroption, setvectoroption] = useState<any>([]);
  const [valueArea, setvalueArea] = useState<any>([]);
  const [valuePlant, setvaluePlant] = useState<any>([]);
  const [defaultYearOption, setDefaultYearOption] = useState<any>([]);
  const [sectorCompanyoption, setsectorCompanyoption] = useState<any>([]);
  const [sectorRegionoption, setsectorRegionoption] = useState<any>([]);
  const [EnergyUnit, setEnergyUnit] = useState("TJ");
  const [whereClauseState, setwhereClauseState] = useState<any>(
    "production_type_v1 = 'Energy Outlook' AND vector NOT IN ('Biomass_wCCS', 'Biomass_woCCS', 'Coal_wCCS', 'Coal_woCCS', 'Natural gas_wCCS', 'Natural gas_woCCS')"
  );
  const resultYearOptions: { label: any; value: any }[] = [];
  const resultvectorOptions: { label: any; value: any }[] = [];
  const resultSectorCompanyOptions: {
    label: any;
    value: any;
    groupkey: any;
  }[] = [];
  const resultSectorOptions: { label: any; value: any; groupkey: any }[] = [];
  const resultSectorOptionsGrouped: { label: any; options: any }[] = [];
  const optionsSectorGroup: { label: any; options: any }[] = [];
  const resulteoOptions: { label: any; value: any }[] = [];
  const resultSectorAreaOptions: { label: any; value: any; groupkey: any }[] =
    [];
  const resultSectorAreaOptionsGrouped: { label: any; options: any }[] = [];
  const resultSectorCompaniesOptionsGrouped: { label: any; options: any }[] =
    [];
  const optionsArea: { label: any; options: any }[] = [];
  // const optionsPlant: { label: any; options: any }[] = [];
  const [lengthTextTEC, setlengthTextTEC] = useState(0);

  const vectorOptionEmissions: any = [
    { value: "Net Emissions", label: "Net Emissions" },
    { value: "Captured Emissions", label: "Captured Emissions" },
  ];
  // setoutEmissionValue(vectorOptionEmissions[0]);

  useEffect(() => {
    if (!appState.isAuthenticated) {
      setappState({ ...initialState });
      setappState((prevState) => ({
        ...prevState,
        ConfidentialPopup: true,
      }));
      setoutEmissionValue(vectorOptionEmissions[0]);
    }
  }, [appState.isAuthenticated]);

  const _onSelectOutYear = (selected: any) => {
    setoutYearValue(selected);
    setappState((prevState) => ({
      ...prevState,
      outputYearVal: selected.value,
    }));
    filtersSelcted("Year", selected.value);
  };
  const optionsEnergyOut = [
    { label: "New Momentum", value: "New Momentum" },
    { label: "Accelerated", value: "Accelerated" },
    { label: "Net Zero", value: "Net Zero" },
  ];
  const defaultOptionEnergyOut = optionsEnergyOut[0];

  const _onSelectEnergyOut = (selected: any) => {
    setoutEOValue(selected);
    setappState((prevState) => ({
      ...prevState,
      energyOutlookVal: selected.value,
    }));
    filtersSelcted("EnergyOutlook", "'" + selected.value + "'");
  };
  const toggleActiveTab = (clickedTab: any) => {
    if (appState.activeLinkMap === "Emissions" && clickedTab !== "Tab1") {
      setappState((prevState) => ({
        ...prevState,
        vectorVal: "",
      }));
    }
    setactiveTab(clickedTab);
    setappState((prevState) => ({
      ...prevState,
      activeTabs: clickedTab,
    }));
  };

  const sectorSelectedbyUser = (event: any) => {
    setoutSectorValue(event);
    setvalueSector(event);
    if (event !== null && event !== undefined) {
      let eventValues = "";
      if (event.length > 0) {
        for (let i = 0; i < event.length; i++) {
          if (eventValues === "") {
            eventValues = "'" + event[i].label + "'";
          } else {
            eventValues = eventValues + ",'" + event[i].label + "'";
          }
        }
      }
      setappState((prevState) => ({
        ...prevState,
        sectorVal: eventValues,
      }));
      filtersSelcted("Sector", eventValues);
    } else {
      setsectorValue("");
      setappState((prevState) => ({
        ...prevState,
        sectorVal: "",
      }));
    }
  };
  const regionSelectedbyUser = (event: any) => {
    setoutRegionValue(event);
    if (event !== null && event !== undefined) {
      setvalueArea(event);
      let eventValues = "";
      if (event.length > 0) {
        for (let i = 0; i < event.length; i++) {
          if (eventValues === "") {
            eventValues = "'" + event[i].label + "'";
          } else {
            eventValues = eventValues + ",'" + event[i].label + "'";
          }
        }
      }
      setappState((prevState) => ({
        ...prevState,
        regionVal: eventValues,
      }));
      filtersSelcted("Area", eventValues);
    } else {
      setappState((prevState) => ({
        ...prevState,
        regionVal: "",
      }));
      filtersSelcted("Area", "");
    }
  };
  useEffect(() => {
    if (appState.polygonFilter) {
      queryFilteredData(
        whereClauseState +
          " AND plant_front_name IN (" +
          appState.companyVal +
          ")",
        "Polygon"
      );
    }
  }, [appState.polygonFilter]);
  const handleClearFilters = () => {
    if (activeTab !== "Tab1" && appState.activeLinkMap !== "Emissions") {
      setappState((prevState) => ({
        ...prevState,
        outputYearVal: "",
        regionVal: "",
        companyVal: "",
        vectorVal: "",
        sectorVal: "",
        energyOutlookVal: "",
        homeQuery: "",
      }));
    } else {
      setappState((prevState) => ({
        ...prevState,
        outputYearVal: "",
        regionVal: "",
        companyVal: "",
        sectorVal: "",
        energyOutlookVal: "",
        homeQuery: "",
      }));
    }
    queryFilteredData(whereClauseState, "");
  };
  const queryFilteredData = (whereClause: any, eventSelected: any) => {
    //  setoutYearValue("");
    const emptyarr: any = [];
    setoutRegionValue(emptyarr);
    setoutPlantValue("");
    setoutVectorValue("");
    setoutSectorValue("");
    setoutEOValue("");
    setvalueArea(emptyarr);
    setvaluePlant(emptyarr);
    setvalueSector(emptyarr);
    const YearFeatureLayer = new FeatureLayer({
      url: props.mit_scenario_table_spatial,
    });
    const query = YearFeatureLayer.createQuery();
    query.where = whereClause;
    if (query.where.includes("undefined")) {
      return;
    }
    query.outFields = ["year"];
    query.returnDistinctValues = true;
    query.returnGeometry = false;
    query.orderByFields = ["year ASC"];
    if (eventSelected !== "Polygon") {
      YearFeatureLayer.queryFeatures(query).then(function (response) {
        if (response.features) {
          response.features.forEach((item) => {
            if (item.attributes.year !== null) {
              resultYearOptions.push({
                label: item.attributes.year,
                value: item.attributes.year,
              });
            }
          });
          setyearoption(resultYearOptions);
          setDefaultYearOption(resultYearOptions[0]);
          const resultY: any = resultYearOptions[0];
          setoutYearValue(resultY);
          setappState((prevState) => ({
            ...prevState,
            outputYearVal: resultYearOptions[0].label,
          }));
        }
      });
    }

    const RegionFeatureLayer = new FeatureLayer({
      url: props.mit_scenario_table_spatial,
    });
    const queryRegion = RegionFeatureLayer.createQuery();
    queryRegion.where = whereClause;
    if (queryRegion.where.includes("undefined")) {
      return;
    }
    queryRegion.outFields = ["onemap_region,country_name"];
    queryRegion.returnDistinctValues = true;
    queryRegion.returnGeometry = false;
    queryRegion.orderByFields = ["onemap_region ASC"];

    RegionFeatureLayer.queryFeatures(queryRegion).then(function (response) {
      if (response.features) {
        let count = 0;
        response.features.forEach((item) => {
          const status = resultSectorAreaOptions.some(function (e) {
            return e.groupkey === item.attributes.onemap_region;
          });

          if (!status) {
            count++;
          }
          resultSectorAreaOptions.push({
            label: item.attributes.country_name,
            value: count + "|" + item.attributes.country_name,
            groupkey: item.attributes.onemap_region,
          });
        });
        const groupedArea = resultSectorAreaOptions.reduce(function (r, a) {
          r[a.groupkey] = r[a.groupkey] || [];
          r[a.groupkey].push(a);
          return r;
        }, Object.create(null));
        Object.keys(groupedArea).forEach((key) => {
          resultSectorAreaOptionsGrouped.push({
            label: key,
            options: groupedArea[key],
          });
          optionsArea.push(createGroup(key, groupedArea[key], setvalueArea));
        });

        setsectorRegionoption(optionsArea);
      }
    });
    const plantFeatureLayer = new FeatureLayer({
      url: props.mit_scenario_table_spatial,
    });
    const queryPlantName = plantFeatureLayer.createQuery();
    queryPlantName.where = whereClause;
    if (queryPlantName.where.includes("undefined")) {
      return;
    }
    queryPlantName.outFields = ["parent_company,plant_front_name"];
    queryPlantName.returnDistinctValues = true;
    queryPlantName.returnGeometry = false;
    queryPlantName.orderByFields = ["parent_company ASC"];

    plantFeatureLayer.queryFeatures(queryPlantName).then(function (response) {
      if (response.features) {
        response.features = response.features.slice(0, 1500);
        setsectorCompanyoption([]);
        let count = 0;
        response.features.forEach((item) => {
          const status = resultSectorCompanyOptions.some(function (e) {
            return (
              e.groupkey ===
              item.attributes.parent_company.replace(/"/g, "").trim()
            );
          });

          if (!status) {
            count++;
          }
          resultSectorCompanyOptions.push({
            label: item.attributes.plant_front_name.replace(/"/g, "").trim(),
            value:
              count +
              "|" +
              item.attributes.plant_front_name.replace(/"/g, "").trim(),
            groupkey: item.attributes.parent_company.replace(/"/g, "").trim(),
          });
        });
        const groupedArea = resultSectorCompanyOptions.reduce(function (r, a) {
          r[a.groupkey] = r[a.groupkey] || [];
          r[a.groupkey].push(a);
          return r;
        }, Object.create(null));
        Object.keys(groupedArea).forEach(element => {
          groupedArea[element] = groupedArea[element].filter((o:any, index:any, arr:any) =>
            arr.findIndex((item :any) => JSON.stringify(item) === JSON.stringify(o)) === index
        ); 
        });
        const optionsPlant: { label: any; options: any }[] = [];
        Object.keys(groupedArea).forEach((key) => {
          resultSectorCompaniesOptionsGrouped.push({
            label: key,
            options: groupedArea[key],
          });
          optionsPlant.push(
            createGroupPlantName(key, groupedArea[key], setvaluePlant)
          );
        });
        setsectorCompanyoption(optionsPlant);
      }
    });

    const queryPlantCount = plantFeatureLayer.createQuery();
    queryPlantCount.where = whereClause;
    if (queryPlantCount.where.includes("undefined")) {
      return;
    }
    queryPlantCount.outFields = ["plant_name"];
    queryPlantCount.returnDistinctValues = true;
    queryPlantCount.returnGeometry = false;
    const validparam: any = [
      {
        statisticType: "count",
        onStatisticField: "plant_name",
        outStatisticFieldName: "Total_PlantCount",
      },
    ];
    queryPlantCount.outStatistics = validparam;

    plantFeatureLayer.queryFeatures(queryPlantCount).then(function (response) {
      setappState((prevState) => ({
        ...prevState,
        totalPlantCount:
          response.features[0].attributes.Total_PlantCount.toLocaleString(
            "en-US"
          ).replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      }));
    });
    const vectorFeatureLayer = new FeatureLayer({
      url: props.mit_scenario_table_spatial,
    });
    const queryvector = vectorFeatureLayer.createQuery();
    queryvector.where = whereClause;
    if (queryvector.where.includes("undefined")) {
      return;
    }
    if (queryvector.where !== "1 = 1") {
      queryvector.where =
        queryvector.where + "and vector Not IN('Net Emissions','Total Energy')";
    }
    queryvector.outFields = ["vector"];
    queryvector.returnDistinctValues = true;
    queryvector.returnGeometry = false;
    queryvector.orderByFields = ["vector ASC"];

    vectorFeatureLayer.queryFeatures(queryvector).then(function (response) {
      if (response.features) {
        response.features.forEach((item) => {
          if (
            item.attributes.vector !== "" &&
            item.attributes.vector !== null
          ) {
            resultvectorOptions.push({
              label: item.attributes.vector,
              value: item.attributes.vector,
            });
          }
        });
        setvectoroption(resultvectorOptions);
      }
    });
    const sectorFeatureLayer = new FeatureLayer({
      url: props.mit_scenario_table_spatial,
    });
    const querysector = sectorFeatureLayer.createQuery();
    querysector.where = whereClause;
    if (querysector.where.includes("undefined")) {
      return;
    }
    querysector.outFields = ["sector", "product"];
    querysector.returnDistinctValues = true;
    querysector.returnGeometry = false;
    querysector.orderByFields = ["sector ASC"];

    sectorFeatureLayer.queryFeatures(querysector).then(function (response) {
      if (response.features) {
        let count = 0;
        response.features.forEach((item) => {
          const status = resultSectorOptions.some(function (e) {
            return (
              e.groupkey === item.attributes.sector.replace(/"/g, "").trim()
            );
          });

          if (!status) {
            count++;
          }
          resultSectorOptions.push({
            label: item.attributes.product.replace(/"/g, "").trim(),
            value:
              count + "|" + item.attributes.product.replace(/"/g, "").trim(),
            groupkey: item.attributes.sector.replace(/"/g, "").trim(),
          });
        });
        const groupedArea = resultSectorOptions.reduce(function (r, a) {
          r[a.groupkey] = r[a.groupkey] || [];
          r[a.groupkey].push(a);
          return r;
        }, Object.create(null));
        Object.keys(groupedArea).forEach((key) => {
          resultSectorOptionsGrouped.push({
            label: key,
            options: groupedArea[key],
          });
          optionsSectorGroup.push(
            createGroupSector(key, groupedArea[key], setvalueSector)
          );
        });
        setoptionsSector(optionsSectorGroup);
      }
    });
    const eoFeatureLayer = new FeatureLayer({
      url: props.mit_scenario_table_spatial,
    });
    const queryeo = eoFeatureLayer.createQuery();
    queryeo.where = whereClause;
    if (queryeo.where.includes("undefined")) {
      return;
    }
    queryeo.outFields = ["pathway"];
    queryeo.returnDistinctValues = true;
    queryeo.returnGeometry = false;
    queryeo.orderByFields = ["pathway ASC"];

    eoFeatureLayer.queryFeatures(queryeo).then(function (response) {
      if (response.features) {
        response.features.forEach((item) => {
          if (
            item.attributes.pathway !== "" &&
            item.attributes.pathway !== null
          ) {
            resulteoOptions.push({
              label: item.attributes.pathway,
              value: item.attributes.pathway,
            });
          }
        });
        setoptionsEnergyOutput(resulteoOptions);
        const resultEO: any = resulteoOptions[0];
        setoutEOValue(resultEO);
        setappState((prevState) => ({
          ...prevState,
          energyOutlookVal: resulteoOptions[0].label,
        }));
      }
    });
  };
  const filtersSelcted = (selectedFilter: any, selctedValue: any) => {
    let whereClause = whereClauseState;
    if (
      selectedFilter === "Year" &&
      selctedValue !== "" &&
      selctedValue !== null
    ) {
      whereClause = whereClause + " AND year = " + selctedValue;
    }

    if (
      selectedFilter !== "Year" &&
      appState.outputYearVal !== "" &&
      selctedValue !== null
    ) {
      whereClause = whereClause + " AND year = " + appState.outputYearVal;
    }

    if (
      selectedFilter === "Area" &&
      selctedValue !== "" &&
      selctedValue !== null
    ) {
      if (whereClause === "") {
        whereClause = whereClause + "country_name IN(" + selctedValue + ")";
      } else {
        whereClause =
          whereClause + " AND country_name IN(" + selctedValue + ")";
      }
    }

    if (
      selectedFilter !== "Area" &&
      appState.regionVal !== "" &&
      selctedValue !== null
    ) {
      if (whereClause === "") {
        whereClause =
          whereClause + "country_name IN(" + appState.regionVal + ")";
      } else {
        whereClause =
          whereClause + " AND country_name IN(" + appState.regionVal + ")";
      }
    }

    if (
      selectedFilter === "Plant" &&
      selctedValue !== "" &&
      selctedValue !== null
    ) {
      if (whereClause === "") {
        whereClause = whereClause + "plant_front_name IN(" + selctedValue + ")";
      } else {
        whereClause =
          whereClause + " AND plant_front_name IN(" + selctedValue + ")";
      }
    }

    if (
      selectedFilter !== "Plant" &&
      appState.companyVal !== "" &&
      selctedValue !== null
    ) {
      if (whereClause === "") {
        whereClause =
          whereClause + "plant_front_name IN(" + appState.companyVal + ")";
      } else {
        whereClause =
          whereClause + " AND plant_front_name IN(" + appState.companyVal + ")";
      }
    }

    if (
      selectedFilter === "Vector" &&
      selctedValue !== "" &&
      selctedValue !== null
    ) {
      if (whereClause === "") {
        whereClause = whereClause + "vector IN(" + selctedValue + ")";
      } else {
        whereClause = whereClause + " AND vector IN(" + selctedValue + ")";
      }
    }

    if (
      selectedFilter !== "Vector" &&
      appState.vectorVal !== "" &&
      selctedValue !== null
    ) {
      if (whereClause === "") {
        whereClause = whereClause + "vector IN(" + appState.vectorVal + ")";
      } else {
        whereClause =
          whereClause + " AND vector IN(" + appState.vectorVal + ")";
      }
    }
    if (
      selectedFilter === "Sector" &&
      selctedValue !== "" &&
      selctedValue !== null
    ) {
      if (whereClause === "") {
        whereClause = whereClause + "product IN(" + selctedValue + ")";
      } else {
        whereClause = whereClause + " AND product IN(" + selctedValue + ")";
      }
    }

    if (
      selectedFilter !== "Sector" &&
      appState.sectorVal !== "" &&
      selctedValue !== null
    ) {
      if (whereClause === "") {
        whereClause = whereClause + "product IN(" + appState.sectorVal + ")";
      } else {
        whereClause =
          whereClause + " AND product IN(" + appState.sectorVal + ")";
      }
    }
    if (
      selectedFilter === "EnergyOutlook" &&
      selctedValue !== "" &&
      selctedValue !== null
    ) {
      if (whereClause === "") {
        whereClause = whereClause + "pathway IN(" + selctedValue + ")";
      } else {
        whereClause = whereClause + " AND pathway IN(" + selctedValue + ")";
      }
    }
    if (
      selectedFilter !== "EnergyOutlook" &&
      appState.energyOutlookVal !== "" &&
      selctedValue !== null
    ) {
      if (whereClause === "") {
        whereClause =
          whereClause + "pathway IN('" + appState.energyOutlookVal + "')";
      } else {
        whereClause =
          whereClause + " AND pathway IN('" + appState.energyOutlookVal + "')";
      }
    }
    if (whereClause !== whereClauseState) {
      setappState((prevState) => ({
        ...prevState,
        homeQuery: whereClause,
      }));
    }
    if (!appState.isAuthenticated) {
      return;
    }

    if (selectedFilter !== "Plant") {
      const plantFeatureLayer = new FeatureLayer({
        url: props.mit_scenario_table_spatial,
      });
      const queryPlantName = plantFeatureLayer.createQuery();
      queryPlantName.where = whereClause;
      if (queryPlantName.where.includes("undefined")) {
        return;
      }
      queryPlantName.outFields = ["parent_company,plant_front_name"];
      queryPlantName.returnDistinctValues = true;
      queryPlantName.returnGeometry = false;
      queryPlantName.orderByFields = ["parent_company ASC"];

      plantFeatureLayer.queryFeatures(queryPlantName).then(function (response) {
        if (response.features) {
          response.features = response.features.slice(0, 1500);
          setsectorCompanyoption([]);
          let count = 0;
          response.features.forEach((item) => {
            const status = resultSectorCompanyOptions.some(function (e) {
              return (
                e.groupkey ===
                item.attributes.parent_company.replace(/"/g, "").trim()
              );
            });

            if (!status) {
              count++;
            }
            resultSectorCompanyOptions.push({
              label: item.attributes.plant_front_name.replace(/"/g, "").trim(),
              value:
                count +
                "|" +
                item.attributes.plant_front_name.replace(/"/g, "").trim(),
              groupkey: item.attributes.parent_company.replace(/"/g, "").trim(),
            });
          });
          const groupedArea = resultSectorCompanyOptions.reduce(function (
            r,
            a
          ) {
            r[a.groupkey] = r[a.groupkey] || [];
            r[a.groupkey].push(a);
            return r;
          },
          Object.create(null));
          Object.keys(groupedArea).forEach(element => {
            groupedArea[element] = groupedArea[element].filter((o:any, index:any, arr:any) =>
              arr.findIndex((item :any) => JSON.stringify(item) === JSON.stringify(o)) === index
          ); 
          });
          const optionsPlant: { label: any; options: any }[] = [];
          Object.keys(groupedArea).forEach((key) => {
            resultSectorCompaniesOptionsGrouped.push({
              label: key,
              options: groupedArea[key],
            });
            optionsPlant.push(
              createGroupPlantName(key, groupedArea[key], setvaluePlant)
            );
          });
          setsectorCompanyoption(optionsPlant);
        }
      });
      const queryPlantCount = plantFeatureLayer.createQuery();
      queryPlantCount.where = whereClause;
      if (queryPlantCount.where.includes("undefined")) {
        return;
      }
      queryPlantCount.outFields = ["plant_name"];
      queryPlantCount.returnDistinctValues = true;
      queryPlantCount.returnGeometry = false;
      const validparam: any = [
        {
          statisticType: "count",
          onStatisticField: "plant_name",
          outStatisticFieldName: "Total_PlantCount",
        },
      ];
      queryPlantCount.outStatistics = validparam;

      plantFeatureLayer
        .queryFeatures(queryPlantCount)
        .then(function (response) {
          setappState((prevState) => ({
            ...prevState,
            totalPlantCount:
              response.features[0].attributes.Total_PlantCount.toLocaleString(
                "en-US"
              ).replace(/\B(?=(\d{3})+(?!\d))/g, ","),
          }));
        });
    }
    if (selectedFilter !== "Vector" && appState.vectorVal === "") {
      const vectorFeatureLayer = new FeatureLayer({
        url: props.mit_scenario_table_spatial,
      });
      const queryvector = vectorFeatureLayer.createQuery();
      queryvector.where = whereClause;
      if (queryvector.where.includes("undefined")) {
        return;
      }
      if (queryvector.where !== "1 = 1") {
        queryvector.where =
          queryvector.where +
          "and vector Not IN('Net Emissions','Total Energy')";
      }
      queryvector.outFields = ["vector"];
      queryvector.returnDistinctValues = true;
      queryvector.returnGeometry = false;
      queryvector.orderByFields = ["vector ASC"];

      vectorFeatureLayer.queryFeatures(queryvector).then(function (response) {
        if (response.features) {
          response.features.forEach((item) => {
            if (
              item.attributes.vector !== "" &&
              item.attributes.vector !== null
            ) {
              resultvectorOptions.push({
                label: item.attributes.vector,
                value: item.attributes.vector,
              });
            }
          });
          setvectoroption(resultvectorOptions);
        }
      });
    }
    if (selectedFilter !== "Sector" && appState.sectorVal === "") {
      const sectorFeatureLayer = new FeatureLayer({
        url: props.mit_scenario_table_spatial,
      });
      const querysector = sectorFeatureLayer.createQuery();
      querysector.where = whereClause;
      if (querysector.where.includes("undefined")) {
        return;
      }
      querysector.outFields = ["sector", "product"];
      querysector.returnDistinctValues = true;
      querysector.returnGeometry = false;
      querysector.orderByFields = ["sector ASC"];

      sectorFeatureLayer.queryFeatures(querysector).then(function (response) {
        if (response.features) {
          let count = 0;
          response.features.forEach((item) => {
            const status = resultSectorOptions.some(function (e) {
              return (
                e.groupkey === item.attributes.sector.replace(/"/g, "").trim()
              );
            });

            if (!status) {
              count++;
            }
            resultSectorOptions.push({
              label: item.attributes.product.replace(/"/g, "").trim(),
              value:
                count + "|" + item.attributes.product.replace(/"/g, "").trim(),
              groupkey: item.attributes.sector.replace(/"/g, "").trim(),
            });
          });
          const groupedArea = resultSectorOptions.reduce(function (r, a) {
            r[a.groupkey] = r[a.groupkey] || [];
            r[a.groupkey].push(a);
            return r;
          }, Object.create(null));
          Object.keys(groupedArea).forEach((key) => {
            resultSectorOptionsGrouped.push({
              label: key,
              options: groupedArea[key],
            });
            optionsSectorGroup.push(
              createGroupSector(key, groupedArea[key], setvalueSector)
            );
          });
          setoptionsSector(optionsSectorGroup);
        }
      });
    }
  };

  const CompanyEnteredValuebyUser = (inputVal: any) => {
    if (inputVal.length < 2) {
      return;
    }
    let whereClause = "1 = 1";
    if (appState.outputYearVal !== "") {
      whereClause = "year = " + appState.outputYearVal;
    }
    if (appState.energyOutlookVal !== "") {
      whereClause =
        whereClause + " AND pathway = '" + appState.energyOutlookVal + "'";
    }
    if (appState.regionVal !== "") {
      whereClause =
        whereClause + "AND country_name IN(" + appState.regionVal + ")";
    }
    if (appState.companyVal !== "" && whereClause === "1 = 1") {
      whereClause = "plant_front_name IN(" + appState.companyVal + ")";
    }
    if (appState.companyVal !== "" && whereClause !== "1 = 1") {
      whereClause =
        whereClause + " AND plant_front_name IN(" + appState.companyVal + ")";
    }

    if (appState.sectorVal !== "" && whereClause === "1 = 1") {
      whereClause = "product IN(" + appState.sectorVal + ")";
    }
    if (appState.sectorVal !== "" && whereClause !== "1 = 1") {
      whereClause = whereClause + " AND product IN(" + appState.sectorVal + ")";
    }
    if (appState.vectorVal !== "" && whereClause === "1 = 1") {
      whereClause = "vector IN(" + appState.vectorVal + ")";
    }
    if (appState.vectorVal !== "" && whereClause !== "1 = 1") {
      whereClause = whereClause + " AND vector IN(" + appState.vectorVal + ")";
    }
    const plantFeatureLayer = new FeatureLayer({
      url: props.mit_scenario_table_spatial,
    });
    const queryPlantName = plantFeatureLayer.createQuery();
    const logicalOperator = appState.companyVal !== "" ? "OR" : "AND";
    queryPlantName.where =
      whereClause +
      " " +
      logicalOperator +
      " (plant_front_name like '%" +
      inputVal +
      "%' OR parent_company like '%" +
      inputVal +
      "%')";
    if (queryPlantName.where.includes("undefined")) {
      return;
    }
    queryPlantName.outFields = ["parent_company,plant_front_name"];
    queryPlantName.returnDistinctValues = true;
    queryPlantName.returnGeometry = false;
    queryPlantName.orderByFields = ["parent_company ASC"];

    plantFeatureLayer.queryFeatures(queryPlantName).then(function (response) {
      if (response.features) {
        response.features = response.features.slice(0, 1500);
        setsectorCompanyoption([]);
        let count = 0;
        response.features.forEach((item) => {
          const status = resultSectorCompanyOptions.some(function (e) {
            return (
              e.groupkey ===
              item.attributes.parent_company.replace(/"/g, "").trim()
            );
          });

          if (!status) {
            count++;
          }
          resultSectorCompanyOptions.push({
            label: item.attributes.plant_front_name.replace(/"/g, "").trim(),
            value:
              // count +
              "|" + item.attributes.plant_front_name.replace(/"/g, "").trim(),
            groupkey: item.attributes.parent_company.replace(/"/g, "").trim(),
          });
        });
        const groupedArea = resultSectorCompanyOptions.reduce(function (r, a) {
          r[a.groupkey] = r[a.groupkey] || [];
          r[a.groupkey].push(a);
          return r;
        }, Object.create(null));
        Object.keys(groupedArea).forEach(element => {
          groupedArea[element] = groupedArea[element].filter((o:any, index:any, arr:any) =>
            arr.findIndex((item :any) => JSON.stringify(item) === JSON.stringify(o)) === index
        ); 
        });
        const optionsPlant: { label: any; options: any }[] = [];
        Object.keys(groupedArea).forEach((key) => {
          resultSectorCompaniesOptionsGrouped.push({
            label: key,
            options: groupedArea[key],
          });
          optionsPlant.push(
            createGroupPlantName(key, groupedArea[key], setvaluePlant)
          );
        });
        // setsectorCompanyoption(optionsPlant);
        setsectorCompanyoption(
          optionsPlant.map((item) => {
            item.options = Array.from(
              new Set(item.options.map((option: any) => JSON.stringify(option)))
            ).map((option: any) => JSON.parse(option));
            return item;
          })
        );
      }
    });
  };
  const companySelectedbyUser = (event: any) => {
    setvaluePlant(event);
    if (event !== null && event !== undefined) {
      let eventValues = "";
      if (event.length > 0) {
        for (let i = 0; i < event.length; i++) {
          if (eventValues === "") {
            eventValues = "'" + event[i].label + "'";
          } else {
            eventValues = eventValues + ",'" + event[i].label + "'";
          }
        }
      }
      setappState((prevState) => ({
        ...prevState,
        companyVal: eventValues,
      }));
      filtersSelcted("Plant", eventValues);
    } else {
      setappState((prevState) => ({
        ...prevState,
        companyVal: "",
      }));
      filtersSelcted("Plant", "");
    }
  };
  const vectorSelectedbyUserEmission = (event: any) => {
    setoutEmissionValue(event);
    const eventValues = "'" + event.value + "'";
    setappState((prevState) => ({
      ...prevState,
      vectorVal: eventValues,
    }));
    filtersSelcted("Vector", eventValues);
  };
  const vectorSelectedbyUser = (event: any) => {
    setoutVectorValue(event);
    if (event !== null && event !== undefined) {
      let eventValues = "";
      if (event.length > 0) {
        for (let i = 0; i < event.length; i++) {
          if (eventValues === "") {
            eventValues = "'" + event[i].value + "'";
          } else {
            eventValues = eventValues + ",'" + event[i].value + "'";
          }
        }
      }
      setappState((prevState) => ({
        ...prevState,
        vectorVal: eventValues,
      }));
      filtersSelcted("Vector", eventValues);
    } else {
      setappState((prevState) => ({
        ...prevState,
        vectorVal: "",
      }));
    }
  };
  useEffect(() => {
    if (appState.isAuthenticated) {
      let whereClause = "1 = 1";
      if (appState.outputYearVal !== "") {
        whereClause = "year = " + appState.outputYearVal;
      }
      if (appState.energyOutlookVal !== "") {
        whereClause =
          whereClause + " AND pathway = '" + appState.energyOutlookVal + "'";
      }
      if (appState.regionVal !== "") {
        whereClause =
          whereClause + "AND country_name IN(" + appState.regionVal + ")";
      }
      if (appState.companyVal !== "" && whereClause === "1 = 1") {
        whereClause = "plant_front_name IN(" + appState.companyVal + ")";
      }
      if (appState.companyVal !== "" && whereClause !== "1 = 1") {
        whereClause =
          whereClause + " AND plant_front_name IN(" + appState.companyVal + ")";
      }

      if (appState.sectorVal !== "" && whereClause === "1 = 1") {
        whereClause = "product IN(" + appState.sectorVal + ")";
      }
      if (appState.sectorVal !== "" && whereClause !== "1 = 1") {
        whereClause =
          whereClause + " AND product IN(" + appState.sectorVal + ")";
      }
      if (activeTab !== "Tab1" && appState.activeLinkMap !== "Emissions") {
        if (appState.vectorVal !== "" && whereClause === "1 = 1") {
          whereClause = "vector IN(" + appState.vectorVal + ")";
        }
        if (appState.vectorVal !== "" && whereClause !== "1 = 1") {
          whereClause =
            whereClause + " AND vector IN(" + appState.vectorVal + ")";
        }
      }
      const TotalEnergyFeatureLayer = new FeatureLayer({
        url: props.mit_scenario_table_spatial,
      });
      const query = TotalEnergyFeatureLayer.createQuery();

      query.where = whereClause;

      if (query.where.includes("undefined")) {
        return;
      }

      query.outFields = ["*"];
      query.returnDistinctValues = false;
      query.returnGeometry = false;
      const validationParamsPlant: any = [
        {
          statisticType: "sum",
          onStatisticField: "total_energy_tj",
          outStatisticFieldName: "Total_sum",
        },
      ];
      query.outStatistics = validationParamsPlant;
      TotalEnergyFeatureLayer.queryFeatures(query).then(function (response) {
        if (response.features) {
          const energyTotal = Math.round(
            parseInt(response.features[0].attributes.Total_sum)
          );
          setEnergyUnit("TJ");
          setlengthTextTEC(energyTotal.toString().length);
          setappState((prevState) => ({
            ...prevState,
            totalEnergyConsumption: energyTotal
              .toLocaleString("en-US")
              .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
          }));
        }
      });
      const TotalEmissionFeatureLayer = new FeatureLayer({
        url: props.mit_scenario_table_spatial,
      });

      const queryemission = TotalEmissionFeatureLayer.createQuery();
      whereClause = "1 = 1";
      if (appState.outputYearVal !== "") {
        whereClause = "year = " + appState.outputYearVal;
      }
      if (appState.energyOutlookVal !== "") {
        whereClause =
          whereClause + " AND pathway = '" + appState.energyOutlookVal + "'";
      }
      if (appState.regionVal !== "") {
        whereClause =
          whereClause + " AND country_name IN(" + appState.regionVal + ")";
      }
      if (appState.companyVal !== "" && whereClause === "1 = 1") {
        whereClause = "plant_front_name IN(" + appState.companyVal + ")";
      }
      if (appState.companyVal !== "" && whereClause !== "1 = 1") {
        whereClause =
          whereClause + " AND plant_front_name IN(" + appState.companyVal + ")";
      }

      if (appState.sectorVal !== "" && whereClause === "1 = 1") {
        whereClause = "product IN(" + appState.sectorVal + ")";
      }
      if (appState.sectorVal !== "" && whereClause !== "1 = 1") {
        whereClause =
          whereClause + " AND product IN(" + appState.sectorVal + ")";
      }
      let wherecl: any = "";
      if (whereClause === "1 = 1") {
        wherecl = "vector = 'Net Emissions'";
      } else {
        wherecl = whereClause + " AND vector = 'Net Emissions'";
      }
      queryemission.where = wherecl;
      if (queryemission.where.includes("undefined")) {
        return;
      }
      queryemission.outFields = ["*"];
      queryemission.returnDistinctValues = false;
      queryemission.returnGeometry = false;
      const validationParamsPlantEmission: any = [
        {
          statisticType: "sum",
          onStatisticField: "total_vector_energy",
          outStatisticFieldName: "Total_sum",
        },
      ];
      queryemission.outStatistics = validationParamsPlantEmission;
      TotalEmissionFeatureLayer.queryFeatures(queryemission).then(function (
        response
      ) {
        if (response.features) {
          const energyTotal = Math.round(
            parseInt(response.features[0].attributes.Total_sum)
          );

          setappState((prevState) => ({
            ...prevState,
            totalemissions: energyTotal
              .toLocaleString("en-US")
              .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
          }));
        }
      });
    }
  }, [
    appState.outputYearVal,
    appState.energyOutlookVal,
    appState.companyVal,
    appState.regionVal,
    appState.sectorVal,
    appState.vectorVal,
    appState.exploreByVal,
    valueArea,
    valuePlant,
    valueSector,
    appState.polygonFilter,
  ]);
  useEffect(() => {
    if (!appState.isAuthenticated) {
      return;
    }
    const YearFeatureLayer = new FeatureLayer({
      url: props.mit_scenario_table_spatial,
    });
    const query = YearFeatureLayer.createQuery();
    query.where = whereClauseState;
    if (query.where.includes("undefined")) {
      return;
    }
    query.outFields = ["year"];
    query.returnDistinctValues = true;
    query.returnGeometry = false;
    query.orderByFields = ["year ASC"];

    YearFeatureLayer.queryFeatures(query).then(function (response) {
      if (response.features) {
        response.features.forEach((item) => {
          if (item.attributes.year !== null) {
            resultYearOptions.push({
              label: item.attributes.year,
              value: item.attributes.year,
            });
          }
        });
        setyearoption(resultYearOptions);
        setDefaultYearOption([resultYearOptions[0]]);
        const resultsY: any = resultYearOptions[0];
        setoutYearValue(resultsY);
        setappState((prevState) => ({
          ...prevState,
          outputYearVal: resultYearOptions[0].label,
        }));
      }
    });

    const RegionFeatureLayer = new FeatureLayer({
      url: props.mit_scenario_table_spatial,
    });
    const queryRegion = RegionFeatureLayer.createQuery();
    queryRegion.where = whereClauseState;
    if (queryRegion.where.includes("undefined")) {
      return;
    }
    queryRegion.outFields = ["onemap_region,country_name"];
    queryRegion.returnDistinctValues = true;
    queryRegion.returnGeometry = false;
    queryRegion.orderByFields = ["onemap_region ASC"];

    RegionFeatureLayer.queryFeatures(queryRegion).then(function (response) {
      if (response.features) {
        let count = 0;
        response.features.forEach((item) => {
          if (item.attributes.onemap_region === null) {
            return;
          }
          if (item.attributes.country_name === null) {
            return;
          }
          const status = resultSectorAreaOptions.some(function (e) {
            return e.groupkey === item.attributes.onemap_region;
          });

          if (!status) {
            count++;
          }
          resultSectorAreaOptions.push({
            label: item.attributes.country_name,
            value: count + "|" + item.attributes.country_name,
            groupkey: item.attributes.onemap_region,
          });
        });
        const groupedArea = resultSectorAreaOptions.reduce(function (r, a) {
          r[a.groupkey] = r[a.groupkey] || [];
          r[a.groupkey].push(a);
          return r;
        }, Object.create(null));
        Object.keys(groupedArea).forEach(element => {
          groupedArea[element] = groupedArea[element].filter((o:any, index:any, arr:any) =>
            arr.findIndex((item :any) => JSON.stringify(item) === JSON.stringify(o)) === index
        ); 
        });
        Object.keys(groupedArea).forEach((key) => {
          resultSectorAreaOptionsGrouped.push({
            label: key,
            options: groupedArea[key],
          });
          optionsArea.push(createGroup(key, groupedArea[key], setvalueArea));
        });

        setsectorRegionoption(optionsArea);
      }
    });
    const plantFeatureLayer = new FeatureLayer({
      url: props.mit_scenario_table_spatial,
    });
    const queryPlantName = plantFeatureLayer.createQuery();
    queryPlantName.where = whereClauseState;
    if (queryPlantName.where.includes("undefined")) {
      return;
    }
    queryPlantName.outFields = ["parent_company,plant_front_name"];
    queryPlantName.returnDistinctValues = true;
    queryPlantName.returnGeometry = false;
    queryPlantName.orderByFields = ["parent_company ASC"];

    plantFeatureLayer.queryFeatures(queryPlantName).then(function (response) {
      if (response.features) {
        response.features = response.features.slice(0, 1500);
        setsectorCompanyoption([]);
        let count = 0;
        response.features.forEach((item) => {
          if (item.attributes.plant_front_name === null) {
            return;
          }
          if (item.attributes.parent_company === null) {
            return;
          }
          const status = resultSectorCompanyOptions.some(function (e) {
            return (
              e.groupkey ===
              item.attributes.parent_company.replace(/"/g, "").trim()
            );
          });

          if (!status) {
            count++;
          }
          resultSectorCompanyOptions.push({
            label: item.attributes.plant_front_name.replace(/"/g, "").trim(),
            value:
              count +
              "|" +
              item.attributes.plant_front_name.replace(/"/g, "").trim(),
            groupkey: item.attributes.parent_company.replace(/"/g, "").trim(),
          });
        });
        const groupedArea = resultSectorCompanyOptions.reduce(function (r, a) {
          r[a.groupkey] = r[a.groupkey] || [];
          r[a.groupkey].push(a);
          return r;
        }, Object.create(null));        
        Object.keys(groupedArea).forEach(element => {
          groupedArea[element] = groupedArea[element].filter((o:any, index:any, arr:any) =>
            arr.findIndex((item :any) => JSON.stringify(item) === JSON.stringify(o)) === index
        ); 
        });
        const optionsPlant: { label: any; options: any }[] = [];
        Object.keys(groupedArea).forEach((key) => {
          resultSectorCompaniesOptionsGrouped.push({
            label: key,
            options: groupedArea[key],
          });
          optionsPlant.push(
            createGroupPlantName(key, groupedArea[key], setvaluePlant)
          );
        });
        setsectorCompanyoption(optionsPlant);
      }
    });
    const queryPlantCount = plantFeatureLayer.createQuery();
    queryPlantCount.where = whereClauseState;
    if (queryPlantCount.where.includes("undefined")) {
      return;
    }
    queryPlantCount.outFields = ["plant_name"];
    queryPlantCount.returnDistinctValues = true;
    queryPlantCount.returnGeometry = false;
    const validparam: any = [
      {
        statisticType: "count",
        onStatisticField: "plant_name",
        outStatisticFieldName: "Total_PlantCount",
      },
    ];
    queryPlantCount.outStatistics = validparam;

    plantFeatureLayer.queryFeatures(queryPlantCount).then(function (response) {
      setappState((prevState) => ({
        ...prevState,
        totalPlantCount:
          response.features[0].attributes.Total_PlantCount.toLocaleString(
            "en-US"
          ).replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      }));
    });
    const vectorFeatureLayer = new FeatureLayer({
      url: props.mit_scenario_table_spatial,
    });
    const queryvector = vectorFeatureLayer.createQuery();
    queryvector.where = whereClauseState;
    if (queryvector.where.includes("undefined")) {
      return;
    }
    if (queryvector.where !== "1 = 1") {
      queryvector.where =
        queryvector.where + "and vector Not IN('Net Emissions','Total Energy')";
    }
    queryvector.outFields = ["vector"];
    queryvector.returnDistinctValues = true;
    queryvector.returnGeometry = false;
    queryvector.orderByFields = ["vector ASC"];

    vectorFeatureLayer.queryFeatures(queryvector).then(function (response) {
      if (response.features) {
        response.features.forEach((item) => {
          if (
            item.attributes.vector !== "" &&
            item.attributes.vector !== null
          ) {
            resultvectorOptions.push({
              label: item.attributes.vector,
              value: item.attributes.vector,
            });
          }
        });
        setvectoroption(resultvectorOptions);
      }
    });
    const sectorFeatureLayer = new FeatureLayer({
      url: props.mit_scenario_table_spatial,
    });
    const querysector = sectorFeatureLayer.createQuery();
    querysector.where = whereClauseState;
    if (querysector.where.includes("undefined")) {
      return;
    }
    querysector.outFields = ["sector", "product"];
    querysector.returnDistinctValues = true;
    querysector.returnGeometry = false;
    querysector.orderByFields = ["sector ASC"];

    sectorFeatureLayer.queryFeatures(querysector).then(function (response) {
      if (response.features) {
        let count = 0;
        response.features.forEach((item) => {
          const status = resultSectorOptions.some(function (e) {
            return (
              e.groupkey === item.attributes.sector.replace(/"/g, "").trim()
            );
          });

          if (!status) {
            count++;
          }
          resultSectorOptions.push({
            label: item.attributes.product.replace(/"/g, "").trim(),
            value:
              count + "|" + item.attributes.product.replace(/"/g, "").trim(),
            groupkey: item.attributes.sector.replace(/"/g, "").trim(),
          });
        });
        const groupedArea = resultSectorOptions.reduce(function (r, a) {
          r[a.groupkey] = r[a.groupkey] || [];
          r[a.groupkey].push(a);
          return r;
        }, Object.create(null));
        Object.keys(groupedArea).forEach((key) => {
          resultSectorOptionsGrouped.push({
            label: key,
            options: groupedArea[key],
          });
          optionsSectorGroup.push(
            createGroupSector(key, groupedArea[key], setvalueSector)
          );
        });
        setoptionsSector(optionsSectorGroup);
      }
    });
    const eoFeatureLayer = new FeatureLayer({
      url: props.mit_scenario_table_spatial,
    });
    const queryeo = eoFeatureLayer.createQuery();
    queryeo.where = whereClauseState;
    if (queryeo.where.includes("undefined")) {
      return;
    }
    queryeo.outFields = ["pathway"];
    queryeo.returnDistinctValues = true;
    queryeo.returnGeometry = false;
    queryeo.orderByFields = ["pathway ASC"];

    eoFeatureLayer.queryFeatures(queryeo).then(function (response) {
      if (response.features) {
        response.features.forEach((item) => {
          if (
            item.attributes.pathway !== "" &&
            item.attributes.pathway !== null
          ) {
            resulteoOptions.push({
              label: item.attributes.pathway,
              value: item.attributes.pathway,
            });
          }
        });
        setoptionsEnergyOutput(resulteoOptions);
        const resultEO: any = resulteoOptions[0];
        setoutEOValue(resultEO);
        setappState((prevState) => ({
          ...prevState,
          energyOutlookVal: resulteoOptions[0].label,
        }));
      }
    });
  }, [appState.isAuthenticated]);
  const createGroupPlantName = (
    groupName: any,
    options: any,
    setvaluePlant: any
  ) => {
    return {
      label: (() => {
        return (
          <div
            className="companyGroup"
            onClick={() => {
              setvaluePlant((value: any) =>
                value.concat(
                  options.filter((grpOpt: any) => !value.includes(grpOpt))
                )
              );
            }}
          >
            {groupName.trim()}
          </div>
        );
      })(),
      options: options,
    };
  };
  const createGroup = (groupName: any, options: any, setvalueArea: any) => {
    return {
      label: (() => {
        return (
          <div
            className="companyGroup"
            onClick={() => {
              setvalueArea((value: any) =>
                value.concat(
                  options.filter((grpOpt: any) => !value.includes(grpOpt))
                )
              );
            }}
          >
            {groupName}
          </div>
        );
      })(),
      options: options,
    };
  };
  const createGroupSector = (
    groupName: any,
    options: any,
    setvalueSector: any
  ) => {
    return {
      label: (() => {
        return (
          <div
            className="companyGroup"
            onClick={() => {
              setvalueSector((value: any) =>
                value.concat(
                  options.filter((grpOpt: any) => !value.includes(grpOpt))
                )
              );
            }}
          >
            {groupName.trim()}
          </div>
        );
      })(),
      options: options,
    };
  };

  useEffect(() => {
    if (valueArea !== null && valueArea !== "" && valueArea.length > 0) {
      let eventValues = "";
      if (valueArea.length > 0) {
        for (let i = 0; i < valueArea.length; i++) {
          if (eventValues === "") {
            eventValues = "'" + valueArea[i].label + "'";
          } else {
            eventValues = eventValues + ",'" + valueArea[i].label + "'";
          }
        }
      }
      setappState((prevState) => ({
        ...prevState,
        regionVal: eventValues,
      }));
      filtersSelcted("Area", eventValues);
    }
  }, [valueArea]);

  useEffect(() => {
    if (valuePlant !== null && valuePlant !== "" && valuePlant.length > 0) {
      let eventValues = "";
      if (valuePlant.length > 0) {
        for (let i = 0; i < valuePlant.length; i++) {
          if (eventValues === "") {
            eventValues = "'" + valuePlant[i].label + "'";
          } else {
            eventValues = eventValues + ",'" + valuePlant[i].label + "'";
          }
        }
      }
      setappState((prevState) => ({
        ...prevState,
        companyVal: eventValues,
      }));
      filtersSelcted("Plant", eventValues);
    }
    filtersSelcted("Plant", "");
  }, [valuePlant]);
  useEffect(() => {
    let eventValues = "";
    if (valuePlant !== null && valuePlant !== "" && valuePlant.length > 0) {
      if (valuePlant.length > 0) {
        for (let i = 0; i < valuePlant.length; i++) {
          if (eventValues === "") {
            eventValues = "'" + valuePlant[i].label + "'";
          } else {
            eventValues = eventValues + ",'" + valuePlant[i].label + "'";
          }
        }
      }
    }
    let whereClause = "1 = 1";
    if (appState.outputYearVal !== "") {
      whereClause = "year = " + appState.outputYearVal;
    }
    if (appState.energyOutlookVal !== "") {
      whereClause =
        whereClause + " AND pathway = '" + appState.energyOutlookVal + "'";
    }
    if (appState.regionVal !== "") {
      whereClause =
        whereClause + "AND country_name IN(" + appState.regionVal + ")";
    }
    if (appState.companyVal !== "" && whereClause === "1 = 1") {
      whereClause = "plant_front_name IN(" + appState.companyVal + ")";
    }
    if (appState.companyVal !== "" && whereClause !== "1 = 1") {
      whereClause =
        whereClause + " AND plant_front_name IN(" + appState.companyVal + ")";
    }
    if (
      appState.companyVal === "" &&
      whereClause !== "1 = 1" &&
      eventValues !== ""
    ) {
      whereClause =
        whereClause + " AND plant_front_name IN(" + eventValues + ")";
    }
    if (appState.sectorVal !== "" && whereClause === "1 = 1") {
      whereClause = "product IN(" + appState.sectorVal + ")";
    }
    if (appState.sectorVal !== "" && whereClause !== "1 = 1") {
      whereClause = whereClause + " AND product IN(" + appState.sectorVal + ")";
    }
    if (appState.vectorVal !== "" && whereClause === "1 = 1") {
      whereClause = "vector IN(" + appState.vectorVal + ")";
    }
    if (appState.vectorVal !== "" && whereClause !== "1 = 1") {
      whereClause = whereClause + " AND vector IN(" + appState.vectorVal + ")";
    }
    const plantFeatureLayer = new FeatureLayer({
      url: props.mit_scenario_table_spatial,
    });
    const queryPlantCount = plantFeatureLayer.createQuery();
    queryPlantCount.where = whereClause;
    if (queryPlantCount.where.includes("undefined")) {
      return;
    }
    queryPlantCount.outFields = ["plant_name"];
    queryPlantCount.returnDistinctValues = true;
    queryPlantCount.returnGeometry = false;
    const validparam: any = [
      {
        statisticType: "count",
        onStatisticField: "plant_name",
        outStatisticFieldName: "Total_PlantCount",
      },
    ];
    queryPlantCount.outStatistics = validparam;

    plantFeatureLayer.queryFeatures(queryPlantCount).then(function (response) {
      if (response.features) {
        setappState((prevState) => ({
          ...prevState,
          totalPlantCount:
            response.features[0].attributes.Total_PlantCount.toLocaleString(
              "en-US"
            ).replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        }));
      }
    });
  }, [appState.companyVal]);
  useEffect(() => {
    if (valueSector !== null && valueSector !== "" && valueSector.length > 0) {
      let eventValues = "";
      if (valueSector.length > 0) {
        for (let i = 0; i < valueSector.length; i++) {
          if (eventValues === "") {
            eventValues = "'" + valueSector[i].label + "'";
          } else {
            eventValues = eventValues + ",'" + valueSector[i].label + "'";
          }
        }
      }
      setappState((prevState) => ({
        ...prevState,
        sectorVal: eventValues,
      }));
      filtersSelcted("Sector", eventValues);
    }
  }, [valueSector]);

  useEffect(() => {
    if (appState.sectorVal) {
      const selectedFilter: any = "";
      const selctedValue = "";
      let whereClause = whereClauseState;
      if (
        selectedFilter === "Year" &&
        selctedValue !== "" &&
        selctedValue !== null
      ) {
        whereClause = whereClause + " AND year = " + selctedValue;
      }

      if (
        selectedFilter !== "Year" &&
        appState.outputYearVal !== "" &&
        selctedValue !== null
      ) {
        whereClause = whereClause + " AND year = " + appState.outputYearVal;
      }

      if (
        selectedFilter === "Area" &&
        selctedValue !== "" &&
        selctedValue !== null
      ) {
        if (whereClause === "") {
          whereClause = whereClause + "country_name IN(" + selctedValue + ")";
        } else {
          whereClause =
            whereClause + " AND country_name IN(" + selctedValue + ")";
        }
      }

      if (
        selectedFilter !== "Area" &&
        appState.regionVal !== "" &&
        selctedValue !== null
      ) {
        if (whereClause === "") {
          whereClause =
            whereClause + "country_name IN(" + appState.regionVal + ")";
        } else {
          whereClause =
            whereClause + " AND country_name IN(" + appState.regionVal + ")";
        }
      }

      if (
        selectedFilter === "Plant" &&
        selctedValue !== "" &&
        selctedValue !== null
      ) {
        if (whereClause === "") {
          whereClause =
            whereClause + "plant_front_name IN(" + selctedValue + ")";
        } else {
          whereClause =
            whereClause + " AND plant_front_name IN(" + selctedValue + ")";
        }
      }

      if (
        selectedFilter !== "Plant" &&
        appState.companyVal !== "" &&
        selctedValue !== null
      ) {
        if (whereClause === "") {
          whereClause =
            whereClause + "plant_front_name IN(" + appState.companyVal + ")";
        } else {
          whereClause =
            whereClause +
            " AND plant_front_name IN(" +
            appState.companyVal +
            ")";
        }
      }
      if (
        selectedFilter === "Vector" &&
        selctedValue !== "" &&
        selctedValue !== null
      ) {
        if (whereClause === "") {
          whereClause = whereClause + "vector IN(" + selctedValue + ")";
        } else {
          whereClause = whereClause + " AND vector IN(" + selctedValue + ")";
        }
      }

      if (
        selectedFilter !== "Vector" &&
        appState.vectorVal !== "" &&
        selctedValue !== null
      ) {
        if (whereClause === "") {
          whereClause = whereClause + "vector IN(" + appState.vectorVal + ")";
        } else {
          whereClause =
            whereClause + " AND vector IN(" + appState.vectorVal + ")";
        }
      }
      if (
        selectedFilter === "Sector" &&
        selctedValue !== "" &&
        selctedValue !== null
      ) {
        if (whereClause === "") {
          whereClause = whereClause + "product IN(" + selctedValue + ")";
        } else {
          whereClause = whereClause + " AND product IN(" + selctedValue + ")";
        }
      }

      if (
        selectedFilter !== "Sector" &&
        appState.sectorVal !== "" &&
        selctedValue !== null
      ) {
        if (whereClause === "") {
          whereClause = whereClause + "product IN(" + appState.sectorVal + ")";
        } else {
          whereClause =
            whereClause + " AND product IN(" + appState.sectorVal + ")";
        }
      }
      if (
        selectedFilter === "EnergyOutlook" &&
        selctedValue !== "" &&
        selctedValue !== null
      ) {
        if (whereClause === "") {
          whereClause = whereClause + "pathway IN(" + selctedValue + ")";
        } else {
          whereClause = whereClause + " AND pathway IN(" + selctedValue + ")";
        }
      }
      if (
        selectedFilter !== "EnergyOutlook" &&
        appState.energyOutlookVal !== "" &&
        selctedValue !== null
      ) {
        if (whereClause === "") {
          whereClause =
            whereClause + "pathway IN('" + appState.energyOutlookVal + "')";
        } else {
          whereClause =
            whereClause +
            " AND pathway IN('" +
            appState.energyOutlookVal +
            "')";
        }
      }
      if (whereClause !== whereClauseState) {
        setappState((prevState) => ({
          ...prevState,
          homeQuery: whereClause,
        }));
      }
      if (!appState.isAuthenticated) {
        return;
      }
      const plantFeatureLayer = new FeatureLayer({
        url: props.mit_scenario_table_spatial,
      });
      const queryPlantCount = plantFeatureLayer.createQuery();
      queryPlantCount.where = whereClause;
      if (queryPlantCount.where.includes("undefined")) {
        return;
      }
      queryPlantCount.outFields = ["plant_name"];
      queryPlantCount.returnDistinctValues = true;
      queryPlantCount.returnGeometry = false;
      const validparam: any = [
        {
          statisticType: "count",
          onStatisticField: "plant_name",
          outStatisticFieldName: "Total_PlantCount",
        },
      ];
      queryPlantCount.outStatistics = validparam;

      plantFeatureLayer
        .queryFeatures(queryPlantCount)
        .then(function (response) {
          setappState((prevState) => ({
            ...prevState,
            totalPlantCount:
              response.features[0].attributes.Total_PlantCount.toLocaleString(
                "en-US"
              ).replace(/\B(?=(\d{3})+(?!\d))/g, ","),
          }));
        });
    }
  }, [
    appState.sectorVal,
    appState.companyVal,
    appState.energyOutlookVal,
    appState.exploreByVal,
    appState.regionVal,
    appState.vectorVal,
  ]);
  useEffect(() => {
    if (activeTab === "Tab1" && appState.activeLinkMap === "Emissions") {
      if (appState.vectorVal === "") {
        setappState((prevState) => ({
          ...prevState,
          vectorVal: "'Net Emissions'",
        }));
        setoutEmissionValue(vectorOptionEmissions[0]);
      }
      const plantFeatureLayer = new FeatureLayer({
        url: props.mit_scenario_table_spatial,
      });
      let whereClause = "1 = 1";
      if (appState.outputYearVal !== "") {
        whereClause = "year = " + appState.outputYearVal;
      }
      if (appState.energyOutlookVal !== "") {
        whereClause =
          whereClause + " AND pathway = '" + appState.energyOutlookVal + "'";
      }
      if (appState.regionVal !== "") {
        whereClause =
          whereClause + "AND country_name IN(" + appState.regionVal + ")";
      }
      if (appState.companyVal !== "" && whereClause === "1 = 1") {
        whereClause = "plant_front_name IN(" + appState.companyVal + ")";
      }
      if (appState.companyVal !== "" && whereClause !== "1 = 1") {
        whereClause =
          whereClause + " AND plant_front_name IN(" + appState.companyVal + ")";
      }

      if (appState.sectorVal !== "" && whereClause === "1 = 1") {
        whereClause = "product IN(" + appState.sectorVal + ")";
      }
      if (appState.sectorVal !== "" && whereClause !== "1 = 1") {
        whereClause =
          whereClause + " AND product IN(" + appState.sectorVal + ")";
      }
      if (appState.vectorVal !== "" && whereClause !== "1 = 1") {
        whereClause =
          whereClause + " AND vector IN(" + appState.vectorVal + ")";
      }
      const queryPlantCount = plantFeatureLayer.createQuery();
      if (appState.vectorVal === "") {
        queryPlantCount.where = whereClause + " AND vector IN('Net Emissions')";
      } else {
        queryPlantCount.where = whereClause;
      }
      if (queryPlantCount.where.includes("undefined")) {
        return;
      }
      queryPlantCount.outFields = ["plant_name"];
      queryPlantCount.returnDistinctValues = true;
      queryPlantCount.returnGeometry = false;
      const validparam: any = [
        {
          statisticType: "count",
          onStatisticField: "plant_name",
          outStatisticFieldName: "Total_PlantCount",
        },
      ];
      queryPlantCount.outStatistics = validparam;

      plantFeatureLayer
        .queryFeatures(queryPlantCount)
        .then(function (response) {
          setappState((prevState) => ({
            ...prevState,
            totalPlantCount:
              response.features[0].attributes.Total_PlantCount.toLocaleString(
                "en-US"
              ).replace(/\B(?=(\d{3})+(?!\d))/g, ","),
          }));
        });

      // const TotalEnergyFeatureLayer = new FeatureLayer({
      //   url: props.mit_scenario_table_spatial,
      // });
      // const query = TotalEnergyFeatureLayer.createQuery();

      // if (appState.vectorVal === "") {
      //   query.where = whereClause + " AND vector IN('Net Emissions')";
      // } else {
      //   query.where = whereClause;
      // }

      // if (query.where.includes("undefined")) {
      //   return;
      // }

      // query.outFields = ["*"];
      // query.returnDistinctValues = false;
      // query.returnGeometry = false;
      // const validationParamsPlant: any = [
      //   {
      //     statisticType: "sum",
      //     onStatisticField: "total_energy_tj",
      //     outStatisticFieldName: "Total_sum",
      //   },
      // ];
      // query.outStatistics = validationParamsPlant;
      // TotalEnergyFeatureLayer.queryFeatures(query).then(function (response) {
      //   if (response.features) {
      //     const energyTotal = Math.round(
      //       parseInt(response.features[0].attributes.Total_sum)
      //     );
      //     setEnergyUnit("TJ");
      //     setlengthTextTEC(energyTotal.toString().length);
      //     setappState((prevState) => ({
      //       ...prevState,
      //       totalEnergyConsumption: energyTotal
      //         .toLocaleString("en-US")
      //         .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      //     }));
      //   }
      // });
    } else {
      // setappState((prevState) => ({
      //   ...prevState,
      //   vectorVal: "",
      // }));
      const plantFeatureLayer = new FeatureLayer({
        url: props.mit_scenario_table_spatial,
      });
      let whereClause = "1 = 1";
      if (appState.outputYearVal !== "") {
        whereClause = "year = " + appState.outputYearVal;
      }
      if (appState.energyOutlookVal !== "") {
        whereClause =
          whereClause + " AND pathway = '" + appState.energyOutlookVal + "'";
      }
      if (appState.regionVal !== "") {
        whereClause =
          whereClause + "AND country_name IN(" + appState.regionVal + ")";
      }
      if (appState.companyVal !== "" && whereClause === "1 = 1") {
        whereClause = "plant_front_name IN(" + appState.companyVal + ")";
      }
      if (appState.companyVal !== "" && whereClause !== "1 = 1") {
        whereClause =
          whereClause + " AND plant_front_name IN(" + appState.companyVal + ")";
      }

      if (appState.sectorVal !== "" && whereClause === "1 = 1") {
        whereClause = "product IN(" + appState.sectorVal + ")";
      }
      if (appState.sectorVal !== "" && whereClause !== "1 = 1") {
        whereClause =
          whereClause + " AND product IN(" + appState.sectorVal + ")";
      }
      if (appState.vectorVal !== "" && whereClause !== "1 = 1") {
        whereClause =
          whereClause + " AND vector IN(" + appState.vectorVal + ")";
      }
      const queryPlantCount = plantFeatureLayer.createQuery();
      queryPlantCount.where = whereClause;

      if (queryPlantCount.where.includes("undefined")) {
        return;
      }
      queryPlantCount.outFields = ["plant_name"];
      queryPlantCount.returnDistinctValues = true;
      queryPlantCount.returnGeometry = false;
      const validparam: any = [
        {
          statisticType: "count",
          onStatisticField: "plant_name",
          outStatisticFieldName: "Total_PlantCount",
        },
      ];
      queryPlantCount.outStatistics = validparam;

      plantFeatureLayer
        .queryFeatures(queryPlantCount)
        .then(function (response) {
          setappState((prevState) => ({
            ...prevState,
            totalPlantCount:
              response.features[0].attributes.Total_PlantCount.toLocaleString(
                "en-US"
              ).replace(/\B(?=(\d{3})+(?!\d))/g, ","),
          }));
        });
      const TotalEnergyFeatureLayer = new FeatureLayer({
        url: props.mit_scenario_table_spatial,
      });
      const query = TotalEnergyFeatureLayer.createQuery();

      query.where = whereClause;

      if (query.where.includes("undefined")) {
        return;
      }

      query.outFields = ["*"];
      query.returnDistinctValues = false;
      query.returnGeometry = false;
      const validationParamsPlant: any = [
        {
          statisticType: "sum",
          onStatisticField: "total_energy_tj",
          outStatisticFieldName: "Total_sum",
        },
      ];
      query.outStatistics = validationParamsPlant;
      TotalEnergyFeatureLayer.queryFeatures(query).then(function (response) {
        if (response.features) {
          const energyTotal = Math.round(
            parseInt(response.features[0].attributes.Total_sum)
          );
          setEnergyUnit("TJ");
          setlengthTextTEC(energyTotal.toString().length);
          setappState((prevState) => ({
            ...prevState,
            totalEnergyConsumption: energyTotal
              .toLocaleString("en-US")
              .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
          }));
        }
      });
    }
  }, [appState.activeLinkMap, activeTab, appState.vectorVal]);
  return (
    <div className="-HomeDiv">
      <ConfidentialityPopup></ConfidentialityPopup>
      <div className="filtersDiv">
        <span className="h4font-weight-light">Filter and search</span>
        <br />
        <span className="pleadfont-weight-light">
          Explore the MIT data using the filter options below.
        </span>
        <span className="pleadfont-weight-Note">
          Output years beyond 2024 are only covering the Steel, Cement and
          Chemicals sectors at this time.
        </span>
        <div className="Rectangle-home"></div>
        <label className="labOutputYear">Output year...</label>
        <Select
          className="labEnergyOutDrop basic-multi-select"
          options={yearoption}
          placeholder="Select Output Year .."
          isClearable={false}
          isSearchable={false}
          onChange={(selected) => _onSelectOutYear(selected)}
          hideSelectedOptions={false}
          closeMenuOnSelect={true}
          components={ReactSelectOverrides.default}
          classNamePrefix="react-select"
          value={outYearValue}
        />

        <label className="labAreaSel">Region/Country</label>
        <Select
          isMulti
          className="labSelAreadrop basic-multi-select"
          options={sectorRegionoption}
          placeholder="Search or Select ..."
          isClearable={true}
          isSearchable={true}
          onChange={(selected) => regionSelectedbyUser(selected)}
          hideSelectedOptions={false}
          closeMenuOnSelect={false}
          value={valueArea}
          components={ReactSelectOverrides.default}
          classNamePrefix="react-select"
        />
        <label className="labCompanyName">Company</label>
        <Select
          isMulti
          className="labCompanyNamedrop basic-multi-select"
          options={sectorCompanyoption}
          placeholder="Search or Select ..."
          isClearable={true}
          isSearchable={true}
          onChange={(selected) => companySelectedbyUser(selected)}
          onInputChange={(inputVal) => CompanyEnteredValuebyUser(inputVal)}
          hideSelectedOptions={false}
          closeMenuOnSelect={false}
          value={valuePlant}
          components={ReactSelectOverrides.default}
          classNamePrefix="react-select"
        />

        <label className="labVectorName">Energy Vector</label>
        {activeTab === "Tab1" && appState.activeLinkMap === "Emissions" ? (
          <Select
            className="labVectorNamedrop basic-multi-select"
            options={vectorOptionEmissions}
            placeholder="Search or Select ..."
            isClearable={false}
            isSearchable={false}
            onChange={(selected) => vectorSelectedbyUserEmission(selected)}
            hideSelectedOptions={false}
            closeMenuOnSelect={true}
            components={ReactSelectOverrides.default}
            classNamePrefix="react-select"
            value={outEmissionValue}
          />
        ) : (
          <Select
            isMulti
            className="labVectorNamedrop basic-multi-select"
            options={vectoroption}
            placeholder="Search or Select ..."
            isClearable={true}
            isSearchable={true}
            onChange={(selected) => vectorSelectedbyUser(selected)}
            hideSelectedOptions={false}
            closeMenuOnSelect={false}
            components={ReactSelectOverrides.default}
            classNamePrefix="react-select"
            value={outVectorValue}
          />
        )}
        <label className="labSector">Sector</label>
        <Select
          isMulti
          className="labSectordrop basic-multi-select"
          options={optionsSector}
          placeholder="Search Sector ..."
          isClearable={true}
          isSearchable={true}
          onChange={(selected) => sectorSelectedbyUser(selected)}
          hideSelectedOptions={false}
          closeMenuOnSelect={false}
          components={ReactSelectOverrides.default}
          classNamePrefix="react-select"
          value={valueSector}
        />
        <label className="labEnergyOut">Energy outlook scenarios...</label>
        <Select
          className="labEnergyOutDrop basic-multi-select"
          options={optionsEnergyOutput}
          placeholder="Select Energy Outlook .."
          isClearable={false}
          isSearchable={false}
          onChange={(selected) => _onSelectEnergyOut(selected)}
          hideSelectedOptions={false}
          closeMenuOnSelect={false}
          components={ReactSelectOverrides.default}
          classNamePrefix="react-select"
          value={outEOValue}
        />
        <div className="buttoncontainer">
          {/* <div className="saveBtnContainer">
            <img src={savePng} className="save-png" />
            <span className="save-btn-text">Save view</span>
          </div> */}
          <div className="clearBtnContainer" onClick={handleClearFilters}>
            <img src={reloadPng} className="reload-16-png" />
            <span className="clear-btn-text">Clear Filters</span>
          </div>
        </div>
        {/* <div className="Rectangle-home"></div>
        <label className="labVectorName">Load saved view</label>
        <Select
          className="labEnergyOutDrop basic-multi-select"
          placeholder="load Saved View ..."
          isClearable={false}
          isSearchable={false}
          hideSelectedOptions={false}
          closeMenuOnSelect={false}
          components={ReactSelectOverrides.default}
          classNamePrefix="react-select"
        /> */}
      </div>
      <div className="tabsHome">
        <div
          className=""
          style={{
            width: "100%",
            backgroundColor: "transparent",
            marginTop: "35px",
          }}
        >
          <Nav className="nav-tabs nav-tabs-set">
            <NavItem className="navItemCustom">
              <NavLink
                className={classnames({ active: activeTab === "Tab1" })}
                href="#"
                onClick={() => {
                  toggleActiveTab("Tab1");
                }}
              >
                Map
              </NavLink>
            </NavItem>
            <NavItem className="navItemCustom">
              <NavLink
                className={classnames({ active: activeTab === "Tab2" })}
                href="#"
                onClick={() => {
                  toggleActiveTab("Tab2");
                }}
              >
                Chart
              </NavLink>
            </NavItem>
            <NavItem className="navItemCustom">
              <NavLink
                className={classnames({ active: activeTab === "Tab3" })}
                href="#"
                onClick={() => {
                  toggleActiveTab("Tab3");
                }}
              >
                Tables
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab} className="bg-secondary">
            <TabPane tabId="Tab1">
              <div className="large">
                <div
                  style={{ display: activeTab === "Tab1" ? "block" : "none" }}
                >
                  <MapTab {...envConstants} />
                </div>
                {/* {activeTab == "Tab1" ? <MapTab {...envConstants} /> : <MapTab {...envConstants} />} */}
              </div>
            </TabPane>
            <TabPane tabId="Tab2">
              <div className="large">
                <div
                  style={{ display: activeTab === "Tab2" ? "block" : "none" }}
                >
                  <ChartTab {...envConstants} />
                </div>
                {/* {activeTab == "Tab2" ? <ChartTab {...envConstants} /> : null} */}
              </div>
            </TabPane>
            <TabPane tabId="Tab3">
              <div className="large">
                {/* <div
                  style={{ display: activeTab === "Tab3" ? "block" : "none" }}
                >
                  <TablesTab {...envConstants} />
                </div> */}
                {activeTab == "Tab3" ? <TablesTab {...envConstants} /> : null}
              </div>
            </TabPane>
          </TabContent>
          {/* <div className="buttoncontainer"> */}
          <div className="card-group">
            {/* <div className="card"> */}
            <div className="card">
              <div
                // className="tecValue"
                style={{ display: activeTab === "Tab1" ? "block" : "none" }}
              >
                {/* <span className="h4font-weight-light-css">
                Energy consumption
              </span>
              {lengthTextTEC > 4 ? (
                <span className="PJ-text" style={{ fontSize: 35 }}>
                  {appState.totalEnergyConsumption}
                </span>
              ) : (
                <span className="PJ-text">
                  {appState.totalEnergyConsumption}
                </span>
              )}
              <span className="PJ">{EnergyUnit}</span> */}
                <Card border theme="light">
                  <Card.Body>
                    <Card.Title>
                      <span className="h4font-weight-light-css">
                        Energy consumption
                      </span>
                    </Card.Title>
                    <Card.Text>
                      {lengthTextTEC > 4 ? (
                        <span className="PJ-text" style={{ fontSize: 35 }}>
                          {appState.activeLinkMap === "Emissions" ? (
                            <span className="PJ-text" style={{ fontSize: 35 }}>
                              NA
                            </span>
                          ) : (
                            appState.totalEnergyConsumption
                          )}
                        </span>
                      ) : (
                        <span className="PJ-text">
                          {appState.activeLinkMap === "Emissions" ? (
                            <span className="PJ-text" style={{ fontSize: 35 }}>
                              NA
                            </span>
                          ) : (
                            appState.totalEnergyConsumption
                          )}
                        </span>
                      )}
                      {appState.activeLinkMap === "Emissions" ? null : (
                        <span className="PJ">{EnergyUnit}</span>
                      )}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </div>
            </div>
            <div className="card">
              <div
                // className="tceValue"
                style={{ display: activeTab === "Tab1" ? "block" : "none" }}
              >
                {/* <span
                className="h4font-weight-light-css-tce"
                style={{ fontSize: "22px" }}
              >
                Net emissions
              </span>
              <span className="PJ-text-tce">{appState.totalemissions}</span>
              <span className="million-tons">Mt CO2e</span> */}
                <Card border theme="light">
                  <Card.Body>
                    <Card.Title>
                      <span
                        className="h4font-weight-light-css-tce"
                        style={{ fontSize: "20px" }}
                      >
                        Net emissions
                      </span>
                    </Card.Title>
                    <Card.Text>
                      <span className="PJ-text-tce">
                        {appState.activeLinkMap === "Emissions" &&
                        appState.vectorVal === "'Captured Emissions'"
                          ? "NA"
                          : appState.totalemissions}
                      </span>
                      {appState.activeLinkMap === "Emissions" &&
                      appState.vectorVal === "'Captured Emissions'" ? (
                        ""
                      ) : (
                        <span className="million-tons">Mt CO2e</span>
                      )}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </div>
            </div>
            <div className="card">
              <div
                // className="nopValue"
                style={{ display: activeTab === "Tab1" ? "block" : "none" }}
              >
                <Card border theme="light">
                  <Card.Body>
                    <Card.Title>
                      <span className="h4font-weight-light-css-nop">
                        Number of sites
                      </span>
                    </Card.Title>
                    <Card.Text>
                      <span className="PJ-text-nop">
                        {appState.totalPlantCount}
                      </span>
                    </Card.Text>
                    {/* <span className="k">k</span> */}
                  </Card.Body>
                </Card>
              </div>
            </div>
            {/* </div> */}
          </div>
        </div>
      </div>
      {/* <div className="buttoncontainer">
        <div className="tecValue"></div>
        <div className="tceValue"></div>
        <div className="nopValue"></div>
      </div> */}
    </div>
  );
};

export default Home;
