import React, { useEffect, useMemo, useState } from "react";
import "./Decarb.css";
import Select from "react-select";
import savePng from "../../Assets/images/save.png";
import reloadPng from "../../Assets/images/reload-16.png";
import exportPng from "../../Assets/images/export.png";
import { Slider, ReactSelectOverrides } from "@bphxd/ds-core-react";
import { useGlobalState } from "../GlobalContext/GlobalContext";
import {
  MantineReactTable,
  type MRT_RowSelectionState,
} from "mantine-react-table";
import { Box, Button } from "@mantine/core";
import { IconDownload } from "@tabler/icons-react";
import { mkConfig, generateCsv, download } from "export-to-csv"; //or use your library of choice here
import { envComponentProps } from "../../Constants/envConstants.types";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";

const Decarb = (props: envComponentProps) => {
  const { appState, setappState } = useGlobalState();
  const [selectedPlants, setselectedPlants] = useState<any>([]);
  const [sectorRegionoption, setsectorRegionoption] = useState<any>([]);
  const [sectorCompanyoption, setsectorCompanyoption] = useState<any>([]);
  const [sectorPlantoption, setsectorPlantoption] = useState<any>([]);
  const [whereClauseState, setwhereClauseState] = useState<any>("1=1");
  const [valueArea, setvalueArea] = useState<any>([]);
  const [valueSector, setvalueSector] = useState<any>([]);
  const [valueCompany, setvalueCompany] = useState<any>([]);
  const [valuePlant, setvaluePlant] = useState<any>([]);
  const [loadingTable, setloadingTable] = useState(true);
  const [resetTool, setresetTool] = useState(true);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [updateTotalSelection, setupdateTotalSelection] = useState(false);
  const [totalPlantCount, settotalPlantCount] = useState("");
  const [totalPlantCapacityTotal, settotalPlantCapacityTotal] = useState("");
  const [totalTableData, settotalTableData] = useState<any>([]);
  const [allData, setAllData] = useState<any>([]);
  const resultSectorCompanyOptions: {
    label: any;
    value: any;
    groupkey: any;
  }[] = [];
  const resultSectorPlantOptions: {
    label: any;
    value: any;
    groupkey: any;
  }[] = [];
  const resultSectorOptions: {
    label: any;
    value: any;
    groupkey: any;
  }[] = [];

  const resultSectorAreaOptionsGrouped: { label: any; options: any }[] = [];
  const resultSectorOptionsGrouped: { label: any; options: any }[] = [];
  const resultSectorAreaOptions: { label: any; value: any; groupkey: any }[] =
    [];
  const optionsArea: { label: any; options: any }[] = [];
  const optionsSectorGroup: { label: any; options: any }[] = [];
  const [optionsSector, setoptionsSector] = useState<any>([]);
  let PlantData: any = [];
  const handleClearFilters = () => {
    setappState((prevState) => ({
      ...prevState,
      decarbCompany: "",
      decarbRegion: "",
      decarbPlantName: "",
      decarbSector: "",
      decarbQuery: "1=1",
    }));
    setvaluePlant([]);
    setvalueCompany([]);
    setvalueArea([]);
    setvalueSector([]);
    settotalPlantCount("0");
    settotalPlantCapacityTotal("0");
    settotalTableData([]);
    setupdateTotalSelection(true);
    setRowSelection({});
  };

  const columnsfield = [
    { header: "Pathway", accessorKey: "pathway" },
    { header: "Biomass (kt)", accessorKey: "Vector_Biomass" },
    { header: "Biomethane (mmbtu)", accessorKey: "Vector_Biomethane" },
    { header: "Coal (kt)", accessorKey: "Vector_Coal" },
    { header: "Electricity (MWh)", accessorKey: "Vector_Electricity" },
    { header: "Heat (MWh)", accessorKey: "Vector_Heat" },
    { header: "Hydrogen (Kt)", accessorKey: "Vector_Hydrogen" },
    {
      header: "Natural Gas (mmbtu)",
      accessorKey: "Vector_Natural_Gas",
    },
    { header: "Oil (bbl)", accessorKey: "Vector_Oil" },
    { header: "Waste (kt)", accessorKey: "Vector_Waste" },
    { header: "Biofeedstock (kt)", accessorKey: "Vector_Biofeedstock" },
    { header: "Grey Hydrogen_fs (kt)", accessorKey: "Vector_Grey_Hydrogen_fs" },
    { header: "Hydrogen_fs (kt)", accessorKey: "Vector_Hydrogen_fs" },
    { header: "Oil_fs (bbl)", accessorKey: "Vector_Oil_fs" },
    { header: "Net_Emissions (Mt)", accessorKey: "Vector_Net_Emissions" },
    {
      header: "Captured Emissions (Mt)",
      accessorKey: "Vector_Captured_Emissions",
    },
  ];
  const columnsfieldTotal = [
    { header: "Biomass (kt)", accessorKey: "Vector_Biomass" },
    { header: "Biomethane (mmbtu)", accessorKey: "Vector_Biomethane" },
    { header: "Coal (kt)", accessorKey: "Vector_Coal" },
    { header: "Electricity (MWh)", accessorKey: "Vector_Electricity" },
    { header: "Heat (MWh)", accessorKey: "Vector_Heat" },
    { header: "Hydrogen (kt)", accessorKey: "Vector_Hydrogen" },
    {
      header: "Natural Gas (mmbtu)",
      accessorKey: "Vector_Natural_Gas",
    },
    { header: "Oil (bbl)", accessorKey: "Vector_Oil" },
    { header: "Waste (kt)", accessorKey: "Vector_Waste" },
    { header: "Biofeedstock (kt)", accessorKey: "Vector_Biofeedstock" },
    { header: "Grey Hydrogen_fs (kt)", accessorKey: "Vector_Grey_Hydrogen_fs" },
    { header: "Hydrogen_fs (kt)", accessorKey: "Vector_Hydrogen_fs" },
    { header: "Oil_fs (bbl)", accessorKey: "Vector_Oil_fs" },
    { header: "Net_Emissions (Mt)", accessorKey: "Vector_Net_Emissions" },
    {
      header: "Captured Emissions (Mt)",
      accessorKey: "Vector_Captured_Emissions",
    },
  ];
  const columnsfieldCSV = [
    "countryPlant",
    "PlantName",
    "capacity",
    "pathway",
    "plantPathway",
    "SectorPlant",
    "Vector_Biomass_kt",
    "Vector_Biomethane_mmbtu",
    "Vector_Coal_kt",
    "Vector_Electricity_Mwh",
    "Vector_Heat_Mwh",
    "Vector_Hydrogen_kt",
    "Vector_Natural_Gas_mmbtu",
    "Vector_Oil_bbl",
    "Vector_Waste_kt",
    "Vector_Biofeedstock_kt",
    "Vector_Grey_Hydrogen_fs_kt",
    "Vector_Hydrogen_fs_kt",
    "Vector_Oil_fs_bbl",
    "Vector_Net_Emissions_Mt",
    "Vector_Captured_Emissions_Mt",
  ];
  const csvConfig = mkConfig({
    fieldSeparator: ",",
    decimalSeparator: ".",
    columnHeaders: columnsfieldCSV,
    useKeysAsHeaders: true,
    filename: "MIT_Decarb_" + new Date().toString(),
  });
  useEffect(() => {
    const selectedRows = Object.keys(rowSelection);
    const PlantNames: any = [];
    let TotalCapacity = 0;
    let DataNestedforTotalSum: any = [];
    let selectedDataRows: any = [];
    if (selectedRows.length > 0) {      
      selectedRows.forEach((item) => {
        selectedPlants.forEach((element: any) => {
          element.forEach((item2: any) => {
            if (item === item2.plantPathway) {
              selectedDataRows.push(item2);
            }
          });
        });
      });
      selectedDataRows = JSON.stringify(selectedDataRows);
      selectedDataRows = selectedDataRows.replace(/(?=,(?!"))(,(?!{))/g,"");
      selectedDataRows = JSON.parse(selectedDataRows);
      selectedDataRows.forEach((item: any) => {
        if (PlantNames.indexOf(item.PlantName.toString().replace(",", "") + item.SectorPlant) === -1) {
          PlantNames.push(item.PlantName.toString().replace(",", "") + item.SectorPlant);
          TotalCapacity =
            TotalCapacity + parseInt(item.capacity.toString().replace(",", ""));
        }
      });
      if (selectedDataRows.length > 0) {
        DataNestedforTotalSum = selectedDataRows.reduce(function (
          previousValue: any,
          currentValue: any
        ) {
          return {
            Vector_Biomass: (
              parseFloat(previousValue.Vector_Biomass.replace(",", "")) +
              parseFloat(currentValue.Vector_Biomass.replace(",", ""))
            ).toFixed(1),
            Vector_Biomethane: (
              parseFloat(previousValue.Vector_Biomethane.replace(",", "")) +
              parseFloat(currentValue.Vector_Biomethane.replace(",", ""))
            ).toFixed(1),
            Vector_Captured_Emissions: (
              parseFloat(
                previousValue.Vector_Captured_Emissions.replace(",", "")
              ) +
              parseFloat(
                currentValue.Vector_Captured_Emissions.replace(",", "")
              )
            ).toFixed(1),
            Vector_Coal: (
              parseFloat(previousValue.Vector_Coal.replace(",", "")) +
              parseFloat(currentValue.Vector_Coal.replace(",", ""))
            ).toFixed(1),
            Vector_Electricity: (
              parseFloat(previousValue.Vector_Electricity.replace(",", "")) +
              parseFloat(currentValue.Vector_Electricity.replace(",", ""))
            ).toFixed(1),
            Vector_Heat: (
              parseFloat(previousValue.Vector_Heat.replace(",", "")) +
              parseFloat(currentValue.Vector_Heat.replace(",", ""))
            ).toFixed(1),
            Vector_Hydrogen: (
              parseFloat(previousValue.Vector_Hydrogen.replace(",", "")) +
              parseFloat(currentValue.Vector_Hydrogen.replace(",", ""))
            ).toFixed(1),
            Vector_Natural_Gas: (
              parseFloat(previousValue.Vector_Natural_Gas.replace(",", "")) +
              parseFloat(currentValue.Vector_Natural_Gas.replace(",", ""))
            ).toFixed(1),
            Vector_Oil: (
              parseFloat(previousValue.Vector_Oil.replace(",", "")) +
              parseFloat(currentValue.Vector_Oil.replace(",", ""))
            ).toFixed(1),
            Vector_Net_Emissions: (
              parseFloat(previousValue.Vector_Net_Emissions.replace(",", "")) +
              parseFloat(currentValue.Vector_Net_Emissions.replace(",", ""))
            ).toFixed(1),
            Vector_Waste: (
              parseFloat(previousValue.Vector_Waste.replace(",", "")) +
              parseFloat(currentValue.Vector_Waste.replace(",", ""))
            ).toFixed(1),
            Vector_Biofeedstock: (
              parseFloat(previousValue.Vector_Biofeedstock.replace(",", "")) +
              parseFloat(currentValue.Vector_Biofeedstock.replace(",", ""))
            ).toFixed(1),
            Vector_Grey_Hydrogen_fs: (
              parseFloat(
                previousValue.Vector_Grey_Hydrogen_fs.replace(",", "")
              ) +
              parseFloat(currentValue.Vector_Grey_Hydrogen_fs.replace(",", ""))
            ).toFixed(1),
            Vector_Hydrogen_fs: (
              parseFloat(previousValue.Vector_Hydrogen_fs.replace(",", "")) +
              parseFloat(currentValue.Vector_Hydrogen_fs.replace(",", ""))
            ).toFixed(1),
            Vector_Oil_fs: (
              parseFloat(previousValue.Vector_Oil_fs.replace(",", "")) +
              parseFloat(currentValue.Vector_Oil_fs.replace(",", ""))
            ).toFixed(1),
          };
        });
      }
    }
    settotalPlantCount(PlantNames.length);
    settotalPlantCapacityTotal(
      TotalCapacity.toLocaleString("en-US").replace(
        /\B(?=(\d{3})+(?!\d))/g,
        ","
      )
    );
    Object.entries(DataNestedforTotalSum).forEach(([key, value]: any) => {
      DataNestedforTotalSum[key] = value
        .toLocaleString("en-US")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ","); // Convert string values to uppercase
    });
    settotalTableData([DataNestedforTotalSum]);
    setupdateTotalSelection(false);
  }, [rowSelection]);
  const handleExportData = (event: any) => {
    let i;
    for (i = 0; i < allData.length; i++) {
      allData[i].Vector_Biomass_kt = allData[i]["Vector_Biomass"];
      allData[i].Vector_Biomethane_mmbtu = allData[i]["Vector_Biomethane"];
      allData[i].Vector_Coal_kt = allData[i]["Vector_Coal"];
      allData[i].Vector_Electricity_Mwh = allData[i]["Vector_Electricity"];
      allData[i].Vector_Heat_Mwh = allData[i]["Vector_Heat"];
      allData[i].Vector_Hydrogen_kt = allData[i]["Vector_Hydrogen"];
      allData[i].Vector_Natural_Gas_mmbtu = allData[i]["Vector_Natural_Gas"];
      allData[i].Vector_Oil_bbl = allData[i]["Vector_Oil"];
      allData[i].Vector_Waste_kt = allData[i]["Vector_Waste"];
      allData[i].Vector_Biofeedstock_kt = allData[i]["Vector_Biofeedstock"];
      allData[i].Vector_Grey_Hydrogen_fs_kt =
        allData[i]["Vector_Grey_Hydrogen_fs"];
      allData[i].Vector_Hydrogen_fs_kt = allData[i]["Vector_Hydrogen_fs"];
      allData[i].Vector_Oil_fs_bbl = allData[i]["Vector_Oil_fs"];
      allData[i].Vector_Net_Emissions_Mt = allData[i]["Vector_Net_Emissions"];
      allData[i].Vector_Captured_Emissions_Mt =
        allData[i]["Vector_Captured_Emissions"];
      delete allData[i].Vector_Biomass;
      delete allData[i].Vector_Biomethane;
      delete allData[i].Vector_Coal;
      delete allData[i].Vector_Electricity;
      delete allData[i].Vector_Heat;
      delete allData[i].Vector_Hydrogen;
      delete allData[i].Vector_Natural_Gas;
      delete allData[i].Vector_Oil;
      delete allData[i].Vector_Waste;
      delete allData[i].Vector_Biofeedstock;
      delete allData[i].Vector_Grey_Hydrogen_fs;
      delete allData[i].Vector_Hydrogen_fs;
      delete allData[i].Vector_Oil_fs;
      delete allData[i].Vector_Net_Emissions;
      delete allData[i].Vector_Captured_Emissions;
    }
    if (Object.keys(rowSelection).length === 0) {
      if (allData.length > 0) {
        const csv = generateCsv(csvConfig)(allData);
        download(csvConfig)(csv);
      }
    } else {
      if (allData.length > 0) {
        const SelectedData: any = [];
        const selarr = Object.keys(rowSelection);
        selarr.forEach((item) => {
          allData.forEach((element: any) => {
            if (item === element.plantPathway) {
              SelectedData.push(element);
            }
          });
        });
        const csv = generateCsv(csvConfig)(SelectedData);
        download(csvConfig)(csv);
      }
    }
  };
  const regionSelectedbyUser = (event: any) => {
    if (event !== null && event !== undefined) {
      setvalueArea(event);
      let eventValues = "";
      if (event.length > 0) {
        for (let i = 0; i < event.length; i++) {
          if (eventValues === "") {
            eventValues = "'" + event[i].label + "'";
          } else {
            eventValues = eventValues + ",'" + event[i].label + "'";
          }
        }
      }
      setappState((prevState) => ({
        ...prevState,
        decarbRegion: eventValues,
      }));
    } else {
      setappState((prevState) => ({
        ...prevState,
        decarbRegion: "",
      }));
    }
  };

  const _onSelectSector = (event: any) => {
    if (event !== null && event !== undefined) {
      setvalueSector(event);
      let eventValues = "";
      if (event.length > 0) {
        for (let i = 0; i < event.length; i++) {
          if (eventValues === "") {
            eventValues = "'" + event[i].label + "'";
          } else {
            eventValues = eventValues + ",'" + event[i].label + "'";
          }
        }
      }
      setappState((prevState) => ({
        ...prevState,
        decarbSector: eventValues,
      }));
    } else {
      setappState((prevState) => ({
        ...prevState,
        decarbSector: "",
      }));
    }
  };
  const plantSelectedbyUser = (event: any) => {
    setvaluePlant(event);
    if (event !== null && event !== undefined) {
      let eventValues = "";
      if (event.length > 0) {
        for (let i = 0; i < event.length; i++) {
          if (eventValues === "") {
            eventValues = "'" + event[i].label + "'";
          } else {
            eventValues = eventValues + ",'" + event[i].label + "'";
          }
        }
      }
      setappState((prevState) => ({
        ...prevState,
        decarbPlantName: eventValues,
      }));
    } else {
      setappState((prevState) => ({
        ...prevState,
        decarbPlantName: "",
      }));
      setRowSelection({});
    }

    setupdateTotalSelection(true);
    const selectedRows = Object.keys(rowSelection);
    const selctedPlants: any = [];
    const eventplants: any = [];
    selectedRows.forEach((item) => {
      selctedPlants.push(item.split("--")[1]);
    });
    event.forEach((item: any) => {
      eventplants.push(item.value);
    });
    let commonElementsArray: any = [];
    const finalElementsRowSelection = {};
    if (event.length > 0) {
      if (selctedPlants.length > 0) {
        commonElementsArray = selctedPlants.filter((element: any) =>
          eventplants.includes(element)
        );

        for (const [key, value] of Object.entries(rowSelection)) {
          commonElementsArray.forEach((element: any) => {
            if (key.includes(element)) {
              Object.assign(finalElementsRowSelection, { [key]: true });
            }
          });
        }
        setRowSelection(finalElementsRowSelection);
      }
    } else {
      setRowSelection({});
    }
  };
  const companySelectedbyUser = (event: any) => {
    setvalueCompany(event);
    if (event !== null && event !== undefined) {
      let eventValues = "";
      if (event.length > 0) {
        for (let i = 0; i < event.length; i++) {
          if (eventValues === "") {
            eventValues = "'" + event[i].label + "'";
          } else {
            eventValues = eventValues + ",'" + event[i].label + "'";
          }
        }
      }
      setappState((prevState) => ({
        ...prevState,
        decarbCompany: eventValues,
      }));
    } else {
      setappState((prevState) => ({
        ...prevState,
        decarbCompany: "",
      }));
    }
  };
  const CompanyEnteredValuebyUser = (inputVal: any) => {
    if (inputVal.length < 2) {
      return;
    }
    const plantFeatureLayer = new FeatureLayer({
      url: props.mit_sceanrio_decarb_table,
    });
    const queryPlantName = plantFeatureLayer.createQuery();
    queryPlantName.where = "parent_company like '%" + inputVal + "%'";
    queryPlantName.outFields = ["parent_company"];
    queryPlantName.returnDistinctValues = true;
    queryPlantName.returnGeometry = false;
    queryPlantName.orderByFields = ["parent_company ASC"];

    plantFeatureLayer.queryFeatures(queryPlantName).then(function (response) {
      if (response.features) {
        response.features.forEach((item) => {
          resultSectorCompanyOptions.push({
            label: item.attributes.parent_company.replace(/"/g, "").trim(),
            value: item.attributes.parent_company.replace(/"/g, "").trim(),
            groupkey: item.attributes.parent_company.replace(/"/g, "").trim(),
          });
        });

        setsectorCompanyoption(resultSectorCompanyOptions);
      }
    });
    const queryPlantNameComp = plantFeatureLayer.createQuery();
    queryPlantNameComp.where = whereClauseState;
    queryPlantNameComp.outFields = ["plant_front_name"];
    queryPlantNameComp.returnDistinctValues = true;
    queryPlantNameComp.returnGeometry = false;
    queryPlantNameComp.orderByFields = ["plant_front_name ASC"];

    plantFeatureLayer
      .queryFeatures(queryPlantNameComp)
      .then(function (response) {
        if (response.features) {
          response.features.forEach((item) => {
            resultSectorPlantOptions.push({
              label: item.attributes.plant_front_name.replace(/"/g, "").trim(),
              value: item.attributes.plant_front_name.replace(/"/g, "").trim(),
              groupkey: item.attributes.plant_front_name
                .replace(/"/g, "")
                .trim(),
            });
          });

          setsectorPlantoption(resultSectorPlantOptions);
        }
      });
    const queryPlantCount = plantFeatureLayer.createQuery();
    queryPlantCount.where =
      "plant_front_name like '%" +
      inputVal +
      "%' OR parent_company like '%" +
      inputVal +
      "%'";
    queryPlantCount.outFields = ["plant_name"];
    queryPlantCount.returnDistinctValues = true;
    queryPlantCount.returnGeometry = false;
  };
  const createGroupSector = (
    groupName: any,
    options: any,
    setvalueSector: any
  ) => {
    return {
      label: (() => {
        return (
          <div
            className="companyGroup"
            onClick={() => {
              setvalueSector((value: any) =>
                value.concat(
                  options.filter((grpOpt: any) => !value.includes(grpOpt))
                )
              );
            }}
          >
            {groupName.trim()}
          </div>
        );
      })(),
      options: options,
    };
  };

  useEffect(() => {
    if (appState.isAuthenticated) {
      const RegionFeatureLayer = new FeatureLayer({
        url: props.mit_sceanrio_decarb_table,
      });
      const queryRegion = RegionFeatureLayer.createQuery();
      queryRegion.where = "1 = 1";
      queryRegion.outFields = ["onemap_region,country_name"];
      queryRegion.returnDistinctValues = true;
      queryRegion.returnGeometry = false;
      queryRegion.orderByFields = ["onemap_region ASC"];

      RegionFeatureLayer.queryFeatures(queryRegion).then(function (response) {
        if (response.features) {
          let count = 0;
          response.features.forEach((item) => {
            const status = resultSectorAreaOptions.some(function (e) {
              return e.groupkey === item.attributes.onemap_region;
            });

            if (!status) {
              count++;
            }
            resultSectorAreaOptions.push({
              label: item.attributes.country_name,
              value: count + "|" + item.attributes.country_name,
              groupkey: item.attributes.onemap_region,
            });
          });
          const groupedArea = resultSectorAreaOptions.reduce(function (r, a) {
            r[a.groupkey] = r[a.groupkey] || [];
            r[a.groupkey].push(a);
            return r;
          }, Object.create(null));
          Object.keys(groupedArea).forEach((key) => {
            resultSectorAreaOptionsGrouped.push({
              label: key,
              options: groupedArea[key],
            });
            optionsArea.push(createGroup(key, groupedArea[key], setvalueArea));
          });

          setsectorRegionoption(optionsArea);
        }
      });
      const plantFeatureLayer = new FeatureLayer({
        url: props.mit_sceanrio_decarb_table,
      });
      let queryDecarbCompany = "";
      if (appState.decarbSector !== "") {
        queryDecarbCompany = " product IN(" + appState.decarbSector + ") ";
      }
      if (appState.decarbRegion !== "" && queryDecarbCompany !== "") {
        queryDecarbCompany =
          queryDecarbCompany +
          " AND country_name IN(" +
          appState.decarbRegion +
          ") ";
      }
      if (appState.decarbRegion !== "" && queryDecarbCompany === "") {
        queryDecarbCompany = " country_name IN(" + appState.decarbRegion + ") ";
      }

      const queryPlantName = plantFeatureLayer.createQuery();
      queryPlantName.where = queryDecarbCompany;
      queryPlantName.outFields = ["parent_company"];
      queryPlantName.returnDistinctValues = true;
      queryPlantName.returnGeometry = false;
      queryPlantName.orderByFields = ["parent_company ASC"];

      plantFeatureLayer.queryFeatures(queryPlantName).then(function (response) {
        if (response.features) {
          response.features.forEach((item) => {
            resultSectorCompanyOptions.push({
              label: item.attributes.parent_company.replace(/"/g, "").trim(),
              value: item.attributes.parent_company.replace(/"/g, "").trim(),
              groupkey: item.attributes.parent_company.replace(/"/g, "").trim(),
            });
          });

          setsectorCompanyoption(resultSectorCompanyOptions);
        }
      });
      let queryDecarbPlant = "";
      if (appState.decarbSector !== "") {
        queryDecarbPlant = " product IN(" + appState.decarbSector + ") ";
      }
      if (appState.decarbRegion !== "" && queryDecarbPlant !== "") {
        queryDecarbPlant =
          queryDecarbPlant +
          " AND country_name IN(" +
          appState.decarbRegion +
          ") ";
      }
      if (appState.decarbRegion !== "" && queryDecarbPlant === "") {
        queryDecarbPlant = " country_name IN(" + appState.decarbRegion + ") ";
      }
      if (appState.decarbCompany !== "" && queryDecarbPlant !== "") {
        queryDecarbPlant =
          queryDecarbPlant +
          " AND parent_company IN(" +
          appState.decarbCompany +
          ") ";
      }
      if (appState.decarbCompany !== "" && queryDecarbPlant === "") {
        queryDecarbPlant =
          " parent_company IN(" + appState.decarbCompany + ") ";
      }
      const queryPlantNameComp = plantFeatureLayer.createQuery();
      queryPlantNameComp.where = queryDecarbPlant;
      queryPlantNameComp.outFields = ["plant_front_name"];
      queryPlantNameComp.returnDistinctValues = true;
      queryPlantNameComp.returnGeometry = false;
      queryPlantNameComp.orderByFields = ["plant_front_name ASC"];

      plantFeatureLayer
        .queryFeatures(queryPlantNameComp)
        .then(function (response) {
          if (response.features) {
            response.features.forEach((item) => {
              resultSectorPlantOptions.push({
                label: item.attributes.plant_front_name
                  .replace(/"/g, "")
                  .trim(),
                value: item.attributes.plant_front_name
                  .replace(/"/g, "")
                  .trim(),
                groupkey: item.attributes.plant_front_name
                  .replace(/"/g, "")
                  .trim(),
              });
            });

            setsectorPlantoption(resultSectorPlantOptions);
          }
        });
      const sectorFeatureLayer = new FeatureLayer({
        url: props.mit_sceanrio_decarb_table,
      });
      const querysector = sectorFeatureLayer.createQuery();
      querysector.where = whereClauseState;
      querysector.outFields = ["sector", "product"];
      querysector.returnDistinctValues = true;
      querysector.returnGeometry = false;
      querysector.orderByFields = ["sector ASC"];

      sectorFeatureLayer.queryFeatures(querysector).then(function (response) {
        if (response.features) {
          let count = 0;
          response.features.forEach((item) => {
            const status = resultSectorOptions.some(function (e) {
              return (
                e.groupkey === item.attributes.sector.replace(/"/g, "").trim()
              );
            });

            if (!status) {
              count++;
            }
            resultSectorOptions.push({
              label: item.attributes.product.replace(/"/g, "").trim(),
              value:
                count + "|" + item.attributes.product.replace(/"/g, "").trim(),
              groupkey: item.attributes.sector.replace(/"/g, "").trim(),
            });
          });
          const groupedArea = resultSectorOptions.reduce(function (r, a) {
            r[a.groupkey] = r[a.groupkey] || [];
            r[a.groupkey].push(a);
            return r;
          }, Object.create(null));
          Object.keys(groupedArea).forEach((key) => {
            resultSectorOptionsGrouped.push({
              label: key,
              options: groupedArea[key],
            });
            optionsSectorGroup.push(
              createGroupSector(key, groupedArea[key], setvalueSector)
            );
          });
          setoptionsSector(optionsSectorGroup);
        }
      });
    }
  }, [appState.decarbQuery]);

  const createGroup = (groupName: any, options: any, setvalueArea: any) => {
    return {
      label: (() => {
        return (
          <div
            className="companyGroup"
            onClick={() => {
              setvalueArea((value: any) =>
                value.concat(
                  options.filter((grpOpt: any) => !value.includes(grpOpt))
                )
              );
            }}
          >
            {groupName}
          </div>
        );
      })(),
      options: options,
    };
  };
  useEffect(() => {
    if (valueArea !== null && valueArea !== "" && valueArea.length > 0) {
      let eventValues = "";
      if (valueArea.length > 0) {
        for (let i = 0; i < valueArea.length; i++) {
          if (eventValues === "") {
            eventValues = "'" + valueArea[i].label + "'";
          } else {
            eventValues = eventValues + ",'" + valueArea[i].label + "'";
          }
        }
      }
      setappState((prevState) => ({
        ...prevState,
        decarbRegion: eventValues,
      }));
    }
  }, [valueArea]);

  useEffect(() => {
    if (valueSector !== null && valueSector !== "" && valueSector.length > 0) {
      let eventValues = "";
      if (valueSector.length > 0) {
        for (let i = 0; i < valueSector.length; i++) {
          if (eventValues === "") {
            eventValues = "'" + valueSector[i].label + "'";
          } else {
            eventValues = eventValues + ",'" + valueSector[i].label + "'";
          }
        }
      }
      setappState((prevState) => ({
        ...prevState,
        decarbSector: eventValues,
      }));
    }
  }, [valueSector]);
  useEffect(() => {
    let queryDecarb = "";
    if (appState.decarbSector !== "") {
      queryDecarb = " product IN(" + appState.decarbSector + ") ";
    }
    if (appState.decarbRegion !== "" && queryDecarb !== "") {
      queryDecarb =
        queryDecarb + " AND country_name IN(" + appState.decarbRegion + ") ";
    }
    if (appState.decarbRegion !== "" && queryDecarb === "") {
      queryDecarb = " country_name IN(" + appState.decarbRegion + ") ";
    }
    if (appState.decarbCompany !== "" && queryDecarb !== "") {
      queryDecarb =
        queryDecarb + " AND parent_company IN(" + appState.decarbCompany + ") ";
    }
    if (appState.decarbCompany !== "" && queryDecarb === "") {
      queryDecarb = " parent_company IN(" + appState.decarbCompany + ") ";
    }
    if (appState.decarbPlantName !== "" && queryDecarb !== "") {
      queryDecarb =
        queryDecarb +
        " AND plant_front_name IN(" +
        appState.decarbPlantName +
        ") ";
    }
    if (appState.decarbPlantName !== "" && queryDecarb === "") {
      queryDecarb = " plant_front_name IN(" + appState.decarbPlantName + ") ";
    }

    if (queryDecarb === "") {
      setwhereClauseState("1 = 1");
      queryDecarb = "1 = 1";
    } else {
      setwhereClauseState(queryDecarb);
    }
    setappState((prevState) => ({
      ...prevState,
      decarbQuery: queryDecarb,
    }));
  }, [
    appState.decarbCompany,
    appState.decarbPlantName,
    appState.decarbRegion,
    appState.decarbSector,
  ]);
  useEffect(() => {
    if (appState.isAuthenticated) {
      setloadingTable(true);
      if (appState.decarbPlantName === "") {
        setloadingTable(false);
        setselectedPlants([]);
      }
      if (appState.decarbPlantName !== "") {
        const TotalFeatureLayer = new FeatureLayer({
          url: props.mit_sceanrio_decarb_table,
        });
        const queryclause: any = appState.decarbQuery;
        const queryPlant = TotalFeatureLayer.createQuery();
        queryPlant.where = queryclause;
        queryPlant.outFields = ["*"];
        queryPlant.returnDistinctValues = false;
        queryPlant.returnGeometry = false;
        TotalFeatureLayer.queryFeatures(queryPlant).then(function (response) {
          if (response.features) {
            PlantData = response.features;
            const TotalEnergyFeatureLayer = new FeatureLayer({
              url: props.mit_sceanrio_decarb_table,
            });
            const queryclause: any = appState.decarbQuery;
            const query = TotalEnergyFeatureLayer.createQuery();
            query.where = queryclause;
            query.outFields = ["*"];
            query.returnDistinctValues = false;
            query.returnGeometry = false;
            query.orderByFields = ["plant_front_name ASC"];
            query.groupByFieldsForStatistics = [
              "plant_front_name",
              "pathway",
              "vector",
              "sector",
              "product"
            ];
            const validationParamsPlant: any = [
              {
                statisticType: "sum",
                onStatisticField: "capacity",
                outStatisticFieldName: "Total_sum_capacity",
              },
              {
                statisticType: "sum",
                onStatisticField: "total_vector_energy",
                outStatisticFieldName: "Total_sum_vector",
              },
              {
                statisticType: "sum",
                onStatisticField: "total_energy_tj",
                outStatisticFieldName: "Total_sum_Energy",
              },
            ];
            query.outStatistics = validationParamsPlant;

            TotalEnergyFeatureLayer.queryFeatures(query).then(function (
              response
            ) {
              if (response.features) {
                const tableDataNested: any = [];
                response.features.forEach((item: any) => {
                  let index = -1;
                  const obj = tableDataNested.find((o: any, i: any) => {
                    if (
                      o.plantPathway ===
                      item.attributes.pathway +
                        "--" +
                        item.attributes.plant_front_name + "--" +
                        item.attributes.product
                    ) {
                      index = i;
                      return true;
                    }
                  });
                  if (obj !== null && obj !== undefined && obj !== false) {
                    if (
                      tableDataNested[index]["PlantName"] ===
                        item.attributes.plant_front_name &&
                      tableDataNested[index]["pathway"] ===
                        item.attributes.pathway
                    ) {
                      tableDataNested[index][
                        "Vector_" + item.attributes.vector.replace(" ", "_")
                      ] = item.attributes.Total_sum_vector.toFixed(1)
                        .toLocaleString("en-US")
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    } else {
                      const VectorName =
                        "Vector_" + item.attributes.vector.replace(" ", "_");
                      tableDataNested.push({
                        [VectorName]: item.attributes.Total_sum_vector.toFixed(
                          1
                        )
                          .toLocaleString("en-US")
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                        PlantName: item.attributes.plant_front_name,
                        capacity: item.attributes.Total_sum_capacity,
                        pathway: item.attributes.pathway,
                        plantPathway:
                          item.attributes.pathway +
                          "--" +
                          item.attributes.plant_front_name + "--" +
                          item.attributes.product,
                      });
                    }
                  } else {
                    let countryNamePlant = "";
                    let companyNamePlant = "";
                    let sectorName = "";
                    for (let i = 0; i < PlantData.length; i++) {
                      if (
                        item.attributes.plant_front_name ===
                        PlantData[i].attributes.plant_front_name
                      ) {
                        countryNamePlant =
                          PlantData[i].attributes.city +
                          " / " +
                          PlantData[i].attributes.country_name;
                        companyNamePlant = PlantData[i].attributes.company;
                        sectorName =
                          PlantData[i].attributes.sector +
                          " / " +
                          PlantData[i].attributes.product;
                      }
                    }
                    const VectorName =
                      "Vector_" + item.attributes.vector.replace(" ", "_");
                    tableDataNested.push({
                      [VectorName]: item.attributes.Total_sum_vector.toFixed(1)
                        .toLocaleString("en-US")
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                      PlantName: item.attributes.plant_front_name,
                      capacity: item.attributes.Total_sum_capacity,
                      pathway: item.attributes.pathway,
                      plantPathway:
                        item.attributes.pathway +
                        "--" +
                        item.attributes.plant_front_name + "--" +
                        item.attributes.product,
                      countryPlant: countryNamePlant,
                      SectorPlant: item.attributes.sector +
                      " / " +
                      item.attributes.product,
                      companyPlant: companyNamePlant,
                    });
                  }
                });

                const groupingViaCommonProperty = Object.values(
                  tableDataNested.reduce((acc: any, current: any) => {
                    acc[current.PlantName + current.plantPathway.split("--")[2]] = acc[current.PlantName + current.plantPathway.split("--")[2]] ?? [];
                    acc[current.PlantName + current.plantPathway.split("--")[2]].push(current);
                    return acc;
                  }, {})
                );
                const KeysArr = [
                  "Vector_Biomass",
                  "Vector_Biomethane",
                  "Vector_Captured_Emissions",
                  "Vector_Coal",
                  "Vector_Electricity",
                  "Vector_Heat",
                  "Vector_Hydrogen",
                  "Vector_Natural_Gas",
                  "Vector_Oil",
                  "Vector_Net_Emissions",
                  "Vector_Waste",
                  "Vector_Biofeedstock",
                  "Vector_Grey_Hydrogen_fs",
                  "Vector_Hydrogen_fs",
                  "Vector_Oil_fs",
                ];
                for (let i = 0; i < tableDataNested.length; i++) {
                  for (let item = 0; item < KeysArr.length; item++) {
                    if (KeysArr[item] in tableDataNested[i]) {
                      console.log("");
                    } else {
                      tableDataNested[i][KeysArr[item]] = "0.0";
                    }
                  }
                }
                setAllData(tableDataNested);
                setloadingTable(false);
                setselectedPlants(groupingViaCommonProperty);
              }
            });
          }
        });
      }
    }
  }, [appState.decarbQuery]);

  useEffect(() => {
    if (appState.isAuthenticated === undefined) {
      if (window.location.href !== window.location.origin + "/") {
        window.location.href = window.location.origin + "/";
      }
    }
  }, []);

  return (
    <div className="-DecarbDiv">
      <div className="decarbFiltersDiv">
        <span className="h4font-weight-light">Decarbonization Pathway</span>
        <br />
        <span className="pleadfont-weight-light">
          Explore the MIT data using the filter options below.
        </span>
        <div className="Rectangle-decarb"></div>

        <label className="labAreaSel">Region/Country</label>
        <label className="labselareaOptional">optional</label>
        <Select
          isMulti
          className="labSelAreadrop basic-multi-select"
          options={sectorRegionoption}
          placeholder="Search or Select ..."
          isClearable={true}
          isSearchable={true}
          onChange={(selected) => regionSelectedbyUser(selected)}
          hideSelectedOptions={false}
          closeMenuOnSelect={false}
          value={valueArea}
          components={ReactSelectOverrides.default}
          classNamePrefix="react-select"
          aria-label="region-selector"
        />
        <label className="labOutputYear">Sector</label>
        <label className="labselareaOptional">optional</label>
        <Select
          isMulti
          className="labEnergyOutDrop basic-multi-select"
          options={optionsSector}
          placeholder="Select Sector ..."
          isClearable={true}
          isSearchable={true}
          onChange={(selected) => _onSelectSector(selected)}
          hideSelectedOptions={false}
          closeMenuOnSelect={false}
          value={valueSector}
          components={ReactSelectOverrides.default}
          classNamePrefix="react-select"
          aria-label="sector-selector"
        />

        <label className="labCompanyName">Company</label>
        <Select
          isMulti
          className="labCompanyNamedrop basic-multi-select"
          options={sectorCompanyoption}
          placeholder="Search or Select ..."
          isClearable={true}
          isSearchable={true}
          onChange={(selected) => companySelectedbyUser(selected)}
          onInputChange={(inputVal) => CompanyEnteredValuebyUser(inputVal)}
          hideSelectedOptions={false}
          closeMenuOnSelect={false}
          value={valueCompany}
          classNamePrefix="react-select"
          aria-label="company-selector"
        />

        <label className="labVectorName">Plants</label>
        <Select
          isMulti
          className="labVectorNamedrop basic-multi-select"
          options={sectorPlantoption}
          placeholder="Search or Select ..."
          isClearable={true}
          isSearchable={true}
          onChange={(selected) => plantSelectedbyUser(selected)}
          hideSelectedOptions={false}
          closeMenuOnSelect={false}
          components={ReactSelectOverrides.default}
          classNamePrefix="react-select"
          value={valuePlant}
          aria-label="plant-selector"
        />
        <div className="buttoncontainer">
          {/* <div className="saveBtnContainer">
            <img src={savePng} className="save-png" />
            <span className="save-btn-text">Save view</span>
          </div> */}
          <div
            className="clearBtnContainer"
            onClick={handleClearFilters}
            data-testid="clear-filter-btn"
          >
            <img src={reloadPng} className="reload-16-png" />
            <span className="clear-btn-text">Clear Filters</span>
          </div>
        </div>
        {/* <div className="Rectangle-home"></div>
        <label className="labVectorName">Load Saved Pathway View</label>
        <Select
          className="labEnergyOutDrop basic-multi-select"
          placeholder="load Saved Pathway View ..."
          isClearable={false}
          isSearchable={false}
          hideSelectedOptions={false}
          closeMenuOnSelect={false}
          classNamePrefix="react-select"
        /> */}
      </div>
      <div className="tabsDecarb">
        <div className="decarbTabs">
          <div
            className="exportBtnDecarbContainer"
            onClick={handleExportData}
            data-testid="export-data-btn"
          >
            <img src={exportPng} className="export-png" />
            <span className="exportDecarb-btn-text">Export data</span>
          </div>
        </div>

        <div className="decarbContent">
          {selectedPlants.length > 0 ? (
            selectedPlants.map((item: any, index: any) => {
              return (
                <div
                  className="Bounds-decarb"
                  key={item[0].plantPathway}
                  data-testid="decarb-card"
                >
                  <span className="plant-cards">{"Plant Data"}</span>
                  <div className="rule-F2F2F2"></div>
                  <div className="plant-details">
                    <div className="plantdetails-name">
                      <table>
                        <tbody>
                          <tr>
                            <th>
                              <span className="Plant-name-decarb">
                                Plant name
                              </span>
                            </th>
                            <td>
                              <div className="Rectangle-vertical"></div>
                            </td>
                            <td>
                              <span className="plant-name-value-decarb">
                                {item[0].PlantName}
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <th>
                              <span className="Plant-name-decarb">Company</span>
                            </th>
                            <td>
                              <div className="Rectangle-vertical"></div>
                            </td>
                            <td>
                              <span className="plant-name-value-decarb">
                                {item[0].companyPlant}
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <th>
                              <span className="Plant-name-decarb">
                                City/Country
                              </span>
                            </th>
                            <td>
                              <div className="Rectangle-vertical"></div>
                            </td>
                            <td>
                              <span className="plant-name-value-decarb">
                                {item[0].countryPlant}
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="plantdetails-sector">
                      <table>
                        <tr>
                          <th>
                            <span className="Plant-name-decarb">
                              Sector/Product
                            </span>
                          </th>
                          <td>
                            <div className="Rectangle-vertical"></div>
                          </td>
                          <td>
                            <span className="plant-name-value-decarb">
                              {item[0].SectorPlant}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <th>
                            <span className="Plant-name-decarb">
                              Capacity(ktpa)
                            </span>
                          </th>
                          <td>
                            <div className="Rectangle-vertical"></div>
                          </td>
                          <td>
                            <span className="plant-name-value-decarb">
                              {item[0].capacity
                                .toLocaleString("en-US")
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </span>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div className="plant-details-pathways mantine-heightClass">
                    {item && (
                      <MantineReactTable
                        columns={columnsfield}
                        data={item}
                        enableStickyHeader
                        enableExpanding={false}
                        enableExpandAll={false} //default //only expand the root rows by default
                        enableDensityToggle={false}
                        enableRowSelection={true}
                        initialState={{
                          density: "xs",
                          showGlobalFilter: false,
                          columnVisibility: {
                            hasChild: false,
                            autoResetAll: false,
                          },
                          columnPinning: { left: ["pathway"] },
                        }}
                        state={{ isLoading: loadingTable, rowSelection }}
                        onRowSelectionChange={setRowSelection}
                        getRowId={(row: any) => row.plantPathway}
                        autoResetAll={resetTool}
                        enableTopToolbar={false}
                        enablePagination={false}
                        enableBottomToolbar={false}
                        enableSelectAll={false}
                        mantineTableContainerProps={{ sx: { maxHeight: "320px" } }}
                      />
                    )}
                  </div>
                </div>
              );
            })
          ) : (
            <div className="novalue-Css">
              <span>
                Please select Company or Plant and use Region and Sector filters
                to further narrow your selections
              </span>
            </div>
          )}
        </div>
        <span
          style={{
            color: "red",
            fontSize: "smaller",
            marginLeft: "50%",
            margin: "6px 15px 5px 8px",
          }}
        >
          * Click on volume row and use left and right arrows to scroll
          horizontally.
        </span>
        <div>
          <div className="decarb-Rectangle">
            <div className="total-title">
              <table>
                <tbody>
                  <tr>
                    <th>
                      <span className="h4font-weight-light-decarb">
                        Total of selected
                      </span>
                    </th>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="Rectangle-vertical-green"></div>
            <div className="total-name">
              <table>
                <tbody>
                  <tr>
                    <th>
                      <span className="Plant-name-decarb">Plant count</span>
                    </th>
                    <td>
                      <div className="Rectangle-vertical"></div>
                    </td>
                    <td>
                      <span
                        className="plant-name-value-decarb"
                        data-testid="total-plant-count"
                      >
                        {totalPlantCount}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <span className="Plant-name-decarb">
                        Total capacity (ktpa)
                      </span>
                    </th>
                    <td>
                      <div className="Rectangle-vertical"></div>
                    </td>
                    <td>
                      <span
                        className="plant-name-value-decarb"
                        data-testid="total-plant-capacity"
                      >
                        {totalPlantCapacityTotal}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="total-sector">
              <MantineReactTable
                columns={columnsfieldTotal}
                data={totalTableData}
                enableStickyHeader                
                enableExpanding={false}
                enableExpandAll={false} //default //only expand the root rows by default
                enableDensityToggle={false}                
                initialState={{
                  density: "xs",
                  showGlobalFilter: false,
                  columnVisibility: {
                    hasChild: false,
                    autoResetAll: false,
                  },
                }}
                autoResetAll={resetTool}
                enableTopToolbar={false}
                enableBottomToolbar={false}
                enablePagination={false}
                mantineTableContainerProps={{ sx: { maxHeight: "320px" } }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Decarb;
