import React, { useEffect, useState } from "react";
import "./Guide.css";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import "@cyntler/react-doc-viewer/dist/index.css";
import { useGlobalState } from "../GlobalContext/GlobalContext";

const Guide = () => {
  const { appState, setappState } = useGlobalState();
  const docs = [
    {
      uri: require("./GuideDoc/MITguidesection.pdf"),
      fileType: "pdf",
      fileName: "MITguidesection.pdf",
    }, // Local File
  ];
  useEffect(() => {
    if (appState.isAuthenticated === undefined) {
      if (window.location.href !== window.location.origin + "/") {
        window.location.href = window.location.origin + "/";
      }
    }
  }, []);
  return (
    <div className="-GuideDiv">
      <DocViewer
        documents={docs}
        pluginRenderers={DocViewerRenderers}
        style={{ height: 1200 }}
      />
    </div>
  );
};

export default Guide;
