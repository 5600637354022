import React, {
  useContext,
  useState,
  createContext,
  Dispatch,
  SetStateAction,
} from "react";
import { globalContextType } from "../GlobalContext/GlobalConext.types";
import { initialState } from "./GlobalContextInitialState";

export const GlobalContext = createContext({
  appState: {} as Partial<globalContextType>,
  setappState: {} as Dispatch<SetStateAction<Partial<globalContextType>>>,
});

const GlobalStateProvider = ({
  children,
  value = {} as globalContextType,
}: {
  children: React.ReactNode;
  value?: Partial<globalContextType>;
}) => {
  const [appState, setappState] = useState(value);
  return (
    <GlobalContext.Provider value={{ appState, setappState }}>
      {children}
    </GlobalContext.Provider>
  );
};

const useGlobalState = () => {
  const context = useContext(GlobalContext);
  if (!context) {
    throw new Error("useGlobalState must be used within a GlobalStateContext");
  }
  return context;
};

export { GlobalStateProvider, useGlobalState };
