import {
  ApplicationInsights,
  ITelemetryItem,
} from "@microsoft/applicationinsights-web";

import { ReactPlugin } from "@microsoft/applicationinsights-react-js";

import { ClickAnalyticsPlugin } from "@microsoft/applicationinsights-clickanalytics-js";

import { appInsightsConnectStr, appName } from "./Constants/config";
import { envConstants } from "./Constants/envConstants";
import Portal from "@arcgis/core/portal/Portal";
const reactPlugin = new ReactPlugin();

const clickPluginInstance = new ClickAnalyticsPlugin();

// Click Analytics configuration

const clickPluginConfig = {
  autoCapture: true,

  dataTags: {
    useDefaultContentNameOrId: true,
  },
};

const appInsights = new ApplicationInsights({
  config: {
    connectionString: appInsightsConnectStr,

    correlationHeaderExcludedDomains: [
      "onemap-central.bpglobal.com",

      "services.arcgisonline.com",

      "server.arcgisonline.com",

      "js.arcgis.com",

      "static.arcgis.com",

      "cdn.arcgis.com",

      "basemaps.arcgis.com"
    ],

    extensions: [reactPlugin, clickPluginInstance],

    enableAutoRouteTracking: true,

    disableAjaxTracking: false,

    autoTrackPageVisitTime: true,

    enableCorsCorrelation: true,

    enableRequestHeaderTracking: true,

    enableResponseHeaderTracking: true,

    extensionConfig: {
      [clickPluginInstance.identifier]: clickPluginConfig,
    },
  },
});

appInsights.loadAppInsights();

appInsights.addTelemetryInitializer((env: ITelemetryItem) => {
  if (!env.tags) {
    env.tags = {};
  }

  env.tags["ai.cloud.role"] = appName;

  if (env.baseType === "EventData" && env.baseData?.name) {
    env.baseData.name = `${appName}-${env.baseData.name}`;
  }

  if (env.baseType === "EventData" && env.data?.parentId) {
    env.data.parentId = `${appName}-${env.data.parentId}`;
  }
});

const portal = new Portal({
  url: envConstants.portalURL,

  authMode: "no-prompt",
});

portal.load().then(() => {
  const user = portal.user.username;

  appInsights.setAuthenticatedUserContext(btoa(user), btoa(user), true);

  appInsights.trackPageView();
});

export { reactPlugin, appInsights };
